import React, { useState, useMemo, useContext, useEffect } from 'react'

import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    Grid,
    Tooltip,
    Chip,
    ListItem,
    TextField,
    Typography
} from '@mui/material'

import { getGridBooleanOperators, getGridDateOperators, getGridSingleSelectOperators, ptBR } from '@mui/x-data-grid-pro'
import { GridToolbarContainer, GridToolbarExportContainer } from '@mui/x-data-grid-pro'
import { GridToolbarColumnsButton } from '@mui/x-data-grid-pro'
import { GridToolbarFilterButton } from '@mui/x-data-grid-pro'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ptBR as corePtBR } from '@mui/material/locale';

import CancelIcon from '@mui/icons-material/Cancel'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EyeIcon from '@mui/icons-material/Visibility';
import SendIcon from '@mui/icons-material/Send';

import LoadingButton from '@mui/lab/LoadingButton';

import ResendConfirmationDialog from '../../../Dialogs/ResendConfirmation';
import VisualizeTemplate from '../../../Templates/360dialog/Modal/VisualizeTemplate'
import CloseIcon from '@mui/icons-material/Close';

import { DataGridPro, getGridStringOperators } from '@mui/x-data-grid-pro'
import { replaceMCDU } from '../../../Settings/Vivre/Vivremenu/replaceMCDU'
import { ScreenContext } from '../..'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { validateEmail } from '../../../Report/NewDetailedRegister/report_export_data'
import { oldFeaturescheduleReport } from '../../../Report/DetailedRegister'

import api from '../../../../api'

const filterOperators = getGridStringOperators().filter(
    (operator) => ['contains', 'equals'].includes(operator.value)
)
const dateOperators = getGridDateOperators().filter(
    (operator) => ['after', 'before'].includes(operator.value)
)
const booleanOperators = getGridBooleanOperators()
const choiceOperators = getGridSingleSelectOperators().filter(
    (operator) => ['is', 'not'].includes(operator.value)
)

export const notificationError = {
    '400': '[400] Erro de download de mídia',
    '402': '[402] Qualificação da empresa — Problema de pagamento',
    '408': '[408] Mensagem inválida',
    '410': '[410] Mensagem expirou',
    '429': '[429] Limite de envio atingido',
    '430': '[430] Certificado não assinado',
    '432': '[432] Incompatibilidade de ID do certificado',
    '433': '[433] Assinatura do certificado inválida',
    '470': '[470] Mensagem de reengajamento',
    '471': '[471] Limite de envio de spam atingido',
    '472': '[472] Número do usuário faz parte de um experimento',
    '480': '[480] Usuário potencialmente alterado',
    '500': '[500] Erro genérico',
    '1000': '[1000] Erro genérico',
    '1001': '[1001] Mensagem muito longa',
    '1002': '[1002] Tipo de destinatário inválido',
    '1004': '[1004] Recurso já existe',
    '1005': '[1005] Acesso negado',
    '1006': '[1006] Recurso não encontrado',
    '1008': '[1008] Parâmetro obrigatório ausente',
    '1009': '[1009] Parâmetro inválido',
    '1010': '[1010] Parâmetro não é obrigatório',
    '1011': '[1011] Serviço não está pronto',
    '1013': '[1013] Destinatário não possui WhatsApp',
    '1014': '[1014] ',
    '1015': '[1015] Limite de envio atingido',
    '1016': '[1016] Sistema sobrecarregado',
    '1017': '[1017] Conta não está configurado corretamente',
    '1018': '[1018] Não é o aplicativo principal principal',
    '1021': '[1021] Usuário inválido',
    '1022': '[1022] URL dos webhooks não está configurado',
    '1023': '[1023] Ocorreu um erro de banco de dados',
    '1024': '[1024] Você é obrigado a alterar sua senha',
    '1025': '[1025] Requisição inválida',
    '1026': '[1026] Destinatário não pode receber a mensagem',
    '1028': '[1028] Uma notificação do sistema requer confirmação',
    '1031': '[1031] Remetente foi bloqueado',
    '2000': '[2000] Incompatibilidade de contagem de parâmetros',
    '2001': '[2001] Template não existe pro idioma específico.',
    '2002': '[2002] Receptor falhou ao baixar o modelo',
    '2003': '[2003] Não existem modelos pro idioma específico',
    '2004': '[2004] Comprimento do parâmetro muito longo',
    '2005': '[2005] Texto traduzido muito longo',
    '2006': '[2006] Política de espaço em branco violada',
    '2007': '[2007] Política de caracteres de formato violada',
    '2008': '[2008] Formato de mídia usado não é compatível',
    '2009': '[2009] Componente obrigatório no modelo está ausente',
    '2010': '[2010] URL do botão é inválido',
    '2011': '[2011] Número de telefone do botão é inválido',
    '2012': '[2012] Formato do parâmetro não corresponde ao template',
    '2013': '[2013] Os botões não são suportados pelo destinatário',
    '2014': '[2014] Namespace esperado está vazio',
    '2015': '[2015] Número inválido de seções',
    '2016': '[2016] Número inválido de linhas',
    '2017': '[2017] Política de personagem violada',
    '2023': '[2023] Número de produto inválido',
    '2024': '[2024] ID do catálogo não encontrado',
    '2025': '[2025] ID do catálogo não vinculado ao número da API',
    '2026': '[2026] Produtos ausentes',
    '2027': '[2027] Nenhum produto encontrado',
    '131026': '[131026] Destinatário não pode receber a mensagem',
    '131042': '[131042] A mensagem não foi enviada porque houve um ou mais erros relacionados à sua forma de pagamento',
    '131008': '[131008] Um parâmetro obrigatório está faltando',
}

const scheduleReport = () => {
}

const handleNotificationErrorMessage = (message, platform) => {
    /** 
     * Handle WhatsApp API errors
     * https://developers.facebook.com/docs/whatsapp/on-premises/errors 
     */

    if (! message) 
        return

    try {
        if (platform === 'SMS') {
            const parsedMessage = JSON.parse(message);
            return `Status: ${parsedMessage.status}, mensagem: ${parsedMessage.message}, mais informações: ${parsedMessage.more_info}`
        }
        const parsedMessage = JSON.parse(message.replace(/'/g, '"'));
        let code
        if ('errors' in parsedMessage)
            code = String(parsedMessage.errors[0].code)
        if ('error' in parsedMessage)
            code = String(parsedMessage.error.code)
        return notificationError[code]
    } catch (err) {
        return
    }
}

export const getNotificationColumns = (isSmallScreen, showTemplate) => {
    return [
        
        { 
            field: 'name', headerName: 'Destinatário', flex: 1, filterOperators: filterOperators
        },
        { 
            field: 'customer_code', headerName: 'Cod. Cliente', flex: 1, filterOperators: filterOperators
        },
        { 
            field: 'enterprise_name', headerName: 'Nome Empresa', flex: 1, filterOperators: filterOperators
        },
        { field: 'to', headerName: 'Tel. Destinatário', flex: 1, filterOperators: filterOperators },
        { field: 'agent_name', type: 'text', headerName: 'Operador', flex: 1, filterOperators: filterOperators },
        {
            field: 'sender', type: 'text', headerName: 'Origem', flex: 1, filterOperators: filterOperators,
            valueGetter: ({ row }) => {
                if (row.platform === 'SMS' && row.senderSms)
                    return row.senderSms
                return row.sender
            }
        },
        { field: 'queue_name', headerName: 'Fila', flex: 1, hide: isSmallScreen, filterOperators: filterOperators },
        {
            field: 'created_at', type: 'dateTime', headerName: 'Criação', flex: 1, hide: isSmallScreen, filterOperators: dateOperators,
            valueGetter: ({ value }) => {
                if (value) {
                    const date = new Date(value);
                    date.setHours(date.getHours() - 3);
                    return date;
                }
            },
            renderCell: (params) => (
                <Box>
                    {params.value.toLocaleString('pt-BR')}
                </Box>
            )
        },
        {
            field: 'processed_at', type: 'dateTime', headerName: 'Processamento', flex: 1, hide: isSmallScreen, filterOperators: dateOperators,
            valueGetter: ({ value }) => {
                if (value) {
                    const date = new Date(value);
                    date.setHours(date.getHours() - 3);
                    return date;
                }
            },
            renderCell: (params) => (
                <Box>
                    {params.value ? params.value.toLocaleString('pt-BR') : ''}
                </Box>
            )
        },
        {
            field: 'status', headerName: 'Status', flex: .5, filterOperators: choiceOperators,
            valueOptions: ['SUCESSO', 'PENDENTE', 'NÃO ENVIADA', 'ERRO'],
            renderCell: (params) => (
                <Chip
                    label={
                        params.value == "SUCCESS"
                            ? "Sucesso"
                            : params.value == "WAITING"
                                ? "Pendente"
                                : params.value == "NOTSENT"
                                    ? "Não enviada"
                                    : "Erro"
                    }
                    color={
                        params.value == "SUCCESS"
                            ? "success"
                            : params.value == "WAITING" || params.value == "NOTSENT"
                                ? "warning"
                                : "error"
                    }
                    size="small"
                />
            )
        },
        {
            field: 'used', headerName: 'Respondida', flex: .5, hide: isSmallScreen, filterOperators: booleanOperators,
            renderCell: (params) => {
                if (params.value === 2) {
                    return (<Tooltip title='Visualizado'><CheckCircleOutlineIcon className="icon-answered" /></Tooltip>)
                }
                else if (params.value && params.row.status !== 'NOTSENT') {
                    return (<CheckCircleIcon className="icon-answered" />);
                }
                return (<CancelIcon className="icon-not-answered" />);
            }
        },
        {
            field: 'platform', headerName: 'Canal', flex: 0.3, hide: isSmallScreen,
            renderCell: ({ value }) => {
                return (
                    <>
                        <Tooltip title={value.toLocaleLowerCase()}>
                            <Box>{replaceMCDU(value)}</Box>
                        </Tooltip>
                    </>
                )
            }
        },
        {
            field: 'template', headerName: 'Template', flex: 1.5, hide: isSmallScreen, filterOperators: filterOperators,
            renderCell: (params) => {
                if (params.row.platform === 'WHATSAPP' && showTemplate) {
                    const data = JSON.parse(params.value);
                    return (
                        <>
                            <Button className="action-button view-template"
                                onClick={() => showTemplate(params.row)}>
                                <EyeIcon />
                            </Button>
                            {data.name}
                        </>
                    );
                }
                else if (params.row.platform === 'SMS' && showTemplate) {
                    return (
                        <>
                            <Button className="action-button view-template"
                                onClick={() => showTemplate(params.row)}>
                                <EyeIcon />

                            </Button>
                            Ver mensagem SMS.
                        </>
                    )
                }
                else return params.value

            }
        },
        {
            field: 'comments', headerName: 'Mensagem de Erro', flex: 2, hide: isSmallScreen, filterOperators: filterOperators,
            renderCell: (params) => {
                if (params.row.status == 'FAILURE' || params.row.status == 'NOTSENT' && params.value) 
                    return handleNotificationErrorMessage(params.value, params.row.platform)
                else if (params.row.status == 'NOTSENT' && ! params.value)
                    return 'O cliente estava em atendimento'
                return ''
            }
        },
        { field: 'campaign_name', headerName: 'Campanha', flex: 1, hide: isSmallScreen, filterOperators: filterOperators },
        { field: 'campaign_id', headerName: 'Id da campanha', flex: 0.5, hide: true, filterOperators: filterOperators.filter(operator => operator.value == 'equals') }
    ];
}

const CustomToolbar = (setShowExportDialog, setShowResendConfirmationDialog) => {
    return (
        <GridToolbarContainer>
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
            <GridToolbarExportContainer>
                <ListItem
                    onClick={() => { setShowExportDialog('csv') }}
                    className={'menuItem'}>
                    Exportar como CSV
                </ListItem>
                <ListItem
                    onClick={() => { setShowExportDialog('xlsx') }}
                    className={'menuItem'}>
                    Exportar como XLSX
                </ListItem>
            </GridToolbarExportContainer>
            <Box className='toolbarRight'>
                <LoadingButton
                    component="label"
                    startIcon={<SendIcon />}
                    loading={false}
                    onClick={() => { setShowResendConfirmationDialog(true) }}
                    disabled={false}
                    size="small"
                >
                    Reenviar
                </LoadingButton>
            </Box>
        </GridToolbarContainer>
    )
}


const categories = {
    'AUTO_REPLY': 'Resposta automática',
    'ACCOUNT_UPDATE': 'Atualização da conta',
    'PAYMENT_UPDATE': 'Atualização de pagamento',
    'PERSONAL_FINANCE_UPDATE': 'Atualização de finanças pessoais',
    'SHIPPING_UPDATE': 'Atualização de envio',
    'RESERVATION_UPDATE': 'Atualização de reserva',
    'ISSUE_RESOLUTION': 'Resolução de problemas',
    'APPOINTMENT_UPDATE': 'Atualização de compromisso',
    'TRANSPORTATION_UPDATE': 'Atualização de transporte',
    'TICKET_UPDATE': 'Atualização de ingresso',
    'ALERT_UPDATE': 'Atualização de alerta',
    'TRANSACTIONAL': 'Transacional',
    'MARKETING': 'Marketing',
    'AUTHENTICATION': 'Autenticação',
    'OTP': 'Senha de uso único',
    'UTILITY': 'Utilidades'
};

const theme = createTheme({
    palette: {
        primary: { main: '#21446C' },
    },
}, ptBR, corePtBR);


export const showTemplateFunc = (data, updateShowTemplate, updateTemplateText) => {

    let template = ''

    if (data.platform === 'SMS') {
        template = { components: [{ type: 'BODY', text: data.template }] }
    }
    else if (data.platform === 'WHATSAPP') {
        template = JSON.parse(data.template);
        let parameters = JSON.parse(data.parameters);

        let type_new = false
        if (parameters && parameters.length > 0) {
            type_new = true
            if (typeof (parameters) == 'string')
                parameters = JSON.parse(parameters)
            if (typeof (parameters[0]) == 'string')
                type_new = false
        }

        template.components.map(component => {
            if (component.type == 'BODY') {
                const bparameters = type_new ? parameters.find(parameter => parameter.body) : parameters
                if (bparameters)
                    component.text = component.text.replace(/{{(\d+)}}/g, function (_, m) { return type_new ? bparameters.body[--m] : bparameters[--m]; })

            }
            else if (type_new && component.type == 'BUTTONS') {
                const buttonURL = component.buttons.find(button => button.url)
                const bparameters = parameters.find(parameter => parameter.buttons)
                if (buttonURL && bparameters)
                    buttonURL.url = buttonURL.url.replace(/{{(\d+)}}/g, function (_, m) { return bparameters.buttons })
            }
            else if (type_new && component.type == 'HEADER') {
                const bparameters = parameters.find(parameter => parameter.headerbody)
                const hparameters = parameters.find(parameter => parameter.headerfile)
                if (bparameters)
                    component.text = component.text.replace(/{{(\d+)}}/g, function (_, m) { return bparameters.headerbody })
                else if (hparameters)
                    component.link = hparameters.headerfile
            }

        })

        template.components.map(component => {
            if (component.type == 'BODY') {
                const bparameters = type_new ? parameters.find(parameter => parameter.body) : parameters
                if (bparameters)
                    component.text = component.text.replace(/{{(\d+)}}/g, function (_, m) { return type_new ? bparameters.body[--m] : bparameters[--m]; })

            }
            else if (type_new && component.type == 'BUTTONS') {
                const buttonURL = component.buttons.find(button => button.url)
                const bparameters = parameters.find(parameter => parameter.buttons)
                if (buttonURL && bparameters)
                    buttonURL.url = buttonURL.url.replace(/{{(\d+)}}/g, function (_, m) { return bparameters.buttons })
            }
            else if (type_new && component.type == 'HEADER') {
                const bparameters = parameters.find(parameter => parameter.headerbody)
                const hparameters = parameters.find(parameter => parameter.headerfile)
                if (bparameters)
                    component.text = component.text.replace(/{{(\d+)}}/g, function (_, m) { return bparameters.headerbody })
                else if (hparameters)
                    component.link = hparameters.headerfile
            }

        })

    }

    updateShowTemplate(true)
    updateTemplateText(template)
}

const setExportColumns = (columns) => {
    let fieldsExport = [];
    columns.forEach(column => {
        if (column.field === '__check__' ||
            column.field === 'actions' ||
            column.field === 'messages') // Not exporting messages
            return;
        fieldsExport.push(column.field);
    });
    return fieldsExport
}

const NotificationTable = () => {
    const { isSmallScreen,
        notifications,
        fetchNotifications,
        startDateTime,
        endDateTime,
        ServerData,
        updateLimitOffset,
        limitOffset,
        filterModel,
        sortModel,
        enterpriseId
    } = useContext(ScreenContext)


    const [showTemplate, updateShowTemplate] = useState(false)
    const [templateText, updateTemplateText] = useState('')
    const [pageLimit, updatePageLimit] = useState(0)
    const [notificationsFilter, updateNotificationsFilter] = useState({ items: [] })
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({ items: [] })
    const [notificationsSort, updateNotificationsSort] = useState([])
    const [showExportDialog, setShowExportDialog] = useState(null)
    const [selectedNotifications, setSelectedNotifications] = useState([])
    const [showResendConfirmationDialog, setShowResendConfirmationDialog] = useState(null)
    const [email, setEmail] = useState('')

    useEffect(() => {
        updateNotificationsFilter(filterModel.notifications)
        updateNotificationsSort(sortModel.notifications)
    }, [])

    useEffect(() => {
        setColumnVisibilityModel({
            to: !isSmallScreen,
            sender: !isSmallScreen,
            agent_name: !isSmallScreen,
            queue_name: !isSmallScreen,
            created_at: !isSmallScreen,
            processed_at: !isSmallScreen,
            used: !isSmallScreen,
            template: !isSmallScreen,
            comments: !isSmallScreen,
            campaign_name: !isSmallScreen,
            campaign_id: false
        })
    }, [isSmallScreen])

    const columns = getNotificationColumns(isSmallScreen, (data) => showTemplateFunc(data, updateShowTemplate, updateTemplateText))

    const table = useMemo(() => {

        const getPage = (limit, offset, direction) => {
            if (direction == 'next')
                return Math.round(offset / limit) - 1
            return Math.round(offset / limit) + 1

        }
        const getQueryset = (string, search, delimiter) => {
            const queryset = string.split(search)[1]
            if (queryset) {
                const result = queryset.split(delimiter)
                return result ? result[0] : ''
            }
            return ''
        }
        const getLimitOffset = (url) => {
            const queryset = url.split('?').pop()
            limit = getQueryset(queryset, 'limit=', '&')
            offset = getQueryset(queryset, 'offset=', '&')
            if (pageLimit)
                limit = pageLimit
            return [limit, offset]
        }

        const handlePageChange = (event) => {
            if (event > page) {
                fetchNotifications(startDateTime, endDateTime, ...next)
                return
            }
            fetchNotifications(startDateTime, endDateTime, ...previous)
        }

        const handlePageSizeChange = (newPage) => {
            updatePageLimit(newPage)
            fetchNotifications(startDateTime, endDateTime, 'auto', newPage, actual)
        }

        let count = 0
        let page = 0
        let next = ''
        let previous = ''
        let limit = 25
        let oldlimit = 0
        let offset = 0
        let actual = 0

        if (limitOffset.notifications.length > 0) {
            limit = limitOffset.notifications[0]
        }

        if (ServerData) {
            count = ServerData.notifications.count
            previous = ServerData.notifications.previous
            next = ServerData.notifications.next


            if (next) {
                const limitOffset = getLimitOffset(next)
                next = ['auto', ...limitOffset]
                page = getPage(...limitOffset, 'next')
                offset = limitOffset.pop()
                oldlimit = limitOffset.pop()
                actual = Number(offset) - Number(limit)
                updateLimitOffset(prevState => ({ ...prevState, notifications: [oldlimit, actual] }))
            }

            if (previous) {
                const limitOffset = getLimitOffset(previous)
                previous = ['auto', ...limitOffset]
                if (page == 0) {
                    page = getPage(...limitOffset, 'previous')
                    offset = limitOffset.pop()
                    oldlimit = limitOffset.pop()
                    actual = Number(offset) - Number(limit)
                    updateLimitOffset(prevState => ({ ...prevState, notifications: [oldlimit, actual] }))
                }

            }

        }

        const handleFilterChange = event => {
            fetchNotifications(startDateTime, endDateTime, 'filter', limit, actual, event)
            updateNotificationsFilter(event)
        }

        const handleSortChange = event => {
            fetchNotifications(startDateTime, endDateTime, 'sort', limit, actual, { items: [] }, event)
            updateNotificationsSort(event)
        }

        return (
            <Box sx={{ width: '100%', height: '100%' }}>
                <ThemeProvider theme={theme}>
                    <DataGridPro
                        columns={columns}
                        rows={notifications}
                        checkboxSelection
                        disableSelectionOnClick
                        onSelectionModelChange={(selection) => {
                            setSelectedNotifications(selection);
                        }}
                        isRowSelectable={(params) => params.row.status == "NOTSENT"}
                        selectionModel={selectedNotifications}
                        components={{ Toolbar: () => CustomToolbar(setShowExportDialog, setShowResendConfirmationDialog) }}
                        density={'compact'}
                        // columnVisibilityModel={columnVisibilityModel}
                        // onColumnVisibilityModelChange={(newModel) =>
                        //     setColumnVisibilityModel(newModel)
                        // }
                        //pagination
                        pagination
                        paginationMode="server"
                        rowsPerPageOptions={[25, 50, 100, 250]}
                        page={page}
                        onPageSizeChange={handlePageSizeChange}
                        onPageChange={handlePageChange}
                        rowCount={count}
                        keepNonExistentRowsSelected
                        initialState={{
                            pagination: {
                                pageSize: Number(limit)
                            }
                        }}

                        //filter
                        filterMode='server'
                        onFilterModelChange={handleFilterChange}
                        filterModel={notificationsFilter}

                        //sorting
                        sortingMode="server"
                        sortModel={notificationsSort}
                        onSortModelChange={handleSortChange}
                    />
                </ThemeProvider>
            </Box>)
    }, [notifications, columnVisibilityModel, notificationsFilter, selectedNotifications, setSelectedNotifications])

    const validatedEmail = validateEmail(email)

    const resendNotifications = (notifications) => {
        api.post('api/notification/resend/', {ids: notifications})
        .then((res) => {
            if (res.status === 201) {
                setShowResendConfirmationDialog(null)
                setSelectedNotifications([])
                fetchNotifications(startDateTime, endDateTime)
                return
            }
            throw error
        })
        .catch((err) => { console.error(err) })
    }

    return (
        <>
            {table}
            {showResendConfirmationDialog &&
                <ResendConfirmationDialog
                    open={showResendConfirmationDialog}
                    handleClose={() => setShowResendConfirmationDialog(null)}
                    handleSubmit={() => resendNotifications(selectedNotifications)}
                />
            }
            {showExportDialog && <Dialog open={showExportDialog}
                disablePortal
                disableEnforceFocus
                sx={{ position: 'absolute' }}
                fullWidth
                maxWidth='sm'
            >
                <DialogTitle className={'group-flexbox'}>
                    <span>Exportar relatório {showExportDialog}</span>
                    <Tooltip title="Fechar" >
                        <Button className="action-button"  >
                            <CloseIcon onClick={() => { setShowExportDialog(null) }} />
                        </Button>
                    </Tooltip>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <Typography fontSize="12px" fontWeight="500" color="#21446c">
                        O relatório será enviado por email, para isso, digite-o abaixo.
                    </Typography>
                    <TextField
                        size='small'
                        label="Email"
                        variant="outlined"
                        fullWidth
                        sx={{ mt: 2, mb: 2 }}
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        error={!validatedEmail}
                        helperText={email && !validatedEmail && "Por favor, insira um e-mail válido"}
                        inputProps={{
                            maxLength: 100
                        }}
                    />
                </DialogContent>
                <DialogActions sx={{ padding: '0rem 1.25rem 0.5rem 0rem' }}>
                    <Button disabled={email.length === 0 || !validatedEmail} onClick={async () => {
                        const res = await oldFeaturescheduleReport('instant', email, '', '', '', '', true, showExportDialog, columns,
                            setExportColumns(columns), 'notification', enterpriseId, '', notificationsSort, notificationsFilter, startDateTime, endDateTime)
                        if (res?.status === 200)
                            setShowExportDialog(false)
                    }}>
                        Enviar
                    </Button>
                </DialogActions>
            </Dialog>}

            {showTemplate && <Dialog open={showTemplate}
                onClose={() => { updateShowTemplate(false) }}
                disablePortal
                disableEnforceFocus
                sx={{ position: 'absolute' }}
                fullWidth
                maxWidth='sm'
            >
                <DialogTitle className={'group-flexbox'}>
                    <span>Notificação</span>
                    <Tooltip title="Fechar" >
                        <Button className="action-button"  >
                            <CloseIcon onClick={() => { updateShowTemplate(false) }} />
                        </Button>
                    </Tooltip>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <Grid container spacing={3}>
                        <VisualizeTemplate
                            template={templateText}
                            category={categories[templateText.category]}
                            canVisualize={true}
                            processed={true}
                        />
                    </Grid>
                </DialogContent>
                <DialogActions sx={{ padding: '0rem 1.25rem 0.5rem 0rem' }}>
                    <Button onClick={() => { updateShowTemplate(false) }}>
                        Fechar
                    </Button>
                </DialogActions>
            </Dialog>}
        </>
    )

}

export default NotificationTable