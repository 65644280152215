import React from 'react'
import SettingsIntermediate from '../SettingsIntermediate'
import userPermissions from '../../User/Permissions';
import { getSession } from '../../../auth';
import Integrations from '../../Settings/Integrations';
import KwikApiKey from '../../Settings/Integrations/kwik-api-key';


const IntegrationPage = () => {
    const groups = userPermissions();
    const enterprise = getSession()?.profile?.enterprise
    return (
        <>
            <SettingsIntermediate title='Integrações' components={() => ({
                comp1:{
                    permissions: true,
                    style:'title',
                    label:'Integrações'
                },
                comp2:{
                    permissions: groups.isAdmin,
                    style:'list',
                    label: 'Eventos',
                    sublabel: 'Eventos pré-programadas do Kwik',
                    redirect: (<Integrations enterpriseId={enterprise?.id}/>),
                    isVisible: enterprise.integration
                },
                comp3:{
                    permissions: groups.isAdmin,
                    style:'list',
                    label: 'Chave de API do Kwik',
                    sublabel: 'Chave utilizada para se comunicar com a API Pública do Kwik',
                    redirect: (<KwikApiKey />)
                }
            })
            } />
        </>
    )
}


export default IntegrationPage
