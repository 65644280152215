import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import {
    Box,
    Button,
    Tooltip
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel'

export const createColumns = (state, setState) => {
    return [
        { field: 'name', headerName: 'Evento', flex: .4 },
        {
            field: 'builderbot', headerName: 'Bot', flex: .4,
            renderCell: ({ value }) => {
                if (state.builder) {
                    return (<Box>{state?.builder?.botlist?.find(each => each.id === value)?.name}</Box>)
                } else {
                    return value
                }
            }
        },
        {
            field: 'status', headerName: 'Ativo', flex: .1,
            renderCell: ({ value }) => {
                if (value)
                    return (<Box><CheckCircleIcon sx={{ 'color': '#5F8963' }} /></Box>)
                return (<Box><CancelIcon sx={{ 'color': '#C95F50' }} /></Box>)
            }
        },
        {
            field: 'actions', headerName: 'Ações', flex: .1,
            renderCell: (params) => (
                <Box>
                    <Tooltip title='Editar'>
                        <Button className="action-button"
                            onClick={() => setState({ edit: params.row })}>
                            <EditIcon />
                        </Button>
                    </Tooltip>
                    {
                        state?.builder?.botlist?.find(each => each.id === params?.row?.builderbot)?.name && <Tooltip title='Editar Bot'>
                            <Button className="action-button"
                                onClick={() => setState({ showbot: params.row })}>
                                <PrecisionManufacturingIcon />
                            </Button>
                        </Tooltip>
                    }
                    {/* <Tooltip title='Excluir'>
                        <Button className="action-button delete"
                            onClick={() => setState({ delete: params.row })}>
                            <DeleteIcon />
                        </Button>
                    </Tooltip> */}
                </Box>
            )
        },
    ]
}