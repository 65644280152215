import React from "react";
import { Box } from "@mui/material";
import DetailedRegister from "./DetailedRegister";
import DetailedRegisterMain from "./NewDetailedRegister/detailed_register_main"

const ReportGrid = () => {

    return (
        <DetailedRegisterMain />
    );
}

export default ReportGrid;

