import React, { useState, useContext } from 'react'

import { Box, Dialog, DialogTitle, DialogContent, DialogActions, Button, Tooltip, Divider } from '@mui/material'
import { constructorContext } from '../../Builder'
import ImportIcon from '@mui/icons-material/Upload';
import api from '../../../../api';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import CloseIcon from '@mui/icons-material/Close';

import { FileDrop } from 'react-file-drop';

import useNotification from '../../../Notification';

const ImportBotModal = ({ setData, getRows }) => {
    const { activeTab, enterpriseId, setLoading } = useContext(constructorContext)

    const [file, setFile] = useState()

    const submit = async () => {
        let url = `api/enterprises/${enterpriseId}/ura/`
        url = url + (activeTab == 0 ? 'chat/' : 'voice/') + 'importivr/'

        const body = new FormData()

        body.append('file', file)

        setLoading(true)
        const res = await api.post(url, body, { timeout: 0 })
        setLoading(false)
        if (res.status == 200) {

            getRows()
            setData()
            useNotification(
                'Sucesso!',
                'Bot importado com sucesso.',
                'success'
            )
            return
        }
        useNotification(
            'Ops!',
            'Não foi possível importar o bot.',
            'danger'
        )

    }

    return (
        <>
            <Dialog open={true} fullWidth
                disablePortal
                disableEnforceFocus
                sx={{ position: 'absolute' }}>
                <DialogTitle
                    id="customized-dialog-title" className={'group-flexbox'}>
                    <span>Importar Bot</span>
                    <Tooltip title="Fechar" >
                        <Button className="action-button"  >
                            <CloseIcon onClick={() => { setData() }} />
                        </Button>
                    </Tooltip>
                </DialogTitle>
                <Divider />
                <DialogContent
                    className='piloto-modal-flex'
                >
                    <div className="file-drop-container">
                        <FileDrop
                            onDrop={(files, event) => { setFile(files[0]) }}
                        >
                            {file ? <span>{file.name}</span> :
                                <span>Arraste e solte para inserir o arquivo IVR</span>
                            }

                            <span>ou</span>
                            <LoadingButton
                                variant="contained"
                                color="primary"
                                component="label"
                                loading={false}
                                loadingPosition="start"
                                startIcon={<ImportIcon />}
                                disabled={false}
                                onClick={() => { }}
                            >
                                Selecione o arquivo.
                                <input
                                    hidden
                                    type="file"
                                    accept={'.ivr'}
                                    onChange={(e) => { setFile(e.target.files[0]) }}
                                />

                            </LoadingButton>
                        </FileDrop>
                    </div>

                </DialogContent>
                <DialogActions style={{ padding: "10px 20px 10px 10px" }}>
                    <Button
                        onClick={() => { setData() }}>
                        Cancelar
                    </Button>
                    <Tooltip title={''} placement="top" describeChild={true}>
                        <span>
                            <Button
                                disabled={''}
                                onClick={submit}>
                                Salvar
                            </Button>
                        </span>
                    </Tooltip>
                </DialogActions>

            </Dialog>
        </>
    )
}

export default ImportBotModal