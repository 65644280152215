import React from "react";

import { Box, Grid } from "@mui/material";

import { getSession } from "../../auth";
import api from '../../api';

import ConversationList from "./ConversationList";
import MessagesArea from "./MessagesArea";
import Profile from "./Profile";
import ChatRightSideBar from "./ChatRightSideBar";

import ModalMessagesArea from "../ManageCalls/MessagesArea";
import HistoryMessagesDialog from "../Dialogs/HistoryMessagesDialog";
import WebphoneMenu from "../Menu/WebphoneMenu";

import useNotification from "../Notification";
import { ReactNotifications } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import 'animate.css/animate.min.css';
import NewConversationList from "./NewConversationList";
import { CircularProgress } from "@mui/material";

import "./styles.css";
import isMobile from "../../utils/isMobile";

class Chat extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            activeTab            : 1,
            activeSubTab         : 0,
            showProfile          : false,
            contactData          : {},
            activeChat           : {},
            activeVoice          : {},
            enterpriseId         : null,
            enterpriseQueues     : [],
            userId               : null,
            agentId              : null,
            agentUser            : null,
            predefinedMessages   : [],
            accountcode          : null,
            socket               : null,
            socketVoice          : null,
            showChatHistory      : false,
            historyChat          : null,
            historyMessages      : [],
            uniqueId             : null,
            lastDateTime         : null,
            platform             : null,
            nameChangeField      : false,
            emailChangeField     : false,
            renderKey            : 0,
            templates            : [],
            showModal            : false,
            notificationAudio    : '',
            renderKeyList        : 0,
            refresh              : false,
            loading              : false,
            showChatRightSideBar : false,
            allLabels            : [],
            allGroups            : [],
            chatLabels           : [],
            chatGroups           : [],
            ready                : false,
            updateList           : false,
            callMessage          : '',      
            chatCursor           : 0,      
        }

        this.agentCanUpdateContact = true;
        this.messageArea = null

    }

    fetchTemplates = async () => {
        await api.get(`/api/enterprises/${this.state.enterpriseId}/templates/`)
            .then((res) => {
                if (res.status == 200) {
                    if (getSession().profile.enterprise.abstraction_layer) {
                        let templates = {}
                        Object.keys(res.data).forEach((key) => {
                            res.data[key].waba_templates = res.data[key].waba_templates.filter((template) => {
                                return (template.status == 'approved' &&
                                    template.language == 'pt_BR' &&
                                    !template.name.includes('sample_'));
                            });
                            templates[key] = res.data[key].waba_templates
                        })
                        this.state.templates = templates;   
                    } else {
                        let templates = res.data.waba_templates.filter((template) => {
                            return (template.status == 'approved' &&
                                template.language == 'pt_BR' &&
                                !template.name.includes('sample_'));
                        });
                        this.state.templates = templates;   
                    }
                    return;
                }
                throw res;
            })
            .catch((err) => {
                console.error(err);
            });
    }

    // Detects if device is on iOS 
    isIos = () => {
        const userAgent = window.navigator.userAgent.toLowerCase();
        return /iphone|ipad|ipod/.test(userAgent);
    }

    fetchEnterprise = async () => {
        await api.get(`/api/enterprises/${this.state.enterpriseId}/`)
            .then((res) => {
                if (res.status == 200) {
                    if (res.data.settings) {
                        const settings = res.data.settings;
                        this.agentCanUpdateContact = settings.agent_can_update_contact;

                        this.setState({
                            notificationAudio: res.data.audio_notification,
                            renderKeyList: Math.random()
                        });
                    }

                    if ("Notification" in window) {
                        // I have permission to receive notification
                        if (Notification.permission === "granted") {
                            if (this.isIos()) {
                                useNotification(
                                    'Informação útil!',
                                    'Identificamos que você está em um navegador iOS, as notificações de novas mensagens não estão disponíveis por restrição do Sistema Operacional.',
                                    'info'
                                );
                            }
                            else {
                                useNotification(
                                    'Informação útil!',
                                    'Mantenha a tela de Chat aberta para poder receber as notificações de novas mensagens, quando estiver em outra aba do navegador.',
                                    'info'
                                );
                            }
                        } else {
                            if (this.isIos()) {
                                useNotification(
                                    'Informação útil!',
                                    'Identificamos que você está em um navegador iOS, as notificações de novas mensagens não estão disponíveis por restrição do Sistema Operacional.',
                                    'info'
                                );
                            }
                            else {
                                useNotification(
                                    'Atenção!',
                                    'Mantenha as notificações ativas no seu navegador para sempre ser informado de uma nova mensagem no seu Kwik.',
                                    'warning'
                                );
                            }
                        }
                    }
                    
                    this.state.enterpriseId = res.data.id;
                    this.state.accountcode = res.data.accountcode;

                    const queue_transfer = api.get(`/api/enterprises/${this.state.enterpriseId}/get_queues/`)
                    .then((res) => {
                        this.state.enterpriseQueues = res.data;
                    }).catch((error) => {
                        console.log(error)
                    });

                    return;
                }
                throw res;
            })
            .catch((error) => {
                console.error(error);
            }); 
    }

    fetchPredefinedMessages = async () => {
        const ent_mes = api.get(`/api/enterprises/${this.state.enterpriseId}/predefinedmessages/`)
        const usr_mes = api.get(`/api/users/${this.state.userId}/predefinedmessages/`)

        const [ent_res, usr_res] = await Promise.all([ent_mes, usr_mes])
        let predefinedData = {}

        if (usr_res.status == 200) {
            predefinedData = { ...predefinedData, ...usr_res.data }
        }
        else {
            console.error(usr_res.err);
        }

        if (ent_res.status == 200) {
            ent_res.data.map(each => {
                const cat = `${each.category} - Geral`
                if (cat in predefinedData)
                    predefinedData[cat].push({ id: each.id, message: each.message })
                else
                    predefinedData[cat] = [{ id: each.id, message: each.message }]
            })
        }
        else {
            console.error(usr_res.err);
        }


        this.state.predefinedMessages = predefinedData

    }

    addPredefinedMessage = async (message, category) => {
        let data = null;

        await api.post(`api/users/${this.state.userId}/predefinedmessages/`, {
            message: message,
            category: category
        }).then(async (res) => {
            if (res.status === 201) {
                data = res.data;

                const predefinedMessages = this.state.predefinedMessages;
                predefinedMessages.push(res.data);

                useNotification(
                    'Sucesso!',
                    'Mensagem predefinida cadastrada com sucesso.',
                    'success'
                );

                this.setState({
                    predefinedMessages: predefinedMessages
                });
            }

            else if (res.status === 400) {
                useNotification(
                    'Houve um problema!',
                    res.data,
                    'danger'
                );
            }
        }).catch(async (err) => {
            console.error(err);
        });

        return data;
    }

    updatePredefinedMessage = (message) => {
        api.patch(`api/users/${this.state.userId}/predefinedmessages/${message.id}/`, {
            message: message.message
        }).then((res) => {
            if (res.status === 200) {
                useNotification(
                    'Sucesso!',
                    'Mensagem predefinida alterada com sucesso.',
                    'success'
                );
            }
        }).catch((err) => {
            console.error(err);
        });
    }

    removePredefinedMessage = (message) => {
        api.delete(`api/users/${this.state.userId}/predefinedmessages/${message.id}/`)
            .then((res) => {
                if (res.status === 204) {
                    const predefinedMessages = this.state.predefinedMessages;
                    for (var i = 0; i < predefinedMessages.length; i++) {
                        if (predefinedMessages[i].id == message.id) {
                            predefinedMessages.splice(i, 1);
                        }
                    }
                    this.setState({
                        predefinedMessages: predefinedMessages
                    });
                    useNotification(
                        'Sucesso!',
                        'Mensagem predefinida excluída com sucesso.',
                        'success'
                    );
                }
            })
            .catch((err) => {
                console.error(err);
            });
    }

    fetchHistoryMessages = (row) => {
        api.get(`/api/LHC/chats/${row.hash}/`)
            .then((res) => {
                if (res.status == 200) {
                    let state = {
                        historyChat : res.data,
                        showChatHistory: true,
                        historyMessages: res.data.messages,
                        platform: res.data.platform,
                        uniqueId: row.uniqueid,
                        lastDateTime: res.data.time
                    };
                    if (this.props.isManageCalls) {
                        state.showModal = false;
                    }
                    this.setState(state);
                    return;
                }
                throw res;
            })
            .catch((err) => {
                console.error(err);
            });
    }

    setActiveTab = (tab) => {
        this.setState({ activeTab: tab });
    }

    setActiveSubTab = (tab) => {
        this.setState({ activeSubTab: tab, activeChat: {} });
    }

    setActiveChat = (chat) => {
        if (Object.keys(chat).length === 0 && this.state.socket !== null)
            this.closeWebSocket();
        else
            this.connectWebSocket(chat);

        this.setState({
            activeChat: chat,
            showChatRightSideBar: false
        });

        if (getSession().profile.has_assistant) {
            if (window.setExternalId && typeof window.setExternalId === 'function') {
                if (Object.keys(chat).length !== 0) {
                    window.setExternalId()
                    window.setExternalId(chat.uniqueid)
                    if (window.canSendMessage && typeof window.canSendMessage === 'function') {
                        window.canSendMessage(chat.chatstatus && (chat.chatstatus === "QUEUE" || chat.chatstatus === "ONCHAT"))
                    }
                    return
                }
                window.setExternalId()
                if (window.canSendMessage && typeof window.canSendMessage === 'function') {
                    window.canSendMessage(true)
                }
            }
        }
    }

    connectWebSocket = (activeChat) => {
        
        this.closeWebSocket(); // Close any existing socket

        let channel = (activeChat)
            ? activeChat.channel
            : this.state.activeChat.channel
            ;

        if (!channel) return; // Avoid opening undefined socket connections

        const hs = window.location.hostname;

        window?.blockChat && window.blockChat(true)

        let socket = new WebSocket(`wss://${hs}/chat/ws/chat/${channel}/`);

        activeChat.socket = socket    
        
        let that = this;
        let keepAlive = null;

        socket.onopen = (e) => {
            window?.blockChat && window.blockChat(false)
            keepAlive = setInterval(() => {
                // If server not responding for more than 30 seconds
                // the connection might have been closed somewhere
                // we need to close and reopen a new websocket connection
                let curTime = new Date().getTime();
                if (that.state.lastSocketResponse && curTime - that.state.lastSocketResponse > 20000) {
                    socket.close();
                    that.refreshActiveChat();
                    that.connectWebSocket();
                    return;
                }

                // Pinging server every 20secs
                socket.send(JSON.stringify({
                    user: '0',
                    message: 'PING'
                }));
            }, 20000);
        }

        socket.onmessage = (e) => {

            const data = JSON.parse(e.data);
            const curDate = new Date();
            const [hour, minutes] = curDate.toTimeString().split(':', 2)
            const curTime = Math.floor(Date.now() / 1000);

            that.lastSocketResponse = new Date().getTime()

            let message = {
                message: data.message,
                iso_time: hour + ':' + minutes,
                user_id: parseInt(data.user),
                time: curTime,
            };

            if (message.user_id > 0)
                return

            const infinite_scroll_messages = this.messageArea?.children?.messageArea?.messages
            const normal_messages = this.messageArea?.children?.state?.messages
            let messages = (infinite_scroll_messages || normal_messages)

            // let messages = that.state.activeChat.messages;
            if (!messages) return;

            if (message.user_id === 0)
                that.state.activeChat.last_user_msg_time = curTime
            if ('reloadMessageArea' in window) window.reloadMessageArea(messages)

            // Received duplicated message
            if (message.user_id == -2) {
                const realData = JSON.parse(message.message)
                message = realData
                message.time = curTime
                message.iso_time = hour + ':' + minutes

                if (infinite_scroll_messages){
                    this.messageArea.children.messageArea.handleAddNewMessage(message)
                    return
                }
                const oldMessage = messages.find(each => each.id === message.id)
                if (oldMessage){
                    oldMessage.time = message.time
                    oldMessage.iso_time = message.iso_time
                    messageArea?.current?.children.handleAddMessage()
                    return
                }
            }
            // Received signal from supervisor
            else if (message.user_id == -3) {

                const realData = JSON.parse(message.message)
                const oldMessage = messages.find(each => each.id == realData.id)
                oldMessage.status = realData.status
                oldMessage.time = curTime
                oldMessage.iso_time = hour + ':' + minutes
                if (infinite_scroll_messages){
                    this.messageArea.children.messageArea.handleAddNewMessage(oldMessage)
                    return
                }
                this.messageArea.children.handleAddMessage()
                return
            }
            // Received drop_chat from transfer
            else if (message.user_id == -4) {
                const realData = JSON.parse(message.message)
                if ('chatID' in realData && realData.chatID == that.state.activeChat.id) {
                    this.closeChat()
                    useNotification(
                        'Informação',
                        `O chat foi transferido para a fila ${realData.queue} por ${realData.actor}.`,
                        'info'
                    );
                }
                return
            }
            // Received drop_chat from hangup
            else if (message.user_id == -5) {
                const realData = JSON.parse(message.message)
                if ('chatID' in realData && realData.chatID == that.state.activeChat.id) {
                    this.closeChat()
                    useNotification(
                        'Informação',
                        `O chat foi finalizado por ${realData.actor}.`,
                        'info'
                    );
                }
                return
            }

            // let lastMessage = messages[messages.length - 1];
            // if (!that.isSameMessage(lastMessage, message) && message.message != 'PONG') {
            if (message.message != 'PONG') {
                this.messageArea.children.handleAddMessage(message)
                if (this.messageArea.children.shouldRefresh()) {
                    that.state.activeChat.messages = [...messages, message]
                    this.setState({ activeChat: { ...that.state.activeChat } })
                }
                
            }




            // }

            // Doing this will rerrender everything
            // let activeChat = that.state.activeChat;
            // activeChat.messages = [...activeChat.messages, message];



        };

        socket.onclose = (e) => {
            window?.blockChat && window.blockChat(false)
            clearInterval(keepAlive);
            that.state.lastSocketResponse = null;
        };

        this.state.socket = socket;
    }    

    isSameMessage = (message1, message2) => {
        return message1.user_id == message2.user_id &&
            message1.message == message2.message
    }

    closeWebSocket = () => {
        if (this.state.socket) {
            this.state.socket.close();
        }
        this.state.socket = null;
    }

    addOngoingChat = (chat) => {

        this.setUpdateList()
        // if (window.innerWidth > 960) {
        //     // Avoiding mobile error
        //     this.conversationList.addOngoingChat(chat);   
        // }
    }

    addReactivatedChat = (chat) => {
        this.setUpdateList()
        // this.conversationList.addReactivatedChat(chat);
    }

    removeOngoingChat = (chat) => {
        this.setUpdateList()
        // this.conversationList.removeOngoingChat(chat);
    }



    showChatRightSideBar = (contact) => {
        if (!isMobile()) {
            this.setState({
                showChatRightSideBar: !this.state.showChatRightSideBar,
                contactData: contact,
            });
        }
    }

    closeProfile = () => {
        this.setState({
            showProfile: false,
            contactData: {},
            renderKey: Math.random()
        });
    }

    resetActiveChatProgress = () => {
        this.setUpdateList()
        // this.conversationList.resetActiveChatProgress();
    }

    refreshActiveChat = () => {
        this.setUpdateList()
        // ;
        // this.conversationList.refreshActiveChat();
    }

    isManageCalls = () => {
        return this.props.isManageCalls;
    }

    notIsManageCalls = () => {
        return !this.props.isManageCalls;
    }

    lastDateTime = () => {
        return (this.state.activeChat.chatstartts || this.state.activeChat.time) * 1000;
    }

    openChat = (chat) => {
        this.setState({
            activeChat: chat,
            showModal: true
        });

    }

    closeChat = () => {
        this.setState({
            activeChat: {},
            showModal: false,
            showProfile: false,
            renderKey: Math.random(),
            showChatRightSideBar: false
        });
    }

    handleCloseHistoryMessages = () => {
        let state = {
            showChatHistory: false
        };
        if (this.props.isManageCalls) {
            state.showModal = true;
        }
        this.setState(state);
    }

    updateMessages = (message, index, status) => {
        //this.forceUpdate();
        // const activeChat = this.state.activeChat;
        // this.setState({ activeChat: activeChat });
    }

    setUpdateList = (target) => {
        this.setState({ updateList: !this.state.updateList })
    }

    connectEmailChat = () => {
        this.messageArea.children.connectActiveChat()
    }

    buildChatList = () => {
        if (!this.state.ready)
            return (
                <Box id="conversationList-box-4">
                    <CircularProgress />
                </Box>
            )

        return (

            <NewConversationList
                enterpriseId={this.state.enterpriseId}
                agentId={this.state.agentId}
                activeTab={this.state.activeTab}
                setActiveTab={this.setActiveTab}
                activeSubTab={this.state.activeSubTab}
                setActiveSubTab={this.setActiveSubTab}
                activeChat={this.state.activeChat}
                setActiveChat={this.setActiveChat}
                connectEmailChat={this.connectEmailChat}
                closeProfile={this.closeProfile}
                openChat={(chat) => this.openChat(chat)}
                isManageCalls={this.props.isManageCalls}
                setReadMessages={this.setReadMessages}
                notificationAudio={this.state.notificationAudio}
                key={this.state.renderKeyList}
                refresh={() => { this.setState({ refresh: !this.state.refresh }) }}
                refreshChat={() => { this.messageArea?.children?.refreshChat() }}
                loading={this.state.loading}
                labels={this.state.chatLabels}
                updateList={this.state.updateList}
                allGroups={this.state.allGroups}
            />
        );
    }

    buildMessageArea = () => {
        return (
            <>
                {this.state.showChatHistory &&
                    <HistoryMessagesDialog
                        activeChat={this.state.historyChat}
                        open={this.state.showChatHistory}
                        uniqueId={this.state.uniqueId}
                        nick={this.state.contactData ? this.state.contactData.nick : null}
                        lastDateTime={this.state.lastDateTime}
                        historyMessages={this.state.historyMessages}
                        platform={this.state.platform}
                        accountcode={this.state.accountcode}
                        handleClose={() => this.handleCloseHistoryMessages()}
                    />
                }
                <MessagesArea
                    ref={
                        instance => {
                            this.messageArea = instance
                        }
                    }
                    activeChat={this.state.activeChat}
                    activeTab={this.state.activeTab}
                    activeSubTab={this.state.activeSubTab}
                    setActiveChat={this.setActiveChat}
                    setActiveTab={this.setActiveTab}
                    setActiveSubTab={this.setActiveSubTab}
                    agentId={this.state.agentId}
                    enterpriseId={this.state.enterpriseId}
                    addOngoingChat={this.addOngoingChat}
                    addReactivatedChat={this.addReactivatedChat}
                    removeOngoingChat={this.removeOngoingChat}
                    predefinedMessages={this.state.predefinedMessages}
                    addPredefinedMessage={this.addPredefinedMessage}
                    updatePredefinedMessage={this.updatePredefinedMessage}
                    removePredefinedMessage={this.removePredefinedMessage}
                    accountcode={this.state.accountcode}
                    showChatRightSideBar={this.showChatRightSideBar}
                    connectWebSocket={this.connectWebSocket}
                    closeWebSocket={this.closeWebSocket}
                    resetActiveChatProgress={this.resetActiveChatProgress}
                    enterpriseQueues={this.state.enterpriseQueues}
                    contactData={this.state.contactData}
                    renderKey={this.state.renderKey}
                    key={this.state.renderKey}
                    templates={this.state.templates}
                    isManageCalls={this.props.isManageCalls}
                    closeModal={() => this.closeChat()}
                    updateMessages={this.updateMessages}
                    setReadMessages={this.setReadMessages}
                    connections={this.props.connections}
                    setChatLabels={this.setChatLabels}
                />
            </>
        );
    }

    buildProfile = () => {
        return (
            <Profile
                showProfile={this.state.showProfile}
                contactData={this.state.contactData}
                closeProfile={() => this.setState({ showProfile: false })}
                updateField={() => this.setState({ renderKey: Math.random() })}
                isManageCalls={this.props.isManageCalls}
                fetchHistoryMessages={(row) => this.fetchHistoryMessages(row)}
                agentCanUpdateContact={this.agentCanUpdateContact}
            />
        );
    }

    reloadContact = () => {
        this.messageArea.children.reloadContact()
    }

    buildChatRightSideBar = () => {
        return (
            <ChatRightSideBar
                agentCanUpdateContact={this.agentCanUpdateContact}
                contactData={this.state.contactData}
                enterprise={this.state.enterpriseId}
                closeChatRightSideBar={() => this.setState({ showChatRightSideBar: false })}
                updateField={() => this.setState({ renderKey: Math.random() })}
                isManageCalls={this.props.isManageCalls}
                fetchHistoryMessages={(row) => this.fetchHistoryMessages(row)}
                allLabels={this.state.allLabels}
                chatLabels={this.state.chatLabels}
                setChatLabels={this.setChatLabels}
                chat={this.state.activeChat}
                renderLabels={this.state.activeTab !== 2}
                reloadContact={this.reloadContact}
                chatCursor={this.messageArea?.children?.messageArea?.chatCursor}
                allGroups={this.state.allGroups}
                setChatGroups={this.setChatGroups}
            />
        );
    }

    buildManageCalls = () => {
        return (
            <Box className="manage-calls">
                <ModalMessagesArea
                    open={this.state.showModal}
                    handleClose={() => this.closeChat()}
                    buildMessageArea={this.buildMessageArea}
                    buildProfile={this.buildChatRightSideBar()}
                    showProfile={this.state.showChatRightSideBar}
                    key={this.state.renderKey}
                />
                {this.buildChatList()}
            </Box>
        );
    }

    async componentWillMount() {
        this.setState({ loading: true })
        const user = getSession();
        
        if (user) {
            this.state.userId = user.id;
            this.state.agentId = user.profile.agent_id;
            this.state.enterpriseId = user.profile.enterprise.id;
            this.state.agentUser = user.profile.agent.user;
        }
        await this.fetchEnterprise();
        await this.fetchPredefinedMessages();
        await this.fetchTemplates();
        this.setState({ loading: false })
        await this.getLabels();
        await this.getGroups();         

        this.setState({ ready: true })
    }

    componentWillUnmount() {
        this.closeWebSocket();

        if (getSession().profile.has_assistant) {
            if (window.setExternalId && typeof window.setExternalId === 'function') {
                window.setExternalId()
            }
            if (window.canSendMessage && typeof window.canSendMessage === 'function') {
                window.canSendMessage(true)
            }
        }
        
    }

    setReadMessages = (messages) => {
        if (messages.length === 0)
            return;

        // Setting unreadMessages is_read
        const unreadMessagesIds = [];
        messages.filter(each=> !each.is_read).forEach((message) => {
            unreadMessagesIds.push(message.id);
        });

        if (unreadMessagesIds.length === 0)
        return;

        api.post('/api/LHC/messages/set_read/', {
            messages: unreadMessagesIds
        }).then((res) => {
            if (res.status === 200)
                return;
            throw res;
        }).catch((err) => {
            console.error(err);
        });
    }

    shouldRenderChatList = () => {
        return !isMobile() || (
            isMobile() &&
            Object.keys(this.state.activeChat).length === 0 &&
            Object.getPrototypeOf(this.state.activeChat) === Object.prototype
        );
    }

    shouldRenderMessageArea = () => {
        return !isMobile() || (
            isMobile() &&
            Object.keys(this.state.activeChat).length !== 0 &&
            Object.getPrototypeOf(this.state.activeChat) === Object.prototype
        );
    }

    getLabels = async () => {

        await api.get(`api/enterprises/${this.state.enterpriseId}/labelchat/`)
            .then((res) => {
                if (res.status === 200) {
                    this.setState({
                        allLabels: res.data
                    })
                }
                if (res.status === 404) {
                    if ('detail' in res.data && res.data.detail == 'Not found.') {
                        useNotification(
                            'Não encontradas!',
                            'Etiquetas não encontradas.',
                            'danger'
                        );
                    }
                }
                return;
            })
            .catch((err) => {
                console.error(err);
            });
    }

    getGroups = async () => {
        const response = await api.get(`api/enterprises/${this.state.enterpriseId}/contact_group/`)
        if (response.status === 200)
            this.setState({ allGroups: response.data })
    }

    setChatLabels = (labels) => {
        this.setState({
            chatLabels: labels
        })
    }

    setChatGroups = (chat) => {
        this.setState({ refresh: ! this.state.refresh })
    }

    render() {
        return (
            <Box className="kwik-main-chat">
                <ReactNotifications />
                <Grid container>
                    {this.notIsManageCalls() &&
                        <>
                            <Grid
                                style={{ display: this.shouldRenderChatList() ? 'flex' : 'none' }}
                                item lg={3} md={3} sm={6} xs={12} className="column-grid">
                                <Box className="conversationList-box">
                                    {this.buildChatList()}
                                </Box>
                            </Grid>

                            {this.shouldRenderMessageArea() &&
                                <Grid item lg={true} md={true} sm={6} xs={12} className="column-grid">
                                    <Box className="message-box">
                                        {this.buildMessageArea()}
                                    </Box>
                                </Grid>
                            }
                            {this.state.showChatRightSideBar &&
                                <Grid item lg={3} md={4} sm={12} xs={12}
                                    className="column-grid"
                                >
                                    {this.buildChatRightSideBar()}
                                </Grid>
                            }
                        </>
                    }
                    {this.isManageCalls() &&
                        <>
                            {this.buildManageCalls()}
                        </>
                    }
                </Grid>
            </Box>
        );
    }
}

export default Chat;
