import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import LoadingButton from '@mui/lab/LoadingButton';
import { Divider, TextField, Tooltip } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ConfirmationDialogCaptcha = ({ open, title, description, handleClose, handleSubmit, submitButtonLoading, captcha }) => {

    const [captchaAnswer, setCaptchaAnswer] = useState('')

    return (
        <div>

            <Dialog
                open={open}
                //TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
                disablePortal
                disableEnforceFocus
                sx={{position:'absolute'}}
            >
                <DialogTitle id="alert-dialog-slide-title" className={'group-flexbox'}>
                    <span>{title}</span>
                    <Tooltip title="Fechar" >
                        <Button className="action-button"  >
                            <CloseIcon onClick={handleClose} />
                        </Button>
                    </Tooltip>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        {description}
                    </DialogContentText>
                    <TextField
                        size="small"
                        label="Nome"
                        fullWidth
                        type="text"
                        variant="outlined"
                        className="push-top"
                        value={captchaAnswer}
                        InputLabelProps={{ shrink: true }}
                        onChange={(event) => { setCaptchaAnswer(event.target.value) }}

                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleClose}
                        color="primary">
                        Não
                    </Button>
                    <LoadingButton
                        onClick={handleSubmit}
                        color="primary"
                        loading={submitButtonLoading}
                        disabled={captchaAnswer != captcha}
                    >
                        Sim
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default ConfirmationDialogCaptcha;