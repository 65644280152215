import React, { useState, useContext, useMemo, createContext, useEffect } from 'react'

import { ScreenContext } from '../..'
import {
    DataGridPro,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    ptBR,
    getGridStringOperators,
    GridToolbarExportContainer,
    getDataGridUtilityClass,
    getGridDateOperators,
    getGridSingleSelectOperators,
    gridClasses,
    useGridApiRef,
    useGridApiContext,
    useGridSelector,
    gridFilteredDescendantCountLookupSelector
} from '@mui/x-data-grid-pro';

import { GridToolbarImportClients } from './ClientContainer/GridToolbarImportClients';

import { ptBR as corePtBR } from '@mui/material/locale';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { 
    Box, 
    Button, 
    ListItem, 
    Tooltip, 
    IconButton, 
    FormControlLabel, 
    Switch 
} from '@mui/material';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import LoadingButton from '@mui/lab/LoadingButton';
import ClientGroupButton from './ClientGroupButton';
import ClientDialog from '../../../Dialogs/Client';
import ConfirmationDialog from '../../../Dialogs/Confirmation';
import MergeClientDialog from '../../../Dialogs/MergeClient';
import api from '../../../../api';
import useNotification from '../../../Notification';
import AddClientGroupForm from './AddClientGroupForm';
import EditGroupNameModal from './EditGroupNameModal';
import EditGroupMembersModal from './EditGroupMembersModal';
import AddClient from '../../TabButtons/AddClient';
import ImportClients from '../../TabButtons/ImportClients';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Information from '../../TabButtons/Information';
import ConfirmationDialogCaptcha from '../../../Dialogs/ConfimationDialogCaptcha';
import PhoneIcon from '@mui/icons-material/PhoneAndroid';
import PhoneCallIcon from '@mui/icons-material/Phone';
import { getSession } from '../../../../auth';
import isMobile from '../../../../utils/isMobile';

import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TelegramIcon from '@mui/icons-material/Telegram';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import MicrosoftIcon from '@mui/icons-material/Groups';
import EmailIcon from '@mui/icons-material/Email';
import ReceiptIcon from '@mui/icons-material/Receipt';
import GroupIcon from '@mui/icons-material/GroupAdd';
import SettingsIcon from '@mui/icons-material/Settings';


import './styles.css'
import UserChats from './UserChats';

function CustomGridTreeDataGroupingCell(props: GridRenderCellParams) {
  const { id, field, rowNode } = props;
  const apiRef = useGridApiContext();
  const filteredDescendantCountLookup = useGridSelector(
    apiRef,
    gridFilteredDescendantCountLookupSelector,
  );
  const filteredDescendantCount = filteredDescendantCountLookup[rowNode.id] ?? 0;

  const handleClick: ButtonProps['onClick'] = (event) => {
    apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded);
    apiRef.current.setCellFocus(id, field);
    event.stopPropagation();
  };

  return (
    <Box sx={{ ml: rowNode.depth * 4 }}>
      <div>
        {filteredDescendantCount > 0 ? (
          <Button onClick={handleClick} tabIndex={-1} size="small">
            {props.formattedValue} ({filteredDescendantCount})
          </Button>
        ) : (
          <span />
        )}
      </div>
    </Box>
  );
}

const ClientsTable = () => {

    const {
        isSmallScreen,
        enterpriseId,
        settings,
        clients,
        updateClients,
        updateReady,
        updateGroups,
        updateCampaigns,
        groups,
        rmvObj,
        subsObj,
        updateSelectedModel,
        ServerData,
        getClients,
        updateLimitOffset,
        limitOffset,
        filterModel,
        sortModel,
        permissions,
        selectedNumberModel,
        updateNumberSelectedModel,
    } = useContext(ScreenContext)

    const [editClientDialog, updateEditClientDialog] = useState(false)
    const [deleteClientDialog, updateDeleteClientDialog] = useState(false)
    const [massDeleteClientDialog, updateMassDeleteClientDialog] = useState(false)
    const [showAddClientGroupForm, updateShowAddClientGroupForm] = useState(false)
    const [deleteClientGroupModal, updateDeleteClientGroupModal] = useState(false)
    const [editClientGroupModal, updateEditClientGroupModal] = useState(false)
    const [editGroupMembersModal, updateEditGroupMembersModal] = useState(false)
    const [selectedClientModel, updateSelectedClientModel] = useState([])
    const [selectedClients, updateSelectedClients] = useState([])
    const [selectedClientGroup, updateSelectedClientGroup] = useState([])
    const [clientsFilter, updateClientsFilter] = useState({ items: [] })
    const [forceReload, updateForceReload] = useState(false)
    const [pageLimit, updatePageLimit] = useState(0)
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({ items: [] })
    const [clientsSort, updateClientsSort] = useState([])
    const [usersEmails, updateUsersEmails] = useState([])
    const [showChats, setShowChats] = useState(null)
    const [mergeClientDialog, updateMergeClientDialog] = useState(false)
    const [groupedByEnterprise, setGroupedByEnterprise] = useState(false)

    const handleAddClientGroupForm = () => {
        updateShowAddClientGroupForm(true)
    }
    const submitClientGroupForm = async () => {
        await updateSelectedClients([])
        updateNumberSelectedModel([])
        updateShowAddClientGroupForm(false)

    }
    const handleDeleteClientGroup = (value) => {
        updateDeleteClientGroupModal(true)
        updateSelectedClientGroup(value)
    }

    const apiRef = useGridApiRef()

    useEffect(() => {
        const handleColumnOrderChange = (params) => {
            const columns = getColumns()
            const auxiliar = columns[params.oldIndex - 1]
            columns[params.oldIndex - 1] = columns[params.targetIndex - 1]
            columns[params.targetIndex - 1] = auxiliar
            localStorage.setItem('crmClientGridColumns', JSON.stringify(columns))
        };
        apiRef.current.subscribeEvent('columnOrderChange', handleColumnOrderChange)
    }, [apiRef])

    useEffect(() => {
        updateSelectedModel(selectedClients)
        updateClientsFilter(filterModel.clients)
        updateClientsSort(sortModel.clients)
    }, [selectedClients])

    useEffect(() => {
        setColumnVisibilityModel({
            email: !isSmallScreen,
            created_at: !isSmallScreen,
            enterprise_name: false,
            enterprise_cnpj: false,
            updated_at: false,
            channels: false
        })
    }, [isSmallScreen])

    const addSelectedClientsToGroup = async (selectedGroup) => {

        updateNumberSelectedModel([])
        updateReady(false)

        const payload = []
        selectedClients.forEach((client) => {
            payload.push({
                group_id: selectedGroup.id,
                contact_id: client
            })
        })

        const response = await api.post(`api/enterprises/${enterpriseId}/contact_group_contacts/bulk_create/`, payload)

        if (response.status === 201) {
            await getClients()
            useNotification(
                'Sucesso!',
                'Segmento atualizado!',
                'success'
            );
        } else {
            useNotification(
                'Ops!',
                'Algo deu errado, tente novamente',
                'danger'
            );
        }

        updateReady(true)
    }

    const submitDeleteClientGroupModal = async () => {
        updateReady(false)
        updateNumberSelectedModel([])

        const url = `/api/enterprises/${enterpriseId}/contact_group/${selectedClientGroup.id}/`;
        const response = await api.delete(url)

        if (response.status == 403) {
            useNotification(
                'Ops!',
                'Você não tem permissão para ver esse conteúdo',
                'danger'
            );
            return
        }

        updateGroups(prevState => rmvObj(prevState, selectedClientGroup.id))
        updateClients(prevState =>
            prevState.map(each => {
                each.groups = each.groups.filter(group => group.id !== selectedClientGroup.id)
                return each
            }))
        updateCampaigns(prevState =>
            prevState.map(each => {
                each.groups = each.groups.filter(group => group.group !== selectedClientGroup.id)
                return each
            }))


        useNotification(
            'Sucesso!',
            'Segmento deletado!',
            'success'
        );
        updateDeleteClientGroupModal(false)
        updateReady(true)
    }
    const handleEditClientGroup = (value) => {
        updateEditClientGroupModal(true)
        updateSelectedClientGroup(value)
    }

    const submitEditModal = async () => {
        updateNumberSelectedModel([])
        updateReady(false)
        updateReady(true)
        useNotification(
            'Sucesso!',
            'Segmento atualizado!',
            'success'
        );
    }

    const handleEditClientGroupMembers = (value) => {
        updateEditGroupMembersModal(true)
        updateSelectedClientGroup(value)
    }



    const showEditClientDialog = async (row) => {
        await fetchUsersEmails()
        await updateSelectedClientModel(row)
        updateEditClientDialog(true)
    }
    const showDeleteClientDialog = (row) => {
        updateDeleteClientDialog(true)
        updateSelectedClientModel(row)
    }

    const isNumber = (n) => {
        return !isNaN(parseFloat(n)) && isFinite(n);
    }

    const formatPhone = (telephone) => {
        let newTelephone = '';
        if (telephone) {
            const numberTelephone = telephone.replace(/[^0-9]+/g, '');
            if (numberTelephone.length < 10)
                return false
            if (isNumber(numberTelephone) && numberTelephone.substring(0, 3) !== '+55')
                newTelephone = `+55${numberTelephone}`;
            else
                newTelephone = telephone;
        }
        return newTelephone;
    }

    const changeClient = async (id, name, initialEmail, email, initialTelephone, telephone, userFields, advisor, enterpriseName, enterpriseCNPJ, customer_code, groups) => {

        updateSelectedClientModel([]);
        updateNumberSelectedModel([])
        updateEditClientDialog(false);

        updateReady(false)
        const data = {
            name: '',
            email: '',
            telephone: '',
            enterprise_name: '',
            enterprise_cnpj: '',
            customer_code: ''
        };

        if (name)
            data['name'] = name;

        if (email)
            data['email'] = email;

        if (telephone) {
            const number = formatPhone(telephone)
            if (!number) {
                useNotification(
                    'Telefone inválido!',
                    `Insira um número de telefone com DDD + 8 ou 9 dígitos.`,
                    'warning'
                )
                updateEditClientDialog(true)
                updateReady(true)
                return
            }
            data['telephone'] = number
        }

        if (userFields)
            data['user_fields'] = userFields;

        if (advisor)
            data['advisor'] = advisor;

        if (enterpriseName)
            data['enterprise_name'] = enterpriseName;

        if (enterpriseCNPJ)
            data['enterprise_cnpj'] = enterpriseCNPJ;

        if (customer_code)
            data['customer_code'] = customer_code;

        data['groups'] = Object.keys(groups).filter(key => groups[key].checked)

        const res = await api.patch(`/api/enterprises/${enterpriseId}/contacts/${id}/`, data)

        updateReady(true)

        if (res.status == 205) {
            getClients()
            useNotification('Sucesso!', 'Cliente atualizado', 'success');
            return
        }

        if (res.status == 200) {
            const newclient = res.data
            updateClients(clients.map(each => (each.id === newclient.id ? newclient : each)))
            useNotification('Sucesso!', 'Cliente atualizado', 'success');
            return
        }

        if (res.status == 400 && 'advisor' in res.data) {
            useNotification(
                'Assessor não encontrado',
                `Email de assessor ${data['advisor']} não existe no nosso banco de dados`,
                'danger'
            )
            return
        }

        let message = 'Ocorreu um erro no sistema'
        if (res.data.detail == 'Não encontrado.')
            message = 'O cliente não foi encontrado.'

        useNotification('Ops!', message, 'danger');

        getClients()
    }

    const deleteClient = async (client) => {
        updateNumberSelectedModel([])
        updateReady(false)
        const res = await api.delete(`/api/enterprises/${enterpriseId}/contacts/${client.id}/`)
        updateReady(true)
        if (res.status == 204) {
            useNotification(
                'Sucesso!',
                'Cliente removidos com sucesso',
                'success'
            );
            updateClients(clients.filter(each => client.id !== each.id))
            return
        }
        let message = 'Ocorreu um erro no sistema'
        if (res?.data?.detail == 'Não encontrado.')
            message = 'O cliente não foi encontrado.'
        useNotification(
            'Ops!',
            message,
            'danger'
        );
        getClients()
    }

    const deleteClients = async () => {
        updateNumberSelectedModel([])
        updateReady(false)
        const res = await api.post(`/api/enterprises/${enterpriseId}/contacts/bulk_delete/`, { clients_ids: selectedClients })
        updateReady(true)
        if (res.status == 204) {
            useNotification(
                'Sucesso!',
                'Clientes removidos com sucesso',
                'success'
            );
            await getClients()
            //updateClients(clients.filter(each => !selectedClients.includes(each.id)))
            return

        }
        let message = 'Ocorreu um erro no sistema'
        if (res.data.detail === 'Não encontrado.')
            message = 'Os clientes não foram encontrados.'
        if (res.data === "You can't delete when integrated with other CRM")
            message = 'Os clientes não podem ser deletados em massa pelo kwik, apenas pelo CRM integrado.'
        useNotification(
            'Ops!',
            message,
            'danger'
        );
        getClients()
    }

    const fetchUsersEmails = async () => {

        if (!settings.is_financial_market_company) {
            return
        }

        const res = await api.get(`/api/enterprises/${enterpriseId}/users/get_users_emails/`)

        if (res.status == 200) {
            await updateUsersEmails(res.data)
            return
        }
        if (res.status == 404) {
            return
        }
    }

    const webphoneKeypadKeysMapper = {
        '1': 0,
        '2': 1,
        '3': 2,
        '4': 3,
        '5': 4,
        '6': 5,
        '7': 6,
        '8': 7,
        '9': 8,
        '*': 9,
        '0': 10,
        '#': 11
    };

    const callContact = (phone_number) => {
        document.getElementById('webphone-icon').click();

        if (phone_number) {
            const number = phone_number.replace('+55', '0');

            const webphone = document.getElementById('tryit-jssip-container');
            const keypad = webphone.querySelector('.keypad.buttons');
            const backButton = webphone.querySelector('.button.back.no-sub');
            const callButton = webphone.querySelector('.button.call.no-sub');

            if (!webphone || !keypad || !callButton) {
                return;
            }

            // Clear any number from display
            [...Array(15)].forEach(() => backButton.click());

            const keys = keypad.querySelectorAll('.button.digit');
            for (var i = 0; i < number.length; i++) {
                const char = number.charAt(i);
                const charIndex = webphoneKeypadKeysMapper[char];
                keys[charIndex].click();
            }

            callButton.click();
        }
    }

    const notifyNoPermission = () => {
        useNotification(
            'Ops!',
            'Você não tem permissão para executar essa ação.',
            'danger'
        );
    }

    const canChange = () => {
        if (settings.agent_can_update_contact
            || permissions.isAdmin
            || permissions.isSupervisor
            || permissions.isNotOnlyOperator
        ) {
            return true;
        }
        return false;
    }

    // Blocking sorting for integrations
    const sortable = (settings.integration_source) ? false : true;

    const filterOperators = getGridStringOperators().filter((operator) => ['contains', 'equals'].includes(operator.value))
    const dateOperators = getGridDateOperators().filter((operator) => ['after', 'before'].includes(operator.value))
    const choiceOperators = getGridSingleSelectOperators().filter((operator) => ['is', 'not'].includes(operator.value))

    const dynamicColumnsProperties = {
        'internal_code': { filterOperators: filterOperators },
        'name': { filterOperators: filterOperators },
        'telephone': { filterOperators: filterOperators },
        'email': { filterOperators: filterOperators },
        'customer_code': { filterOperators: filterOperators },
        'enterprise_name': { filterOperators: filterOperators },
        'enterprise_cnpj': { filterOperators: filterOperators },
        'advisor': { filterOperators: filterOperators },
        'created_at': {
            filterOperators: dateOperators,
            valueGetter: ({ value }) => {
                if (value) {
                    const date = new Date(value);
                    date.setHours(date.getHours() - 3);
                    return date;
                }
            },
            renderCell: (params) => (
                <Box>
                    {params.value ? params.value.toLocaleString('pt-BR') : ''}
                </Box>
            )
        },
        'updated_at': {
            filterOperators: dateOperators,
            valueGetter: ({ value }) => {
                if (value) {
                    const date = new Date(value);
                    date.setHours(date.getHours() - 3);
                    return date;
                }
            },
            renderCell: (params) => (
                <Box>
                    {params.value ? params.value.toLocaleString('pt-BR') : ''}
                </Box>
            )
        },
        'channels': {
            filterOperators: choiceOperators,
            valueOptions: ['WHATSAPP', 'TELEGRAM', 'INSTAGRAM', 'MESSENGER', 'EMAIL', 'MSTEAMS'],
            renderCell: (params) => (
                <>
                    {'telephone' in params.row && params.row.telephone &&
                        <WhatsAppIcon sx={{ color: '#34af23', marginRight: '0.25rem' }} />
                    }
                    {'telegram_id' in params.row && params.row.telegram_id &&
                        <TelegramIcon sx={{ color: '#3390ec', marginRight: '0.25rem' }} />
                    }
                    {'msteams_id' in params.row && params.row.msteams_id &&
                        <MicrosoftIcon sx={{ color: '#6264A7', marginRight: '0.25rem' }} />
                    }
                    {'instagram_id' in params.row && params.row.instagram_id &&
                        <InstagramIcon sx={{ color: '#FD6B10', marginRight: '0.25rem' }} />
                    }
                    {'email' in params.row && params.row.email &&
                        <EmailIcon sx={{ color: '#757575', marginRight: '0.25rem' }} />
                    }
                    {'messenger_id' in params.row && params.row.messenger_id &&
                        <FacebookIcon sx={{ color: '#0866ff', marginRight: '0.25rem' }} />
                    }
                </>
            )
        },
        'groups': {
            filterOperators: filterOperators,
            valueGetter: ({ value }) => {
                if (value)
                    return value.map(each => each.name).join()
                return ''
            },
            renderCell: (params) => (
                params.row.groups
                    ? <>
                        {params.row.groups.map((group) =>
                            <Box onClick={() => { filterOnClick(group.name, group.id) }} className='client-group-item pointer'>
                                {group.name}
                            </Box>
                        )}
                    </>
                    : <></>
            )
        },
        'action': {
            renderCell: (params) => (
                <Box>
                    <Tooltip title="Histórico de conversas" interactive>
                        <Button className="action-button"
                            onClick={() => { setShowChats(params.row) }}
                            size="large">
                            <ReceiptIcon color="primary" />
                        </Button>
                    </Tooltip>
                    {getSession().profile.enterprise.has_voice &&
                        <Tooltip title="Ligar" interactive>
                            <Button className="action-button"
                                onClick={() => { callContact(params.row.telephone) }}
                                size="large">
                                <PhoneCallIcon color="primary" />
                            </Button>
                        </Tooltip>
                    }
                    <Tooltip title="Editar" interactive>
                        <Button className="action-button"
                            onClick={canChange() ? () => { showEditClientDialog(params.row) } : () => { notifyNoPermission() }}>
                            <EditIcon />
                        </Button>
                    </Tooltip>
                    <Tooltip title="Excluir" interactive>
                        <Button className="action-button delete"
                            onClick={canChange() ? () => { showDeleteClientDialog(params.row) } : () => { notifyNoPermission() }}>
                            <DeleteIcon />
                        </Button>
                    </Tooltip>

                </Box>
            )
        }
    }

    const getColumns = (hideEnterprise=false) => {
        const savedColumns = JSON.parse(localStorage.getItem('crmClientGridColumns'));
        const columns = (savedColumns) ? savedColumns : getDefaultColumns()
        const spreadedColumns = []
        columns.forEach((column) => {
            spreadedColumns.push({
                ...column,
                ...dynamicColumnsProperties[column.field]
            })
        })
        if (hideEnterprise)
            spreadedColumns.find((obj) => obj.field  === 'enterprise_name').hide = hideEnterprise
        return spreadedColumns
    }

    const getDefaultColumns = () => {
        let columns = [
            { field: 'internal_code', headerName: 'Código interno (KID)', flex: 1, hide: true },
            { field: 'name', headerName: 'Nome', flex: 1, sortable: sortable },
            { field: 'telephone', headerName: 'Telefone', flex: 1, sortable: sortable },
            { field: 'email', headerName: 'Email', flex: 1, sortable: sortable, hide: isSmallScreen },
            { field: 'customer_code', headerName: 'Código do cliente', flex: 1, sortable: sortable, hide: isSmallScreen },
            { field: 'enterprise_name', headerName: 'Empresa', flex: 1, hide: true, sortable: sortable },
            { field: 'enterprise_cnpj', headerName: 'CPF/CNPJ', flex: 1, hide: true, sortable: sortable },
            { field: 'created_at', type: 'dateTime', headerName: 'Criação', flex: 1, sortable: sortable, hide: isSmallScreen },
            { field: 'updated_at', type: 'dateTime', headerName: 'Última atualização', flex: 1, sortable: sortable, hide: true },
            { field: 'channels', headerName: 'Canais', flex: 0.5, sortable: false }
        ];

        if (settings.is_financial_market_company && permissions.isAdmin)
            columns.push({ field: 'advisor', headerName: 'Assessor', flex: 1, sortable: sortable });

        if (clients.length != 0 && 'user_fields' in clients[0]) {
            // Zendesk organization field
            columns.push({ field: 'organization_id', headerName: 'Organização', flex: 1, sortable: sortable, hide: isSmallScreen });
            // Zendesk integration custom fields
            Object.keys(clients[0].user_fields).forEach((field) => {
                const headerName = this.capitalize(field.replaceAll('_', ' '));
                columns.push({ field: field, headerName: headerName, flex: 1, sortable: sortable, hide: isSmallScreen });
            });
        }

        if (!settings.integration_source && !isSmallScreen)
            columns.push({ field: 'groups', headerName: 'Segmentos', flex: 1, sortable: false })

        if (!isSmallScreen)
            columns.push({ field: 'action', headerName: 'Ações', flex: 1, sortable: false, hide: false, filterable: false });

        return columns
    }

    const table = useMemo(() => {

        const theme = createTheme({
            palette: {
                primary: { main: '#21446C' },
            },
        }, ptBR, corePtBR);

        const CustomToolbar = () => {
            return (
                <GridToolbarContainer className={gridClasses.toolbarContainer}>
                    <Box className='toolbarLeft'>
                        <GridToolbarColumnsButton size="small" />
                        <GridToolbarFilterButton size="small" className={'filterControl'} />
                        <GridToolbarImportClients startIcon={<AddCircleIcon />}>
                            <AddClient groups={groups} />
                            {!(settings.integration_source || isMobile()) &&
                                <ImportClients />
                            }
                        </GridToolbarImportClients>
                        {!isMobile() &&
                            <ClientGroupButton
                                addclientgroup={handleAddClientGroupForm}
                                deleteClientGroupModal={handleDeleteClientGroup}
                                editClientGroupModal={handleEditClientGroup}
                                selectedClientGroup={handleEditClientGroupMembers}
                                filterOnClick={filterOnClick}
                                small={isSmallScreen}
                                selectedClients={selectedClients}
                                addSelectedClientsToGroup={addSelectedClientsToGroup}
                            />
                        }
                        <LoadingButton
                            component="label"
                            startIcon={<GroupIcon />}
                            loading={false}
                            onClick={() => { checkShowMergeDialog() }}
                            disabled={false}
                            size="small"
                        >
                            {isSmallScreen ? '' : 'Mesclar'}
                        </LoadingButton>
                        {!(settings.integration_source || isMobile()) &&
                            <LoadingButton
                                component="label"
                                startIcon={<DeleteIcon />}
                                loading={false}
                                onClick={() => { updateMassDeleteClientDialog(true) }}
                                disabled={false}
                                size="small"
                            >
                                {isSmallScreen ? '' : 'Excluir'}
                            </LoadingButton>
                        }
                    </Box>
                    <Box className='toolbarRight'>
                        <FormControlLabel 
                            control={<Switch checked={groupedByEnterprise} onClick={updateGroupedByEnterprise}/>} 
                            label="Agrupar por Empresa" 
                        />
                        <LoadingButton
                            component="label"
                            startIcon={<SettingsIcon />}
                            loading={false}
                            onClick={() => { 
                                localStorage.removeItem('crmClientGridColumns'); 
                                if (groupedByEnterprise)
                                    updateGroupedByEnterprise(); 
                                getClients(); 
                            }}
                            disabled={false}
                            size="small"
                        >
                            {isSmallScreen ? '' : 'Reset'}
                        </LoadingButton>
                    </Box>
                </GridToolbarContainer>
            )
        }

        //Treating mobile text
        const newlocaleText = theme.components.MuiDataGrid.defaultProps.localeText

        let mobileColumns = [];

        const handleColumnVisibilityChange = (updatedColumn) => {
            const columns = getColumns()
            columns.forEach((column) => {
                if (column.field === updatedColumn.field)
                    column.hide = !updatedColumn.isVisible
            })
            localStorage.setItem('crmClientGridColumns', JSON.stringify(columns));
        }

        const getPage = (limit, offset, direction) => {
            if (direction == 'next')
                return Math.round(offset / limit) - 1
            return Math.round(offset / limit) + 1

        }

        const getQueryset = (string, search, delimiter) => {
            const queryset = string.split(search)[1]
            if (queryset) {
                const result = queryset.split(delimiter)
                return result ? result[0] : ''
            }
            return ''
        }
        const getLimitOffset = (url) => {
            const queryset = url.split('?').pop()
            limit = getQueryset(queryset, 'limit=', '&')
            offset = getQueryset(queryset, 'offset=', '&')
            if (pageLimit)
                limit = pageLimit
            return [limit, offset]
        }

        const handlePageChange = (event) => {
            if (event > page) {
                getClients(...next)
                return
            }
            getClients(...previous)

        }

        const handlePageSizeChange = (newPage) => {
            updatePageLimit(newPage)
            getClients('auto', newPage, actual)
        }

        let count = 0
        let page = 0
        let next = ''
        let previous = ''
        let limit = 25
        let oldlimit = 0
        let offset = 0
        let actual = 0

        if (limitOffset.clients.length > 0) {
            limit = limitOffset.clients[0]
        }


        if (ServerData.clients) {
            count = ServerData.clients.count
            previous = ServerData.clients.previous
            next = ServerData.clients.next

            if (next) {
                const limitOffset = getLimitOffset(next)
                next = ['auto', ...limitOffset]
                page = getPage(...limitOffset, 'next')
                offset = limitOffset.pop()
                oldlimit = limitOffset.pop()
                actual = Number(offset) - Number(limit)
                updateLimitOffset(prevState => ({ ...prevState, clients: [oldlimit, actual] }))
            }

            if (previous) {
                const limitOffset = getLimitOffset(previous)
                previous = ['auto', ...limitOffset]
                if (!next) {
                    page = getPage(...limitOffset, 'previous')
                    offset = limitOffset.pop()
                    oldlimit = limitOffset.pop()
                    actual = Number(offset) - Number(limit)
                    updateLimitOffset(prevState => ({ ...prevState, clients: [oldlimit, actual] }))
                }

            }



        }

        const filterOnClick = (groupName, groupID) => {
            const newFilterModel = {
                items: [{
                    columnField: 'groups',
                    operatorValue: 'contains', value: String(groupName)
                }],
            }
            updateClientsFilter(newFilterModel)
            filterModel.clients = newFilterModel
            getClients('get', limit, offset - oldlimit, `&group_equal=${groupID}`)
        }

        const handleFilterChange = event => {
            getClients('filter', limit, actual, event)
            updateClientsFilter(event)
        }

        const handleSortChange = event => {
            getClients('sort', limit, actual, { items: [] }, event)
            updateClientsSort(event)
        }

        const changeNumberSelectionModel = newModel => {
            const addedNumbers = newModel.filter(each => !selectedClients.includes(each))
            const removedNumbers = selectedClients.filter(each => !newModel.includes(each))
            const added_phones = clients.filter(each => addedNumbers.includes(each.id)).map(each => each.telephone)
            const removed_phones = clients.filter(each => removedNumbers.includes(each.id)).map(each => each.telephone)
            let new_list = [...selectedNumberModel, ...added_phones]
            new_list = new_list.filter(each => !removed_phones.includes(each))
            updateNumberSelectedModel(new_list)
        }

        const updateGroupedByEnterprise = () => {
            if (! groupedByEnterprise)
                clients.forEach((client) => client.enterprise_name = [client.enterprise_name || '', client.id])
            else
                clients.forEach((client) => client.enterprise_name = (Array.isArray(client.enterprise_name)) ? [client.enterprise_name[0]] : [client.enterprise_name])
            setGroupedByEnterprise(! groupedByEnterprise)
        }

        const getTreeDataPath: DataGridProProps['getTreeDataPath'] = (row) => {
            if (Array.isArray(row.enterprise_name))
                return row.enterprise_name
            return [row.enterprise_name, Math.random()]
        }

        const groupingColDef: DataGridProProps['groupingColDef'] = {
            headerName: 'Empresa',
            renderCell: (params) => <CustomGridTreeDataGroupingCell {...params} />,
        };

        return (
            <Box sx={{ width: '100%', height: '100%' }}>
                <ThemeProvider theme={theme}>
                    <DataGridPro
                        apiRef={apiRef}
                        columns={getColumns(groupedByEnterprise)}
                        rows={clients}
                        checkboxSelection
                        disableSelectionOnClick
                        components={{ Toolbar: CustomToolbar }}
                        onSelectionModelChange={(newSelectionModel) => {
                            updateSelectedClients(newSelectionModel);
                            changeNumberSelectionModel(newSelectionModel)
                        }}
                        density={'compact'}
                        localeText={
                            isSmallScreen
                                ? { ...newlocaleText, toolbarFilters: '', toolbarColumns: '' }
                                : newlocaleText
                        }
                        onColumnVisibilityChange={handleColumnVisibilityChange}
                        pagination
                        paginationMode="server"
                        rowsPerPageOptions={[25, 50, 100]}
                        page={page}
                        onPageSizeChange={handlePageSizeChange}
                        onPageChange={handlePageChange}
                        rowCount={count}
                        keepNonExistentRowsSelected
                        initialState={{
                            pagination: {
                                pageSize: Number(limit)
                            }
                        }}
                        filterMode={groupedByEnterprise ? "client" : "server"}
                        onFilterModelChange={handleFilterChange}
                        filterModel={clientsFilter}
                        sortingMode={groupedByEnterprise ? "client" : "server"}
                        sortModel={clientsSort}
                        onSortModelChange={handleSortChange}
                        treeData={groupedByEnterprise}
                        getTreeDataPath={getTreeDataPath}
                        groupingColDef={groupingColDef}
                        key={`clients-table-key-${groupedByEnterprise}`}
                    />
                </ThemeProvider>
            </Box>
        )
    }, [clients, isSmallScreen, forceReload, pageLimit, columnVisibilityModel, clientsFilter, selectedClients, groupedByEnterprise])

    const clientDial = useMemo(() => {

        return (
            <>
                {
                    editClientDialog &&
                    <ClientDialog open={true}
                        title="Cliente"
                        type="change"
                        crm_integrated={settings.integration_source ? true : false}
                        is_financial_market_company={settings.is_financial_market_company}
                        model={selectedClientModel}
                        key={selectedClientModel.id}
                        buttonText="Salvar"
                        handleClose={() => { updateSelectedClientModel([]); updateEditClientDialog(false) }}
                        submit={changeClient}
                        isAdmin={permissions.isAdmin}
                        listOfUsers={usersEmails}
                        groups={groups}
                    />}
            </>
        )

    }, [editClientDialog])

    const deleteDial = useMemo(() => {
        return (
            <>
                {
                    settings.integration_source ?
                        <ConfirmationDialogCaptcha open={deleteClientDialog}
                            title="Confirmação"
                            description={
                                <span>
                                    Ao Concordar em excluir o usuário selecionado, o mesmo será removido do CRM {settings.integratino_source}, para confirmar a ação, digite: <span style={{ 'fontWeight': 'bold' }}>{selectedClientModel.name}</span> no campo abaixo.</span>}
                            handleClose={() => { updateSelectedClientModel([]); updateDeleteClientDialog(false) }}
                            handleSubmit={() => { deleteClient(selectedClientModel); updateSelectedClientModel([]); updateDeleteClientDialog(false) }}
                            captcha={selectedClientModel.name}
                        />
                        :
                        <ConfirmationDialog open={deleteClientDialog}
                            title="Confirmação"
                            description={`Deseja deletar o cliente ${selectedClientModel.name ? selectedClientModel.name : selectedClientModel.telephone}?`}
                            handleClose={() => { updateSelectedClientModel([]); updateDeleteClientDialog(false) }}
                            handleSubmit={() => { deleteClient(selectedClientModel); updateSelectedClientModel([]); updateDeleteClientDialog(false) }}
                        />
                }
            </>
        )

    }, [deleteClientDialog])

    const mergeSelectedClients = (client1, client2, finalClient) => {
        updateNumberSelectedModel([])
        updateReady(false)

        const payload = {}
        Object.keys(finalClient).forEach((key) => {
            if (['enterprise', 'created_at', 'updated_at', 'extra_fields'].includes(key))
                return
            if (key === 'groups') {
                payload[key] = [...new Set(finalClient[key].map((group) => group.id))]
                return
            }
            payload[key] = finalClient[key]
        })
        api.patch(`api/enterprises/${enterpriseId}/contacts/${finalClient.id}/`, payload)
            .then(async (res) => {
                updateReady(true)
                updateMergeClientDialog(false)
                if (res.status === 200) {
                    const toRemove = (client1.id === finalClient.id) ? client2 : client1
                    await api.delete(`api/enterprises/${enterpriseId}/contacts/${toRemove.id}/`)
                    await getClients()
                    useNotification(
                        'Sucesso!',
                        'Clientes mesclados com sucesso!',
                        'success'
                    );
                    return
                }
                throw res
            })
            .catch((err) => {
                useNotification(
                    'Ops!',
                    'Algo deu errado, tente novamente...',
                    'danger'
                );
            })
    }

    const mergeClient = useMemo(() => {
        return (
            <MergeClientDialog open={mergeClientDialog}
                clients={clients}
                selectedClients={selectedClients}
                handleClose={() => updateMergeClientDialog(false)}
                handleSubmit={mergeSelectedClients}
            />
        )
    }, [mergeClientDialog])

    const deleteAllDial = useMemo(() => {
        return (
            <ConfirmationDialog open={massDeleteClientDialog}
                title="Confirmação"
                description="Tem certeza que deseja excluir os clientes selecionados?"
                handleClose={() => { updateMassDeleteClientDialog(false) }}
                handleSubmit={() => { deleteClients(); updateMassDeleteClientDialog(false); updateSelectedClients([]) }}
            />
        )

    }, [massDeleteClientDialog])

    const addDial = useMemo(() => {
        return (
            <AddClientGroupForm
                selectionIds={selectedClientModel}
                enterpriseId={enterpriseId}
                open={showAddClientGroupForm}
                handleClose={() => { updateShowAddClientGroupForm(false); }}
                submit={submitClientGroupForm}
                updateClients={updateClients}
                updateGroups={updateGroups}
                subsObj={subsObj}
                clients={clients}
                updateForceReload={updateForceReload}
                updateReady={updateReady}
            />
        )

    }, [showAddClientGroupForm])

    const confirmGroupDial = useMemo(() => {
        return (
            <ConfirmationDialog open={deleteClientGroupModal}
                title="Confirmação"
                description="Tem certeza que deseja excluir o segmento selecionado?"
                handleClose={() => { updateDeleteClientGroupModal(false); }}
                handleSubmit={submitDeleteClientGroupModal}
            />
        )

    }, [deleteClientGroupModal])

    const editNameDial = useMemo(() => {
        return (
            <EditGroupNameModal
                open={editClientGroupModal}
                title="Editar Segmento"
                buttonText="Salvar"
                groups={groups}
                handleClose={(e) => { updateEditClientGroupModal(false); }}
                submit={submitEditModal}
                enterpriseId={enterpriseId}
                selectedGroup={selectedClientGroup}

            />
        )
    }, [editClientGroupModal])

    const editMembersDial = useMemo(() => {
        return (
            <EditGroupMembersModal
                open={editGroupMembersModal}
                title="Editar Segmento"
                buttonText="Salvar"
                groups={groups}
                handleClose={() => { updateEditGroupMembersModal(false); }}
                submit={() => { submitEditModal(); getClients() }}
                clients={clients}
                enterpriseId={enterpriseId}
                selectedGroup={selectedClientGroup}
                updateForceReload={updateForceReload}
            />
        )
    }, [editGroupMembersModal])

    const checkShowMergeDialog = () => {
        if (selectedClients.length !== 2) {
            useNotification(
                'Atenção!',
                'Selecione 2 clientes na tabela para mesclar!',
                'warning'
            );
            return
        }
        updateMergeClientDialog(true)
    }

    return (
        <>
            {table}
            {editClientDialog && clientDial}
            {deleteClientDialog && deleteDial}
            {massDeleteClientDialog && deleteAllDial}
            {mergeClientDialog && mergeClient}
            {showChats && <UserChats data={showChats} handleClose={() => setShowChats(null)} />}

            <Box onClick={e => e.stopPropagation()}>
                {showAddClientGroupForm && addDial}
                {deleteClientGroupModal && confirmGroupDial}
                {editClientGroupModal && editNameDial}
                {editGroupMembersModal && editMembersDial}
            </Box>
        </>
    )
}

export default ClientsTable