import React, { useEffect } from 'react';

import { styled } from '@mui/material/styles';

import { IconButton, Menu, CircularProgress } from '@mui/material';

import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

import PhoneIcon from '@mui/icons-material/Tty';

import Webphone from '../../Webphone';

import { getSession } from "../../../auth";

import "./index.css";

import io from 'socket.io-client';

const KwikTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
    [`& .${tooltipClasses.tooltip}`]: {
        fontSize: 15,
        padding: 10
    },
}));

const WebphoneMenu = () => {

    const user = getSession();

    const iconButton = React.useRef(null);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState(true);
    const [alreadyConnected, setAlreadyConnected] = React.useState(false);
    const [lastVoiceSocketResponse, setLastVoiceSocketResponse] = React.useState(null);

    const reAnchor = async () => {
        handleClose();
        await new Promise(r => setTimeout(r, 500));
        handleClick();
    }

    const handleClick = () => {
        setAnchorEl(iconButton.current);
        window.setLink = false;
    };

    const handleClose = () => {        
        setAnchorEl();
    };

    const handleLoading = (val) => {        
        setIsLoading(val);
    };

    let connectVoiceSocket = () => {
        let socket = null; 
        let keepAliveVoice = null;
        const options = { upgrade: false, transports: [ 'websocket' ] };        
        const url = `https://${location.host}:443`;

        if (!alreadyConnected) {
            socket = io(url, options);
            
            socket.on('connect', () => {
                socket.emit('subscribe', { agent: user.profile.agent.user });                
                keepAliveVoice = setInterval(() => {
                    // If server not responding for more than 11 seconds
                    // the connection might have been closed somewhere
                    // we need to close and reopen a new websocket connection
                    let curTime = new Date().getTime();
                    if (lastVoiceSocketResponse && curTime - lastVoiceSocketResponse > 11000) {                        
                        socket.disconnect();                        
                        socket.open();
                        window.socketVoice = socket;
                        return;
                    }

                    // Pinging server every 20secs
                    socket.emit('xping', { agent: user.profile.agent.user });
                }, 10000);
            });

            socket.on('disconnect', () => {
                console.log('O socket foi desconectado.');
                clearInterval(keepAliveVoice);
                
            });

            socket.on('xpong', (event) => {
                setLastVoiceSocketResponse(new Date().getTime());                
            });
            
            setAlreadyConnected(true);
        }

        if (socket) {
            socket.on('disconnect', () => {
                console.log('O socket foi desconectado.');
                window.socketVoice = null;
                setLastVoiceSocketResponse(null);
            });
        }

        window.socketVoice = socket;
    }

    let closeVoiceSocket = () => {
        if (window.socketVoice?.connected) {
            window.socketVoice.disconnect();
        }
        window.socketVoice = null;
    };

    useEffect(() => {
        if (!user?.profile.enterprise.has_voice || !user.profile.agent.preferred_interface || user.profile.agent.external_username) {
            return;
        }
        connectVoiceSocket();
        return () => {
            closeVoiceSocket();
        }
    }, []);

    if (user?.profile.enterprise.has_voice) {        
        const telephone = user.profile.enterprise.telephone;
        let extension = null;
        let info = null
        if (user.profile.agent.external_username) {
            extension = user.profile.agent.external_username;
            info = 'Ramal(Externo): ' + extension
        } else if (user.profile.agent.preferred_interface) {
            extension = user.profile.agent.preferred_interface;
            extension = extension.split('_').pop();
            info = 'Ramal(Interno): ' + extension
        }

        let tooltip = 'Ramal não configurado';
        if (extension) {
            tooltip = info;
            if (telephone)
                tooltip += ' [' + telephone.replace('55', '0') + ']';
        }

        return (
            <div className="webphone-menu-container">
                <KwikTooltip 
                    title={extension 
                        ? tooltip 
                        : 'Ramal não configurado'} 
                    placement="left"
                    disableInteractive
                    arrow
                >
                    <IconButton id="webphone-icon" 
                        ref={iconButton} 
                        disabled={extension && isLoading}
                        onClick={handleClick}>
                        {extension && isLoading ? (
                            <CircularProgress size={20} color="primary" />
                        ) : (
                            <PhoneIcon className="toolbar-phone-icon" />
                        )}
                    </IconButton>
                </KwikTooltip>
                <Menu
                    anchorEl={anchorEl}
                    keepMounted
                    sx={{zIndex:2001}}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    elevation={0}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                    className="webphone-container"
                >
                    <Webphone 
                        reAnchor={() => reAnchor()}
                        handleClose={() => handleClose()}
                        handleLoading={() => handleLoading()}
                    />
                </Menu>
            </div>
        );
    } else {
        return (
            <></>
        );
    }
}

export default WebphoneMenu;
