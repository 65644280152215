import React from "react";

import { Box, IconButton, Tooltip } from "@mui/material";

import AttachmentMenu from "../../../../AttachmentMenu";
import AudioRecorder from "../../../../AudioRecorder";
import EmojiTextarea from "../../../../Input/EmojiTextarea";

import SmsIcon from '@mui/icons-material/Sms';
import SendIcon from '@mui/icons-material/Send';

import InputEmoji from 'react-input-emoji';
import "./styles.css";

class Footer extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            text: ""
        }
    }

    onKeyDownMessage = (event) => {
        // key shift off and enter on
        if (!event.shiftKey && (event.keyCode === 13 || event.key === 'Enter')) {
            this.sendMessage();
            event.preventDefault();
        }
    }

    sendMessage = () => {
        const text = this.state.text;

        this.props.sendMessage(text);
        this.setState({ text: "" });
    }

    setText = (text) => {
        this.setState({ text: text });
    }

    componentDidMount = () => {
        window.copyMessageBack = (message) => {
            this.setState({ text: message.text })
        }
        window.sendMessageBack = (message) => {
            this.props.sendMessage(message.text)
        }
    }

    render() {
        return (
            <Box id="footer">
                {this.props.activeChat.chatstatus && ! this.props.hideAttachments &&
                    <AttachmentMenu setFile={this.props.setFile} activeChat={this.props.activeChat}/>
                }
                {! this.props.hidePredefinedMessages &&
                    <Tooltip title="Mensagens predefinidas" placement="top" disableInteractive>
                        <IconButton size="large">
                            <SmsIcon color="primary"
                                onClick={() => this.props.showPredefinedMessages()} />
                        </IconButton>
                    </Tooltip>
                }
                <EmojiTextarea
                    text={this.state.text}
                    setText={(event) => this.setState({ text: event.target.value })}
                    setEmoji={(text) => this.setState({ text: text })}
                    onKeyDown={this.onKeyDownMessage}
                    placeholder={window.innerWidth > 960 && "Digite uma mensagem"}
                    childComponent="Chat"
                />
                {! this.state.text && ! this.props.hideAudio && this.props.activeChat.chatstatus &&
                    <AudioRecorder activeChat={this.props.activeChat}
                        isActiveChatOnQueue={this.props.isActiveChatOnQueue}
                        connectActiveChat={this.props.connectActiveChat}
                        chat={this.props.activeChat}
                        addMessage={this.props.addMessage}
                        connections={this.props.connections}
                        scrollDown={this.props.scrollDown}
                    />
                }
                {this.state.text &&
                    <IconButton onClick={() => this.sendMessage()} size="large">
                        <SendIcon color="primary" />
                    </IconButton>
                }
            </Box>
        );
    }
}

export default Footer;
