import React, { useEffect, useRef, useState } from 'react'
import { Box, Button, Input } from '@mui/material'

import useNotification from '../../../../../Notification';

import DeleteIcon from '@mui/icons-material/Delete';

const ButtonBlock = ({ parent, updateParent, rerender, display, editable }) => {
    const linkButton = useRef()
    const phoneInput = useRef()
    const [haveVariable, setHaveVariable] = useState(false)

    const [firstText, setFirstText] = useState('')
    const [secondText, setSecondText] = useState('')

    useEffect(() => {
        if (!display)
            return
        if (parent.type == 'URL') {
            if (parent.text)
                setFirstText(parent.text)
            if (parent.url)
                setSecondText(parent.url)
        }
        else if (parent.type == 'PHONE_NUMBER') {
            if (parent.phone_number)
                setSecondText(parent.phone_number)
            if (parent.text)
                setFirstText(parent.text)
        }
        else if (parent.type == 'QUICK_REPLY') {
            if (parent.text)
                setFirstText(parent.text)
        }
    }, [])

    const type = parent.type
    const headerName = () => {
        switch (type) {
            case 'URL':
                return 'Link'
            case 'PHONE_NUMBER':
                return 'Telefone'
            case 'QUICK_REPLY':
                return 'Resposta rápida'
            default:
                return ''
        }
    }
    const handleVariable = () => {
        const memoryOfValue = linkButton.current.lastChild
        if (memoryOfValue.value.includes('{{1}}'))
            return
        memoryOfValue.value += '{{1}}'
        setHaveVariable(true)
        parent.url = memoryOfValue.value
        parent.example = [parent.url.replace('{{1}}', 'dynamic-url-example')]
        setSecondText(parent.url)
        rerender()
    }
    const handleLinkChange = (e) => {
        const memoryOfValue = linkButton.current.lastChild
        parent.url = e.target.value
        setHaveVariable(false)
        if (memoryOfValue.value.includes('{{1}}')) {
            setHaveVariable(true)
            const splitted = memoryOfValue.value.split('{{1}}')
            memoryOfValue.value = splitted[0] + '{{1}}'
            parent.url = memoryOfValue.value
            parent.example = [parent.url.replace('{{1}}', 'dynamic-url-example')]
            if (splitted[1])
                useNotification(
                    'Falha!',
                    'Não é permitido adicionar texto após a variável',
                    'danger'
                );
        }
        setSecondText(parent.url)
        rerender()
    }
    const handleTextChange = (e) => {
        parent.text = e.target.value
        setFirstText(parent.text)
        rerender()
    }
    const handlePhoneChange = (e) => {
        const memoryOfValue = phoneInput.current.lastChild
        memoryOfValue.value = memoryOfValue.value.replace('+55', '')
        memoryOfValue.value = memoryOfValue.value.replace(/\D/g, '')
        memoryOfValue.value = '+55' + memoryOfValue.value
        memoryOfValue.value = memoryOfValue.value.slice(0, 20)
        parent.phone_number = memoryOfValue.value
        setSecondText(parent.phone_number)
        rerender()
    }

    const buildTexts = () => {
        switch (type) {
            case 'URL':
                return (
                    <>
                        <Input
                            inputProps={{ maxLength: 25 }}
                            onChange={handleTextChange}
                            type='text'
                            placeholder='Nome do botão'
                            value={firstText}
                            readOnly={!editable}
                        />
                        <Box className={'templates-footer flexedGap'}>
                          <Box className="character-counter positionUnset" >
                            {`${firstText.length} / 25 caracteres`}
                          </Box>
                        </Box>
                        <Input
                            inputProps={{ maxLength: 500 }}
                            onChange={handleLinkChange}
                            type='text'
                            placeholder='Link'
                            ref={linkButton}
                            value={secondText}
                            readOnly={!editable}
                        />
                        {!haveVariable && editable &&
                            <Button onClick={handleVariable}>
                                Adicionar variável
                            </Button>}
                    </>
                )
            case 'PHONE_NUMBER':
                return (
                    <>
                        <Input
                            inputProps={{ maxLength: 25 }}
                            onChange={handleTextChange}
                            type='text'
                            placeholder='Nome do botão'
                            value={firstText}
                            readOnly={!editable}
                        />
                        <Box className={'templates-footer flexedGap'}>
                          <Box className="character-counter positionUnset" >
                            {`${firstText.length} / 25 caracteres`}
                          </Box>
                        </Box>
                        <Input
                            inputProps={{ maxLength: 20 }}
                            onChange={handlePhoneChange}
                            type='text'
                            placeholder='Número de telefone'
                            ref={phoneInput}
                            value={secondText}
                            readOnly={!editable}
                        />
                    </>
                )
            case 'QUICK_REPLY':
                return (
                    <Input
                        value={firstText}
                        inputProps={{ maxLength: 255 }}
                        onChange={handleTextChange}
                        type='text'
                        placeholder='Texto da resposta' 
                        readOnly={!editable}/>
                )
            default:
                return ''
        }
    }


    const handleDeleteButton = () => {
        updateParent(prevState => prevState.filter(button => button != parent))
    }


    return (
        <>
            {!editable && <Box>Botões</Box>}
            <Box className={'button-box-new-template'} >
                <Box sx={{ display: 'flex', 'justifyContent': 'space-between' }}>
                    <span>
                        {headerName()}
                    </span>
                    <span>
                        {editable &&
                            <DeleteIcon
                                className={'delete-icon'}
                                sx={{ cursor: 'pointer' }}
                                onClick={handleDeleteButton} />
                        }
                    </span>
                </Box>
                {
                    buildTexts()
                }

            </Box>
        </>
    )
}

export default ButtonBlock