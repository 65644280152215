import React from 'react'

import {Box, Button} from '@mui/material'

import {SPECIAL_REPORT_NAME} from './detailed_register_main'

const evaluateDisabled = (key, parent) => {
    if (key === 'graphs' && parent.selected_report_name?.name !== SPECIAL_REPORT_NAME)
        return true
    return false
}

const DetailedRegisterRightMenu = ({parent, setParent, options}) => {

    return (
        <Box className="report-header-right">

            {Object.entries(options).map(([key, value]) => 
                <Box>
                    <Button className={key === parent.selectedTab ? "btn-switch active" : "btn-switch inactive"}
                                        onClick={() => setParent({selectedTab:key})}
                                        variant={"contained"}
                                        endIcon={value.icon}
                                        disabled={evaluateDisabled(key, parent)}
                                    >
                                        {value.name}
                    </Button>
                </Box>)}
                            
        </Box>
    )
}

export default DetailedRegisterRightMenu