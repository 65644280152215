import React, { useReducer, useEffect } from 'react'
import ReportGraphs from "../../ReportGraphs";
import { lockedReducer } from '../../../utils/defaultReducer'
import { get_report_data } from './detailed_register_functions'

const activeGraphics = {
    serviceQueues: { title: 'Atendimentos (filas)', active: true },
    serviceStatus: { title: 'Atendimentos (status)', active: true },
    npsSurvey: { title: 'Pesquisa NPS', active: true },
    csatSurvey: { title: 'Pesquisa CSAT', active: true }
};

let graphInstance = null

const GraphView = ({ parent, setParent }) => {

    const [state, setState] = useReducer(lockedReducer, {
        graphRows: [],
        filteredGraphRows: [],
        instance: null,
        filters: [],
        dataColumns: [],
        activeGraphics: activeGraphics,
        ready: false
    })

    const initialize = async () => {
        setParent({ loading: true })
        const data = {
            ...parent,
            selected_report_name: parent.selected_report_name,
            parameters: { ...parent.parameters, nopagination: true }
        }
        const res = await get_report_data(data)
        if ('results' in res)
            setState({ graphRows: res.results, filteredGraphRows: res.results, ready: true })

        setParent({ loading: false })
    }

    useEffect(() => {
        initialize()
    }, [])

    const data = state.filteredGraphRows.length !== 0 ? state.filteredGraphRows : state.graphRows

    return (
        <>
            {state.ready &&
                <ReportGraphs
                    ref={instance => { graphInstance = instance; }}
                    startDate={`${parent.parameters.date__gte}T${parent.parameters.time__gte}`}
                    endDate={`${parent.parameters.date__gte}T${parent.parameters.time__gte}`}
                    data={data}
                    filters={state.filters}
                    columns={state.dataColumns}
                    activeGraphics={state.activeGraphics}
                />}
        </>
    )
}

export default GraphView