import React, { useState, useEffect } from "react";

import {
    Box, IconButton, Tooltip, Accordion, AccordionSummary, AccordionDetails
    , Divider
} from "@mui/material";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import Profile from './Profile';
import LabelArea from './LabelArea';
import GroupArea from './GroupArea';

import "./styles.css";
import { getSession } from "../../../auth";
import History from "./History";
import NewHistory from "./History/newhistory";

export default function ChatRightSideBar({
    closeChatRightSideBar,
    isManageCalls,
    contactData,
    updateField,
    fetchHistoryMessages,
    agentCanUpdateContact,
    allLabels,
    chatLabels,
    setChatLabels,
    chat,
    renderLabels,
    reloadContact,
    chatCursor,
    allGroups,
    setChatGroups
}) {

    const enterprise = getSession().profile.enterprise;

    return (
        <Box
            className="chat-right-sidebar"
            style={{ height: isManageCalls ? '100%' : '100%' }}
        >
            <Box className="header">
                <Tooltip title="Fechar" placement="bottom" disableInteractive>
                    <IconButton
                        onClick={() => closeChatRightSideBar()}
                        className="contactHistory-iconButton"
                        size="large">
                        <ChevronRightIcon />
                    </IconButton>
                </Tooltip>
            </Box>
            <Divider />
            <Accordion defaultExpanded={true} className='accordion'>
                <AccordionSummary className='accordionHeader'
                    expandIcon={<ExpandMoreIcon />}
                >
                    Dados do contato
                </AccordionSummary>
                <Divider />
                <AccordionDetails>
                    <Profile
                        contactData={contactData}
                        agentCanUpdateContact={agentCanUpdateContact}
                        isManageCalls={isManageCalls}
                        updateField={updateField}
                        fetchHistoryMessages={fetchHistoryMessages}
                        chat={chat}
                        reloadContact={reloadContact}
                    />
                </AccordionDetails>
            </Accordion>
            <Divider />
            <Accordion defaultExpanded={false} className='accordion'>
                <AccordionSummary className='accordionHeader'
                    expandIcon={<ExpandMoreIcon />}
                >
                    Histórico
                </AccordionSummary>
                <Divider />
                <AccordionDetails>
                    {enterprise.infinite_scroll ? <NewHistory
                        contactData={contactData}
                        fetchHistoryMessages={fetchHistoryMessages}
                    />
                        :
                        <History
                            contactData={contactData}
                            fetchHistoryMessages={fetchHistoryMessages}
                        />
                    }
                </AccordionDetails>
            </Accordion>
            {enterprise.show_chat_groups && 
                <>
                    <Divider />
                    <Accordion defaultExpanded={false} className='accordion'>
                        <AccordionSummary className='accordionHeader' expandIcon={<ExpandMoreIcon />}>
                            Segmentos
                        </AccordionSummary>
                        <Divider />
                        <AccordionDetails>
                            <AccordionDetails>
                                <Box alignItems={'center'}>
                                    <GroupArea
                                        chat={chat}
                                        chatGroups={chat.contact?.groups ?? []}
                                        allGroups={allGroups}
                                        setChatGroups={setChatGroups}
                                    />
                                </Box>
                            </AccordionDetails>
                        </AccordionDetails>
                    </Accordion>
                </>
            }
            {renderLabels &&
                <>
                    <Divider />
                    <Accordion defaultExpanded={false} className='accordion'>
                        <AccordionSummary className='accordionHeader'
                            expandIcon={<ExpandMoreIcon />}
                        >
                            Etiquetas
                        </AccordionSummary>
                        <Divider />
                        <AccordionDetails >
                            <Box
                                alignItems={'center'}
                            >
                                <LabelArea
                                    chat={chat}
                                    chatLabels={chatLabels}
                                    allLabels={allLabels}
                                    setChatLabels={setChatLabels}
                                />
                            </Box>
                        </AccordionDetails>
                    </Accordion>
                </>
            }
        </Box>
    );
}
