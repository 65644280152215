
import { getSession } from "../../../auth";
import api from '../../../api'
import useNotification from '../../Notification';
import {SPECIAL_REPORT_NAME, SPECIAL_NOTIFICATION_REPORT_NAME} from './detailed_register_main'
import {handleReportRows, buildContactColumns} from './contact_graph_exception'

import {
    getGridBooleanOperators,
    getGridDateOperators,
    getGridSingleSelectOperators,
    getGridStringOperators,
    getGridNumericOperators
} from '@mui/x-data-grid-pro'

import * as moment from "moment";

import { getNotificationColumns } from "../../Crm/TabTables/NotificationTable";
import { showTemplateFunc } from "../../Crm/TabTables/NotificationTable";

const user = getSession()
const enterpriseId = user?.profile.enterprise.id

export const notificationError = {
    '400': '[400] Erro de download de mídia',
    '402': '[402] Qualificação da empresa — Problema de pagamento',
    '408': '[408] Mensagem inválida',
    '410': '[410] Mensagem expirou',
    '429': '[429] Limite de envio atingido',
    '430': '[430] Certificado não assinado',
    '432': '[432] Incompatibilidade de ID do certificado',
    '433': '[433] Assinatura do certificado inválida',
    '470': '[470] Mensagem de reengajamento',
    '471': '[471] Limite de envio de spam atingido',
    '472': '[472] Número do usuário faz parte de um experimento',
    '480': '[480] Usuário potencialmente alterado',
    '500': '[500] Erro genérico',
    '1000': '[1000] Erro genérico',
    '1001': '[1001] Mensagem muito longa',
    '1002': '[1002] Tipo de destinatário inválido',
    '1004': '[1004] Recurso já existe',
    '1005': '[1005] Acesso negado',
    '1006': '[1006] Recurso não encontrado',
    '1008': '[1008] Parâmetro obrigatório ausente',
    '1009': '[1009] Parâmetro inválido',
    '1010': '[1010] Parâmetro não é obrigatório',
    '1011': '[1011] Serviço não está pronto',
    '1013': '[1013] Destinatário não possui WhatsApp',
    '1014': '[1014] ',
    '1015': '[1015] Limite de envio atingido',
    '1016': '[1016] Sistema sobrecarregado',
    '1017': '[1017] Conta não está configurado corretamente',
    '1018': '[1018] Não é o aplicativo principal principal',
    '1021': '[1021] Usuário inválido',
    '1022': '[1022] URL dos webhooks não está configurado',
    '1023': '[1023] Ocorreu um erro de banco de dados',
    '1024': '[1024] Você é obrigado a alterar sua senha',
    '1025': '[1025] Requisição inválida',
    '1026': '[1026] Destinatário não pode receber a mensagem',
    '1028': '[1028] Uma notificação do sistema requer confirmação',
    '1031': '[1031] Remetente foi bloqueado',
    '2000': '[2000] Incompatibilidade de contagem de parâmetros',
    '2001': '[2001] Template não existe pro idioma específico.',
    '2002': '[2002] Receptor falhou ao baixar o modelo',
    '2003': '[2003] Não existem modelos pro idioma específico',
    '2004': '[2004] Comprimento do parâmetro muito longo',
    '2005': '[2005] Texto traduzido muito longo',
    '2006': '[2006] Política de espaço em branco violada',
    '2007': '[2007] Política de caracteres de formato violada',
    '2008': '[2008] Formato de mídia usado não é compatível',
    '2009': '[2009] Componente obrigatório no modelo está ausente',
    '2010': '[2010] URL do botão é inválido',
    '2011': '[2011] Número de telefone do botão é inválido',
    '2012': '[2012] Formato do parâmetro não corresponde ao template',
    '2013': '[2013] Os botões não são suportados pelo destinatário',
    '2014': '[2014] Namespace esperado está vazio',
    '2015': '[2015] Número inválido de seções',
    '2016': '[2016] Número inválido de linhas',
    '2017': '[2017] Política de personagem violada',
    '2023': '[2023] Número de produto inválido',
    '2024': '[2024] ID do catálogo não encontrado',
    '2025': '[2025] ID do catálogo não vinculado ao número da API',
    '2026': '[2026] Produtos ausentes',
    '2027': '[2027] Nenhum produto encontrado',
    '131026': '[131026] Destinatário não pode receber a mensagem',
    '131042': '[131042] A mensagem não foi enviada porque houve um ou mais erros relacionados à sua forma de pagamento',
    '131053': '[131053] Erro no upload da mídia',
    '131008': '[131008] Um parâmetro obrigatório está faltando',
}

const handleNotificationErrorMessage = (message, platform) => {
    /** 
     * Handle WhatsApp API errors
     * https://developers.facebook.com/docs/whatsapp/on-premises/errors 
     */

    if (! message) 
        return

    try {
        if (platform === 'SMS') {
            const parsedMessage = JSON.parse(message);
            return `Status: ${parsedMessage.status}, mensagem: ${parsedMessage.message}, mais informações: ${parsedMessage.more_info}`
        }

        let code
        const parsedMessage = JSON.parse(message.replace(/'/g, '"'))
        if ('errors' in parsedMessage)
            code = String(parsedMessage.errors[0].code)
        if ('error' in parsedMessage)
            code = String(parsedMessage.error.code)
        return notificationError[code]
    } catch (err) {
        return
    }
}

export const get_report_names = async () => {

    const res = await api.get(`/api/enterprises/${enterpriseId}/reports/builder/?kwik=1&action=get_report_names`, {timeout: 10000})
    if (res.status === 200){

        return res.data
    }
    
    return []
}

export const get_report_values = async (report_name) => {
    if (!report_name?.id) return []

    const res = await api.get(`/api/enterprises/${enterpriseId}/reports/builder/?action=get_report_values&report_id=${report_name.id}`, {timeout: 10000})
    if (res.status === 200){

        return res.data
    }
    return []
}

export const get_report_data = async (data) => {
    const payload = {
        report_id: data.selected_report_name?.id,
        parameters: {...data.parameters}
    }
    addFilters(data, payload)
    addSort(data, payload)

    payload.reportData = data.reportMode

    const res = await api.post(`/api/enterprises/${enterpriseId}/reports/data/data/?limit=${data.limit}&offset=${data.offset}`, payload, {timeout: 30000})
    if (res.status === 200){
        if (data.selected_report_name?.name === SPECIAL_REPORT_NAME){
            const result = handleReportRows(res.data.results)
            res.data.results = result
        }
        if (res.data[0]?.id == "360error"){
            useNotification(
                'Atenção!',
                'Erro ao processar requisição, por favor tente novamente mais tarde.',
                'warning'
            );
            return []
        }
        
        return res.data
    }

    return []
}

const addFilters = (data, payload) => {
    data.filterModel?.items.map(each => {
        if (payload.parameters[`${each.columnField}__${each.operatorValue}`]?.length > 0)
            payload.parameters[`${each.columnField}__${each.operatorValue}`].push(each.value)
        else
            payload.parameters[`${each.columnField}__${each.operatorValue}`] = [each.value]
    })
    if (data.filterModel?.linkOperator === 'or')
        payload.parameters.or = true
}

const addSort = (data, payload) => {
    payload.parameters.sort = data.sortModel?.map(each => `${each.field}__${each.sort}`)
    payload.parameters.sort?.push('leg__desc')
}

export const generate_scheduled_report = async (state, data, type) => {
    const payload = {
        report_id: state.selected_report_name.id,
        name: 'relatorio-agendado',
        type: 'SCHED_EMAIL',
        destinations: data.recipients,
        period: data.period,
        frequency: data.period,
        frequency_info: data.period === 'D' ? 1 : data.period === 'W' ? Number(data.selectedWeekDay) : Number(data.selectedDay),
        is_xlsx: type === 'csv' ? false : true,
        time: data.hour,
        active: true,
        parameters: {...state.parameters}
    }
    addFilters(state, payload)
    addSort(state, payload)
    
    // chat report should inform backend whether messages column is visible
    if (state.report_values.name === 'Contatos')
        payload['have_messages'] = state.visibilityModel.messages

    const res = await api.post(`/api/enterprises/${enterpriseId}/reports/data/data/`, payload, {timeout: 10000})
    if (res.status === 200){
        return res.status    
    }else{
        return res.status
    }
}

export const initialize_date_parameters = (data_type) => {
    const parameters = {}
    switch (data_type){
        case 'interval':
            const [date__gte, time__gte] = moment().set({ hour: 8, minute: 0 }).format("YYYY-MM-DDTHH:mm").toLocaleString().split('T');
            const [date__lte, time__lte] = moment().set({ hour: 18, minute: 0 }).format("YYYY-MM-DDTHH:mm").toLocaleString().split('T');
            parameters.date__gte = date__gte
            parameters.time__gte = time__gte
            parameters.date__lte = date__lte
            parameters.time__lte = time__lte
        break;
        case 'month-year':
            parameters.month = (new Date()).getMonth() + 1
            parameters.year = (new Date()).getFullYear()
        break;

        default:
            break
    } 
    return parameters
}

export const filterOperators = getGridStringOperators().filter(
    (operator) => ['contains', 'equals'].includes(operator.value)
)
export const dateOperators = getGridDateOperators().filter(
    (operator) => ['after', 'before'].includes(operator.value)
)
export const booleanOperators = getGridBooleanOperators()
export const choiceOperators = getGridSingleSelectOperators().filter(
    (operator) => ['is', 'not'].includes(operator.value)
)
export const numericOperators = getGridNumericOperators().filter(
    (operator) => ['=', '>', '>=', '<', '<='].includes(operator.value)
)

const selectFilter = {
    'text': filterOperators,
    'date': dateOperators,
    'number': numericOperators
}

export const buildColumns = (data, setParent) => {
    const columns = []
    const visibilityModel = {}

    if (data.name === SPECIAL_REPORT_NAME){
        Object.assign(columns, buildContactColumns(data, setParent))  
    }
    // else if (data.name === SPECIAL_NOTIFICATION_REPORT_NAME){
    //     Object.assign(columns, getNotificationColumns(false, false))
    // }

    data?.fields?.map((row, index)=> {
        if ([SPECIAL_REPORT_NAME].includes(data.name)){
            ;
        }
        else{
            const filter = data?.filters?.find(each => each.col_name === row.col_name)?.data_type
            const selectedFilter = filter in selectFilter ? selectFilter[filter] : filterOperators 
            if (data.name === 'Notificações' && row.col_name === 'error_msg') {
                columns.push({
                    field           : row.col_name,
                    headerName      : row.name,
                    flex            : 1,
                    filterOperators : selectedFilter,
                    renderCell      : (params) => {
                        const status = params.row.status
                        const value  = params.row.comments
                        if (value && ['Falha', 'Não Enviada'].includes(status)) 
                            return handleNotificationErrorMessage(value, params.row.platform)
                        if (! params.value && status == 'Não Enviada')
                            return 'O cliente estava em atendimento'
                        return ''
                    }
                })   
            } else {
                columns.push({
                    field           : row.col_name,
                    headerName      : row.name,
                    flex            : 1,
                    filterOperators : selectedFilter
                })   
            }
        }
        visibilityModel[row.col_name] = row.visibility === 'visible' ? true : false
    }
    )

    return {
            columns: columns,
            visibilityModel: visibilityModel,
        }
}

export const authenticateFile = async (files) => {

    const formdata = new FormData()
    formdata.append('file', files[0])

    const res = await api.post(`/api/enterprises/${enterpriseId}/reports/chat/detailed/authenticate/`, formdata)

    if (files.length == 0)
        return

    if (res.status !== 200) {
        useNotification(
            'Atenção!',
            'O arquivo não é autêntico.',
            'danger'
        );
        files[0].authentic = false
        return {files:files, authSeal: {}};
    }

    files[0].authentic = true
    return {files:files, authSeal: getAuthenticitySeal(res.data, files[0].name)}
}

const getAuthenticitySeal = (data, filename) => {
    const createdDate = new Date(data['date'] + ' ' + data['time'])
    const currentDate = new Date()

    return {
        enterprise: user.profile.enterprise.name,
        fileName: filename,
        createdAt: createdDate.toLocaleString('pt-BR'),
        validatedAt: currentDate.toLocaleString('pt-BR'),
        result: 'Autêntico',
        user: user.first_name + ' ' +user.last_name,
        hash: data['content_hash']
    }
}

export const getTemplates = async (parentTemplates) => {
    if (enterpriseId === 0) return []
    if (parentTemplates.length > 0) return parentTemplates

    let templates

    await api.get(`/api/enterprises/${enterpriseId}/templates/`, { timeout: 0 })
        .then((res) => {
            if (res.status == 200) {
                templates = res.data.waba_templates.filter((template) => {
                    return (template.status == 'approved' &&
                        template.language == 'pt_BR' &&
                        !template.name.includes('sample_'));
                });
                return;
            }
            throw res;
        })
        .catch((err) => {
            console.error(err);
        });

    return templates
}

export const getQueues = () => {
    const queues = {}
    user.profile.queues.forEach((each) => {
        queues[each.description] = each.name
    })
    return queues
}

export const getNotifyNumbers = () => {
    return user.profile.enterprise.channels.map(obj => obj.phone_number)
}

export const formatPhoneNumber = (string) => {
    if (string.length < 11)
        return string
    const regex = /^([0-9]{2})([0-9]{4,5})([0-9]{4})$/;
    let str = string.replace(/[^0-9]/g, "")
    if (str.slice(0,2) === '55')
        str = str.slice(2,13)

    return str.replace(regex, "($1) $2-$3");
}

export const fetchHistoryMessages = async (uniqueid) => {

    const res = await api.get(`/api/LHC/chats/${uniqueid}/`, { timeout: 0 })
        if (res.status === 200){
            return res.data
        }
        return null
}

export const distinctArray = (arr, argument='id') => {

    return arr?.filter((value, index, self) => {
        return self.findIndex(obj => obj[argument] === value[argument]) === index;
    })
}

export const fetchTags =async (uniqueid) => {
    const res = await api.get(`/api/enterprises/${enterpriseId}/buildertags/${uniqueid}/`)
    if (res.status === 200)
        return res.data
    return res.status
}