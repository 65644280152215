import React, { useState, useEffect, useContext } from 'react';

import clsx from 'clsx';
import { useTheme, Box, Tooltip, Badge, Alert } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Drawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import List from '@mui/material/List';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { CircularProgress } from "@mui/material";

import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ReportIcon from '@mui/icons-material/Assessment';
import LogoutIcon from '@mui/icons-material/ExitToApp';
import TemplateIcon from '@mui/icons-material/NotificationAdd';
import SettingsIcon from '@mui/icons-material/Settings';
import ManageCallsIcon from '@mui/icons-material/ManageAccounts';
import ClientsIcon from '@mui/icons-material/Groups';
import ConstructionIcon from '@mui/icons-material/Construction';
import CellTowerIcon from '@mui/icons-material/CellTower';
import CampaignIcon from '@mui/icons-material/Campaign';
import SmartToyIcon from '@mui/icons-material/SmartToy';

import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { getSession, clearToken, clearSession, getToken, saveToken } from "../../auth";

import AccountMenu from '../Menu/AccountMenu';
import LogoImage from '../../assets/images/kwik_color.png';
import LogoFavIcon from '../../assets/images/kwik_favicon.png';

import ConfirmationDialog from '../Dialogs/Confirmation';

import userPermissions from '../User/Permissions';

import useNotification from "../Notification";
import { ReactNotifications } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import 'animate.css/animate.min.css';

import api from '../../api'

import "./styles.css";

import { QueueSocket } from '../../routes';

// const MiniDrawer = ({ child, socket }) => {
const MiniDrawer = ({ child }) => {


    const { chatState, notifications, setNotifications, connections } = useContext(QueueSocket)

    const theme = useTheme();

    const [open, setOpen] = useState(false);
    const [confirmRequeue, setConfirmRequeue] = useState(false);
    const [isLogoutButtonLoading, setIsLogoutButtonLoading] = useState(false);
    const [isRequeueLoading, setIsRequeueLoading] = useState(false);
    const [route, setRoute] = useState(0);
    const [showLogoutConfirmation, setShowLogoutConfirmation] = useState(false);

    const history = useHistory();
    const user = getSession();
    const groups = userPermissions();
    const platforms = user.profile.enterprise.platforms
    const settings = user.profile.enterprise.settings

    const selectedPage = (pagename) => {
        if (window.location.pathname === pagename)
            return { sx: {color:'#21446C'}}
    }

    const pages = [{
        name: "dashboard",
        description: "Dashboard",
        tooltip: "Dashboard",
        onClick: () => navigate('/dashboard'),
        icon: <DashboardIcon {...selectedPage('/dashboard')}/>,
        isVisible: groups.isSupervisor
    }, {
        name: "chat",
        description: "Atendimento",
        tooltip: "Atendimento",
        onClick: () => { navigate('/chat'); setNotifications(false) },
        icon:
            <Badge color="success" variant="dot" invisible={!notifications || !settings.websockets}>
                <QuestionAnswerIcon {...selectedPage('/chat')}/>
            </Badge>,
        isVisible: groups.isOperator
    }, {
        name: "reports",
        description: "Relatórios",
        tooltip: "Relatórios",
        onClick: () => navigate('/report'),
        icon: <ReportIcon {...selectedPage('/report')}/>,
        isVisible: groups.isSupervisor
    }, {
        name: "template",
        description: "Templates",
        tooltip: "Templates",
        onClick: () => navigate('/template'),
        icon: <TemplateIcon {...selectedPage('/template')}/>,
        isVisible: groups.isSupervisor
    },
    {
        name: "crm",
        description: "CRM",
        tooltip: "CRM",
        onClick: () => navigate('/crm'),
        icon: <CampaignIcon {...selectedPage('/crm')}/>,
        isVisible: groups.isOperator
    },
    {
        name: "manage-calls",
        description: "Supervisão",
        tooltip: "Supervisão de atendimentos",
        onClick: () => { navigate('/manageCalls'); setNotifications(false) },
        icon: <ManageCallsIcon {...selectedPage('/manageCalls')}/>,
        isVisible: groups.isSupervisor
    },
    {
        name: "channels",
        description: "Canais",
        tooltip: "Canais de atendimento",
        onClick: () => navigate('/channels'),
        icon: <CellTowerIcon {...selectedPage('/channels')}/>,
        isVisible: groups.isAdmin && Object.keys(platforms).some(k => k != 'chat_web' && platforms[k] === true)
    },
    {
        name: "builder",
        description: "Construtor",
        tooltip: "Construtor de ChatBot",
        onClick: () => navigate('/builder'),
        icon: <ConstructionIcon {...selectedPage('/builder')}/>,
        isVisible: groups.isAdmin && settings.bot
    },
    {
        name: "automation",
        description: "Automação",
        tooltip: "Automações",
        onClick: () => navigate('/automation'),
        icon: <SmartToyIcon {...selectedPage('/automation')}/>,
        isVisible: groups.isAdmin && user.profile.enterprise.automation
    },
    {
        name: "settings",
        description: "Configurações",
        tooltip: "Configurações",
        onClick: () => navigate('/settings'),
        icon: <SettingsIcon {...selectedPage('/settings')}/>,
        isVisible: groups.isSupervisor
    }];

    const mobilePages = [
        {
            name: "chat",
            description: "Chat",
            onClick: () => navigate('/chat'),
            icon: <QuestionAnswerIcon />,
            isVisible: groups.isOperator
        },
        {
            name: "crm",
            description: "Clientes",
            onClick: () => navigate('/crm'),
            icon: <ClientsIcon />,
            isVisible: groups.isOperator
        },
        {
            name: "logout",
            description: "Logout",
            onClick: () => setShowLogoutConfirmation(true),
            icon: <LogoutIcon />,
            isVisible: groups.isOperator
        },
    ];
    const navigate = (url) => {
        history.push(url);
    };

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const drawerWidth = 200;

    //I did not found the exposed path to kwikchat, so i routed the command to server and handled there.
    //We don't even need to wait.
    const addNewChat = async (queueName, channel) => {
        const url = `api/enterprises/${user.profile.enterprise.id}/calls/chat/transfer/${queueName}/${channel}/`
        api.get(url)
    }

    const requeueAgentChats = () => {
        setIsRequeueLoading(true);
        api.post(`/api/IV/agents/${user.profile.agent_id}/requeue_chats/`)
            .then((res) => {
                if (res.status == 200) {
                    //remove ongoing chats from supervisors
                    if (res.data) {
                        res.data.map(chat => {
                            const queue_name = chat.queues_name
                            const channel = chat.channel ? chat.channel : chat.hash
                            const connection = connections?.find(con => con.queue == queue_name)
                            if (queue_name && connection && channel) {
                                connection.send(JSON.stringify({ message: "DROP_CHAT", channel: { channel: chat.channel, user: user.profile.agent.name } }))
                                addNewChat(queue_name, channel)
                            }

                        })

                    }
                    handleLogout();
                    return;
                }
                setIsRequeueLoading(false);
                throw res;
            })
            .catch(async (err) => {
                console.error(err);
                useNotification(
                    'Ops!',
                    'Ocorreu um erro no sistema',
                    'danger'
                );
            });
    }


    const checkOnline = async () => {
        const user = getSession();
        const res = await api.get(`/api/enterprises/${user.profile.enterprise.id}/users/${user.id}/useronline/`)
        if (res.status == 200) {
            if (res.data == 'LOGOFF') {
                clearSession();
                clearToken();
                document.location.href = '/?typeAlert=error&showAlert=Usuário deslogado externamente.';
            }
            return
        }
    }

    useEffect(() => {
        checkOnline()
    }, [route])


    const handleLogout = () => {
        setIsLogoutButtonLoading(true);
        const user = getSession();
        if (user.profile.agent_id) {
            const token = getToken();
            // socket.send(JSON.stringify({"message": "LOGOUT"}));
            api.post(`/api/IV/agents/${user.profile.agent_id}/logout/`)
                .then((res) => {
                    if (res.status == 200) {
                        clearSession();
                        clearToken();
                        document.location.href = '/';
                        // api.post('/auth/token/blacklist/')
                        // .then((res) => {
                        //     clearSession();
                        //     clearToken();
                        //     document.location.href = '/'; 
                        // });
                        return;
                    }
                    throw res;
                })
                .catch((err) => {
                    if (err.data) {
                        if (err.data == 'Você não pode deslogar com atendimentos em andamento')
                            setConfirmRequeue(true);
                        else
                            useNotification('Atenção!', err.data, 'warning');
                    }
                    setIsLogoutButtonLoading(false);
                });
        }
    };

    const useStyles = makeStyles((theme) => ({
        root: {
            display: 'flex',
            flexDirection: 'column',
            height: '100%'
        },
        appBar: {
            zIndex: theme.zIndex.drawer + 1,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            backgroundColor: '#5399DB'
        },
        appBarShift: {
            marginLeft: drawerWidth,
            width: `calc(100% - ${drawerWidth}px)`,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        menuButton: {
            marginRight: 15,
            marginLeft: -20
        },
        hide: {
            display: 'none',
        },
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
            whiteSpace: 'nowrap',
        },
        drawerOpen: {
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        drawerClose: {
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            overflowX: 'hidden',
            width: theme.spacing(7)
        },
        toolbar: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            marginTop: 10
        },
        content: {
            width: '100%',
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden'
        },
    }));

    const classes = useStyles();
    const appClasses = clsx(classes.appBar, { [classes.appBarShift]: open, }) + ' app-bar';
    const drawerClasses = clsx(classes.drawer, { [classes.drawerOpen]: open, [classes.drawerClose]: !open, }) + ' left-drawer';

    useEffect(() => {
        switch (window.location.pathname) {
            case '/crm':
                setRoute(1);
                break;
            default:
                setRoute(0);
                break;
        }
    }, []);

    const hasBlockedChannels = () => {
        let blocked = false
        user.profile.enterprise.channels.forEach((channel) => {
            if ('info_360dialog' in channel && channel.info_360dialog !== undefined && channel.info_360dialog !== null && channel.info_360dialog?.account_mode !== 'live')
                blocked = true
        })
        return blocked
    }

    return (
        <div className={classes.root}>
            <ReactNotifications />
            <AppBar
                position="relative"
                className={appClasses}
            >
                <Toolbar className="toolbar-body">
                    <Box sx={{ display: "flex" }} flexGrow={1}>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            edge="start"
                            className={clsx(classes.menuButton, {
                                [classes.hide]: open,
                            })}
                            size="large">
                            <MenuIcon />
                        </IconButton>
                        <img src={LogoImage}
                            alt="logo"
                            className="logo"
                            id="logo"
                        />
                        {user.profile.enterprise.is_trial &&
                            <Alert variant="filled" severity="warning" className="trial-info-alert">
                                Período de degustação encerra em {new Date(user.profile.enterprise.trial_end_at).toLocaleString('pt-BR')}
                            </Alert>
                        }
                        {hasBlockedChannels() &&
                            <Alert variant="filled" severity="error" className="trial-info-alert">
                                Você possui um (ou mais) números WhatsApp inativos ou bloqueados.
                                Verifique em "Canais de Atendimento" > "WhatsApp".
                            </Alert>
                        }
                    </Box>
                    <AccountMenu
                        user={user}
                        menuItems={[{
                            icon: isLogoutButtonLoading
                                ? <CircularProgress color="inherit" size={16} />
                                : <LogoutIcon fontSize="small" />,
                            title: 'Logout',
                            onClick: () => handleLogout()
                        }]}
                        isLogoutButtonLoading={isLogoutButtonLoading}
                    />

                </Toolbar>
            </AppBar>
            <Box className="body-kwik">
                <ConfirmationDialog open={confirmRequeue}
                    title="Não é permitido deslogar com chats em atendimento"
                    description={(
                        <Box>
                            Deseja que os chats em atendimento sejam
                            transferidos de volta para suas respectivas
                            filas de origem?
                        </Box>
                    )}
                    handleClose={() => setConfirmRequeue(false)}
                    handleSubmit={() => requeueAgentChats()}
                    submitButtonLoading={isRequeueLoading}
                />

                <ConfirmationDialog open={showLogoutConfirmation}
                    title="Logout"
                    description={(
                        <Box>
                            Deseja realizar logout do sistema?
                        </Box>
                    )}
                    handleClose={() => setShowLogoutConfirmation(false)}
                    handleSubmit={() => handleLogout()}
                    submitButtonLoading={isLogoutButtonLoading}
                />


                <Drawer
                    variant="permanent"
                    className={drawerClasses}
                    classes={{
                        paper: clsx({
                            [classes.drawerOpen]: open,
                            [classes.drawerClose]: !open,
                        }),
                    }}
                >
                    <img src={LogoFavIcon}
                        alt="logo"
                        className="logoFavIcon"
                        id="logoFavIcon"
                    />
                    <div className={classes.toolbar}>
                        <IconButton onClick={handleDrawerClose} size="large">
                            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                        </IconButton>
                    </div>
                    <List>
                        {pages.map((item, index) => (
                            item.isVisible ?
                                (<Tooltip title={item.tooltip} placement="right" disableInteractive key={index}>
                                    <ListItem button key={index} onClick={item.onClick}>
                                        <ListItemIcon>{item.icon}</ListItemIcon>
                                        <ListItemText style={{ marginLeft: -15 }}
                                            primary={item.description}
                                        />
                                    </ListItem>
                                </Tooltip>) : null
                        ))}
                    </List>
                </Drawer>

                <main style={{ flex: 1 }} className={classes.content}>
                    {child}
                </main>
            </Box>
            <Box className="mobile-navigation">
                <BottomNavigation
                    showLabels
                    value={route}
                    onChange={(event, newValue) => {
                        mobilePages[newValue].onClick();
                        setRoute(newValue);
                    }}
                >
                    {mobilePages.map((item, index) => (
                        item.isVisible && (
                            <BottomNavigationAction
                                label={item.description}
                                icon={item.icon}
                                key={index}
                            />
                        )
                    ))}
                </BottomNavigation>
            </Box>
        </div>
    );
}

MiniDrawer.propTypes = {
    child: PropTypes.any
}

export default MiniDrawer;