import React, { useState, useEffect } from 'react'

import { Box, Grid, TextField } from '@mui/material'
import TooltipIndicators from '../../Dash/Tooltip'
import LoadingButton from '@mui/lab/LoadingButton';
import KeyIcon from '@mui/icons-material/Key';
import api from '../../../api';
import useNotification from '../../Notification';
import CredentialsDialog from '../../Dialogs/Credentials';

const KwikApiKey = () => {

    const [APIKey, setAPIKey] = useState(null);
    const [loading, setLoading] = useState(false);
    const [credentialsRequest, setCredentialsRequest] = useState(false);

    const generateAPIKey = (username, password) => {
        api.post('/auth/token/public/', {
            username: username,
            password: password
        }).then((res) => {
            if (res.status == 200) {
                setAPIKey(res.data.token);
                setCredentialsRequest(false);
                useNotification(
                    'Sucesso!',
                    `A chave de acesso à API do Kwik foi gerada com sucesso.`,
                    'sucess'
                );
                return;
            }
            throw res;
        }).catch((err) => {
            console.error(err);
            if (err.status == 400) {
                useNotification(
                    'Ops!',
                    `Não foi possível gerar sua chave de API com as credenciais informadas.`,
                    'danger'
                );
                return;
            }
            useNotification(
                'Ops!',
                `Ocorreu um erro no sistema.`,
                'danger'
            );
        })
    }

    const getKwikAPIKey = async () => {
        setLoading(true)
        const res = await api.get('/api/public/token/');
        setLoading(false)

        if (res.status == 200)
            setAPIKey(res.data);
    }

    useEffect(() => {
        getKwikAPIKey()
    }, [])

    return (
        <>
            {credentialsRequest &&
                <CredentialsDialog open={credentialsRequest}
                    title="Credenciais"
                    description="Para sua segurança, digite novamente suas credenciais de acesso ao Kwik."
                    handleClose={() => setCredentialsRequest(false)}
                    handleSubmit={generateAPIKey}
                />
            }
            <Grid item lg={6} md={12} sm={12} xs={12} className='input-settings'>
                <Box className="category-title push-bottom" >
                    Chave de API Kwik
                    <TooltipIndicators
                        text="Chave de API para acessar o Kwik através de um serviço externo."
                        marginLeft="5px"
                    />
                </Box>

                {APIKey &&
                    <TextField
                        size="small"
                        label="Token"
                        fullWidth
                        sx={{ marginTop: '-0.1rem' }}
                        type="text"
                        variant="outlined"
                        value={APIKey}
                        disabled={true}
                    />
                }
                {!APIKey &&
                    <LoadingButton
                        className="button-created"
                        color="info"
                        onClick={() => setCredentialsRequest(true)}
                        loading={loading}
                        loadingPosition="start"
                        startIcon={<KeyIcon />}
                        variant="contained"
                    >
                        Criar chave
                    </LoadingButton>
                }

            </Grid>
        </>
    )
}

export default KwikApiKey 