import React, {useEffect} from 'react'
import { ListItem, Box, ListItemButton, Divider } from "@mui/material"
import useVisibility from "../../../utils/isVisible"
import {CircularProgress} from "@mui/material"
import ConversationItem from "../../Chat/ConversationList/ConversationItem"

const FinishedRow = ({ parent, isVisibleHook = () => { }, data, onClickItem, activeChat, isManageCalls }) => {
    const [isVisible, currentElement, calcVisibility] = useVisibility(100, 100, parent)


    useEffect(() => {
        isVisibleHook(isVisible)
        calcVisibility()
    }, [isVisible])

    return (
        < ListItem component="div" disablePadding ref={currentElement} >
            <ListItemButton sx={{ padding: 0 }}>
                <Box key={`${data.id}-key-on-queue`} bgcolor='' sx={{ width: "100%", height: "100%" }}>
                    {(isVisible && data.id > 0) ?
                        <ConversationItem
                            onClick={() => { onClickItem(data); }}
                            showProgressTime={false}
                            activeId={activeChat?.uniqueid}
                            conversation={data}
                            isManageCalls={isManageCalls ? true : false}
                            setReadMessages={() => {}}
                        />
                        :
                        <Box className={'loader-skeleton'}
                            sx={{ height: '100px', justifyContent: "center" }}
                        >
                            <CircularProgress />
                        </Box>
                    }
                    <Divider />
                </Box>
            </ListItemButton >
        </ListItem>

    )
}

export default FinishedRow