import React, { useState, useEffect } from 'react'

import {
    Box,
    Grid,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField,
    Button,
    Switch,
    FormControlLabel,
    IconButton
} from "@mui/material";

import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import AddIcon from '@mui/icons-material/Add';

import RemoveIcon from '@mui/icons-material/Remove';

import { getSession } from '../../../auth';

import useNotification from '../../Notification';
import ConfirmationDialog from '../../Dialogs/Confirmation';
import ScrollDialog from '../../Dialogs/ScrollDialog';
import TooltipIndicators from '../../Dash/Tooltip';

import api from '../../../api';


import './index.css';
import EventManager from './EventManager';


const Integrations = () => {

    const [loading, setLoading] = useState(false);
    const [runInstall, setRunInstall] = useState(false);

    const [eventsInfo, setEventsInfo] = useState(false);
    const [reload, setReload] = useState(false);

    const [confirm, setConfirm] = useState(false);
    const [source, setSource] = useState('EVENTMANAGER');
    const [token, setToken] = useState('');
    const [baseURL, setBaseURL] = useState('');

    const [queueCallback, setQueueCallback] = useState('');
    const [queueCallbackData, setQueueCallbackData] = useState([]);

    const [agentMessageCallback, setAgentMessageCallback] = useState('');
    const [agentMessageCallbackData, setAgentMessageCallbackData] = useState([]);
    const [clientMessageCallback, setClientMessageCallback] = useState('');
    const [clientMessageCallbackData, setClientMessageCallbackData] = useState([]);

    const [hangupCallback, setHangupCallback] = useState('');
    const [hangupCallbackData, setHangupCallbackData] = useState([]);

    const reloadState = () => {
        setReload(p => !p)
    }

    const parseData = (obj, src) => {
        const data = [];

        if (src === 'ZENDESK' && 'ticket' in obj)
            obj = obj['ticket'];

        Object.keys(obj).forEach((key) => {
            if (typeof obj[key] === 'string' || obj[key] instanceof String) {
                data.push({
                    key: key,
                    subkey: '',
                    value: obj[key]
                })
            } else {
                Object.keys(obj[key]).forEach((subkey) => {
                    data.push({
                        key: key,
                        subkey: subkey,
                        value: obj[key][subkey]
                    });
                });
            }
        });

        // Keys are empty, start one
        if (data.length === 0)
            data.push({
                key: '',
                subkey: '',
                value: ''
            });

        return data;
    }

    const getIntegrations = async () => {
        const enterprise = getSession().profile?.enterprise;

        const res = await api.get(`/api/enterprises/${enterprise.id}/`);

        if (res.status == 200) {
            const settings = res.data.settings;

            if (settings.integration_source && ['ZENDESK'].includes(settings.integration_source)) {
                setSource(settings.integration_source);
                setToken(settings.integration_access_token);
                setBaseURL(settings.integration_base_url);
                setQueueCallback(settings.integration_queue_callback);
                setAgentMessageCallback(settings.integration_agent_message_callback);
                setClientMessageCallback(settings.integration_client_message_callback);
                setHangupCallback(settings.integration_hangup_callback);

                const qcData = parseData(settings.integration_queue_callback_data, settings.integration_source);
                const macData = parseData(settings.integration_agent_message_callback_data, settings.integration_source);
                const mccData = parseData(settings.integration_client_message_callback_data, settings.integration_source);
                const hcData = parseData(settings.integration_hangup_callback_data, settings.integration_source);

                setQueueCallbackData(qcData);
                setAgentMessageCallbackData(macData);
                setClientMessageCallbackData(mccData);
                setHangupCallbackData(hcData);
            } else if (settings.integration_source === 'EVENTMANAGER') {
                // Load eventManager
            }

        }
        else {
            useNotification(
                'Ops!',
                'Ocorreu um erro no sistema.',
                'danger'
            );
        }
    }

    const saveIntegrations = () => {
        if (source && !token) {
            useNotification(
                'Atenção!',
                `Para utilizar o CRM ${source} digite o token de acesso`,
                'warning'
            );
            return;
        }

        if (source && !baseURL) {
            useNotification(
                'Atenção!',
                `Para utilizar o CRM ${source} digite a URL Base`,
                'warning'
            );
            return;
        }

        if (source && runInstall)
            setConfirm(true);
        else
            patchIntegrations();
    }

    const getData = (obj, callback) => {
        const data = {};

        obj.forEach((each) => {
            if (each.subkey) {
                if (!(each.key in data))
                    data[each.key] = {};
                data[each.key][each.subkey] = each.value;
            }
            else
                data[each.key] = each.value;
        });

        if (Object.keys(data).length === 1 && Object.keys(data)[0] === '')
            // Avoid saving empty key {"": ""}
            return {};
        if (source === 'ZENDESK' && callback.indexOf('tickets') !== -1)
            // Zendesk ticket data encapsulated into 'ticket' key
            return { 'ticket': data };

        return data;
    }

    const patchIntegrations = () => {
        setConfirm(false);
        setLoading(true);

        const enterprise = getSession().profile?.enterprise;

        api.patch(`/api/enterprises/${enterprise.id}/`, {
            settings: {
                integration_source: source,
                integration_access_token: token,
                integration_base_url: baseURL,
                integration_queue_callback: queueCallback,
                integration_queue_callback_data: getData(queueCallbackData, queueCallback),
                integration_agent_message_callback: agentMessageCallback,
                integration_agent_message_callback_data: getData(agentMessageCallbackData, agentMessageCallback),
                integration_client_message_callback: clientMessageCallback,
                integration_client_message_callback_data: getData(clientMessageCallbackData, clientMessageCallback),
                integration_hangup_callback: hangupCallback,
                integration_hangup_callback_data: getData(hangupCallbackData, hangupCallback)
            }
        }).then((res) => {
            setLoading(false);
            if (res.status == 200) {
                useNotification(
                    'Sucesso!',
                    'Configurações de integração salvas com sucesso.',
                    'success'
                );
                if (runInstall) {
                    startZendeskInstall(
                        source,
                        token,
                        baseURL
                    );
                    setRunInstall(false);
                }
                return;
            }
            throw res;
        }).catch((err) => {
            console.error(err);
            useNotification(
                'Ops!',
                'Ocorreu um erro no sistema',
                'danger'
            );
        });
    }

    const startZendeskInstall = async (source, token, baseURL) => {
        const enterprise = getSession().profile?.enterprise;

        let res = null;

        switch (source) {
            case 'ZENDESK':
                const data = {
                    source: source,
                    url: baseURL,
                    token: token
                };
                const url = `/api/enterprises/${enterprise.id}/zendesk/install/`;
                res = await api.post(url, data, { timeout: 20000 });
                break;
            default:
                return;
        }

        if (res?.status == 200) {
            useNotification(
                'Sucesso!',
                'A base de clientes começou a ser sincronizada, esse processo pode demorar alguns instantes, continue a usar o kwik normalmente.',
                'success'
            );
        }
        else if (res?.status == 404) {
            let message = `Ocorreu um erro ao sincronizar a base de clientes.`
            if (res?.data?.error == 'Invalid token or invalid URL.')
                message = 'Token inválido ou URL inválida.'
            useNotification('Ops!', message, 'danger');
            return;
        }
        else {
            useNotification(
                'Ops!',
                `Ocorreu um erro ao sincronizar a base de clientes.`,
                'danger'
            );
            return;
        }
    }



    const updateData = (obj, key, value) => {
        obj[key] = value;
        reloadState();
    }

    const addRow = (obj) => {
        obj.push({
            key: '',
            subkey: '',
            value: ''
        });
        reloadState();
    }

    const delRow = (obj, index) => {
        obj.splice(index, 1);

        if (obj.length === 0) {
            obj.push({
                key: '',
                subkey: '',
                value: ''
            });
        }

        reloadState();
    }

    const setDefaultIntegration = () => {
        if (source === 'ZENDESK') {
            setQueueCallback(`POST::${baseURL}/api/v2/tickets`);
            setQueueCallbackData([
                {
                    key: 'subject',
                    subkey: '',
                    value: 'Atendimento Kwik!'
                },
                {
                    key: 'comment',
                    subkey: 'body',
                    value: 'Identificador da chamada: {{ivruniqueid}}\n\nTranscrição:\n{{transcription}}'
                }
            ]);
            setAgentMessageCallback(`PATCH::${baseURL}/api/v2/tickets/{{external_id}}`);
            setAgentMessageCallbackData([
                {
                    key: 'comment',
                    subkey: 'body',
                    value: '{{message}}'
                },
                {
                    key: 'status',
                    subkey: '',
                    value: 'pending'
                }
            ]);
            setClientMessageCallback(`PATCH::${baseURL}/api/v2/tickets/{{external_id}}`);
            setClientMessageCallbackData([
                {
                    key: 'comment',
                    subkey: 'body',
                    value: '{{message}}'
                },
                {
                    key: 'status',
                    subkey: '',
                    value: 'open'
                }
            ]);
        }
    }

    const showChatEventsInfo = () => {
        setEventsInfo(true);
    }

    const getEventsInfo = () => {
        return (
            <Box style={{ textAlign: "justify" }}>
                <p className="push-bottom">
                    Callbacks de eventos de chat serão executados assim que os
                    respectivos eventos ocorrerem no Kwik, se cadastrados.
                    As requisições são enviadas em formato JSON (application/json)
                    e a chave de acesso acima (token) é utilizada para autorizar a
                    requisição (Authorization: Bearer [token]).
                </p>
                <p className="push-bottom">
                    Aqui podem-se definir todos os aspectos das requisições, como o método
                    (POST ou PATCH), a URL à ser executada, e as chaves que serão recebidas
                    pela API externa.
                </p>
                <p className="push-bottom">
                    Abaixo há uma tabela contendo todas as variáveis internas do Kwik,
                    que podem ser utilizadas como valores, tanto nas URLs de callback,
                    como nos valores de cada uma das chaves JSON. Estas variáveis podem
                    ser utilizadas como a seguir &#123;&#123;variável&#125;&#125;:
                </p>
                <table className="table-vars">
                    <tr>
                        <td>&#123;&#123;transcription&#125;&#125;</td>
                        <td>Transcrição da chamada</td>
                    </tr>
                    <tr>
                        <td>&#123;&#123;message&#125;&#125;</td>
                        <td>Mensagem digitada</td>
                    </tr>
                    <tr>
                        <td>&#123;&#123;external_id&#125;&#125;</td>
                        <td>
                            Identificador externo, se existir
                            (quando criado um ticket referente à chamada)
                        </td>
                    </tr>
                    <tr>
                        <td>&#123;&#123;ivruniqueid&#125;&#125;</td>
                        <td>Identificador interno (Kwik)</td>
                    </tr>
                    <tr>
                        <td>&#123;&#123;ivrusername&#125;&#125;</td>
                        <td>Número de telefone do cliente</td>
                    </tr>
                </table>
                <p className="push-top">
                    Adicionalmente qualquer outra variável criada dentro do ChatBot,
                    também pode ser utilizada. Por exemplo, foi pedido o CPF do cliente,
                    e armazenado na variável 'cpf_cliente', a mesma pode ser utilizada
                    tanto na URL de callback, como nos valores das chaves JSON como
                    &#123;&#123;cpf_cliente&#125;&#125;.
                </p>
            </Box>
        );
    }

    useEffect(async () => {
        setLoading(true);
        await getIntegrations();
        setLoading(false);
    }, []);

    return (
        <>
            <ConfirmationDialog open={confirm}
                title="Integração de CRM"
                description={`Ao ativar a integração com o CRM ${source},
                todos os seus contatos serão atualizados com o da plataforma. 
                Qualquer contato que não esteja no CRM será excluído do kwik.
                Deseja continuar?`}
                handleClose={() => setConfirm(false)}
                handleSubmit={() => patchIntegrations()}
            />

            {eventsInfo &&
                <ScrollDialog open={eventsInfo}
                    title="Eventos de Chat"
                    content={getEventsInfo()}
                    handleClose={() => setEventsInfo(false)}
                />
            }
            <Box className="input-settings" sx={{ padding: '1rem' }}>
                <Grid container spacing={3}>
                    <Grid item lg={12} md={12} sm={12} xs={12}  >
                        <Box className="category-title push-bottom" >
                            Integração (CRM)
                        </Box>
                        <Grid container spacing={3} className="push-bottom">
                            <Grid item lg={6} md={12} sm={12} xs={12}>
                                <FormControl fullWidth size="small">
                                    <InputLabel id="crm-select-label">
                                        CRM
                                    </InputLabel>
                                    <Select
                                        labelId="crm-select-label"
                                        id="crm-select"
                                        label="CRM"
                                        value={source}
                                        onChange={(event) => {
                                            const source = event.target.value;
                                            setSource(source);
                                            if (source && source !== 'EVENTMANAGER')
                                                setRunInstall(true);
                                            else {
                                                setToken('');
                                                setBaseURL('');
                                                setQueueCallback('');
                                                setQueueCallbackData([{
                                                    key: '',
                                                    subkey: '',
                                                    value: ''
                                                }]);
                                                setAgentMessageCallback('');
                                                setAgentMessageCallbackData([{
                                                    key: '',
                                                    subkey: '',
                                                    value: ''
                                                }]);
                                                setClientMessageCallback('');
                                                setClientMessageCallbackData([{
                                                    key: '',
                                                    subkey: '',
                                                    value: ''
                                                }]);
                                                setHangupCallback('');
                                                setHangupCallbackData([{
                                                    key: '',
                                                    subkey: '',
                                                    value: ''
                                                }]);
                                            }
                                        }}>
                                        <MenuItem value="EVENTMANAGER">Gerenciador de eventos</MenuItem>
                                        <MenuItem value="ZENDESK">Zendesk</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        {(source && source !== 'EVENTMANAGER') &&
                            <Box>
                                <Grid container spacing={3} className="push-bottom">
                                    <Grid item lg={6} md={12} sm={12} xs={12}>
                                        <TextField
                                            size="small"
                                            label="Token"
                                            fullWidth
                                            type="text"
                                            variant="outlined"
                                            value={token}
                                            onChange={(event) => {
                                                setToken(event.target.value);
                                                setRunInstall(true);
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={3}>
                                    <Grid item lg={6} md={12} sm={12} xs={12}>
                                        <TextField
                                            size="small"
                                            label="URL Base"
                                            fullWidth
                                            type="text"
                                            variant="outlined"
                                            value={baseURL}
                                            onChange={(event) => {
                                                setBaseURL(event.target.value);
                                                setRunInstall(true);
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                        }
                        {(source && source !== 'EVENTMANAGER') &&
                            <>
                                <Box className="category-title push-top">
                                    Eventos de Chat
                                    <TooltipIndicators
                                        text="Clique para mais detalhes."
                                        marginLeft="5px"
                                        onClick={() => showChatEventsInfo()}
                                    />
                                </Box>
                                <Box className="chat-events-default-btn">
                                    <Button onClick={() => setDefaultIntegration()}>
                                        Setup Padrão
                                    </Button>
                                </Box>
                            </>
                        }
                        {(source && source !== 'EVENTMANAGER') &&
                            <Box className="callback-container first">
                                <Grid container spacing={3}>
                                    <Grid item lg={6} md={6} sm={12} xs={12}>
                                        <FormControlLabel
                                            label="Transferência para fila"
                                            control={
                                                <Switch
                                                    checked={queueCallback}
                                                    onChange={(event) => {
                                                        if (!queueCallback) {
                                                            setQueueCallback('POST::https://exemplo.com.br/caminho');
                                                            setQueueCallbackData([{
                                                                key: '',
                                                                subkey: '',
                                                                value: ''
                                                            }]);
                                                        }
                                                        else {
                                                            setQueueCallback('');
                                                            setQueueCallbackData([]);
                                                        }
                                                    }}
                                                />
                                            }
                                        />
                                    </Grid>
                                </Grid>
                                {queueCallback &&
                                    <Box className="push-top">
                                        <Grid container spacing={1} className="push-bottom">
                                            <Grid item lg={2} md={2} sm={2} xs={2}>
                                                <FormControl fullWidth size="small">
                                                    <InputLabel id="crm-select-label">
                                                        Método
                                                    </InputLabel>
                                                    <Select
                                                        labelId="type-select-label-queue"
                                                        id="type-select-queue"
                                                        label="Método"
                                                        value={queueCallback.split('::')[0]}
                                                        onChange={(event) => setQueueCallback(event.target.value + '::' + queueCallback.split('::')[1])}>
                                                        <MenuItem value="POST">POST</MenuItem>
                                                        <MenuItem value="PATCH">PATCH</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item lg={10} md={10} sm={10} xs={10}>
                                                <TextField
                                                    size="small"
                                                    label="URL"
                                                    fullWidth
                                                    type="text"
                                                    variant="outlined"
                                                    value={queueCallback.split('::')[1]}
                                                    onChange={(event) => setQueueCallback(queueCallback.split('::')[0] + '::' + event.target.value)}
                                                />
                                            </Grid>
                                        </Grid>
                                        {queueCallbackData?.map((each, index) => (
                                            <Box style={{ marginBottom: "10px" }}>
                                                <Grid container spacing={1}>
                                                    <Grid item lg={2} md={2} sm={2} xs={2} style={{ textAlign: "right" }}>
                                                        <IconButton
                                                            onClick={() => addRow(queueCallbackData)}
                                                            style={{
                                                                color: "white",
                                                                backgroundColor: "#0288d1",
                                                                padding: "3px",
                                                                marginTop: "3px"
                                                            }}
                                                        ><AddIcon /></IconButton>
                                                        <IconButton
                                                            onClick={() => delRow(queueCallbackData, index)}
                                                            style={{
                                                                color: "white",
                                                                backgroundColor: "#C95F50",
                                                                padding: "3px",
                                                                marginLeft: "10px",
                                                                marginTop: "3px"
                                                            }}
                                                        ><RemoveIcon /></IconButton>
                                                    </Grid>
                                                    <Grid item lg={2} md={2} sm={2} xs={2}>
                                                        <TextField
                                                            size="small"
                                                            label="Chave"
                                                            fullWidth
                                                            type="text"
                                                            variant="outlined"
                                                            value={each.key}
                                                            onChange={(event) => updateData(each, 'key', event.target.value)}
                                                        />
                                                    </Grid>
                                                    <Grid item lg={2} md={2} sm={2} xs={2}>
                                                        <TextField
                                                            size="small"
                                                            label="Chave 2"
                                                            fullWidth
                                                            type="text"
                                                            variant="outlined"
                                                            value={each.subkey}
                                                            onChange={(event) => updateData(each, 'subkey', event.target.value)}
                                                        />
                                                    </Grid>
                                                    <Grid item lg={6} md={6} sm={6} xs={6}>
                                                        <TextField
                                                            multiline
                                                            size="small"
                                                            label="Valor"
                                                            fullWidth
                                                            type="text"
                                                            variant="outlined"
                                                            value={each.value}
                                                            onChange={(event) => updateData(each, 'value', event.target.value)}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        ))}
                                    </Box>
                                }
                            </Box>
                        }
                        {(source && source !== 'EVENTMANAGER') &&
                            <Box className="callback-container">
                                <Grid container spacing={3}>
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <FormControlLabel
                                            label="Troca de mensagens (operador)"
                                            control={
                                                <Switch
                                                    checked={agentMessageCallback}
                                                    onChange={(event) => {
                                                        if (!agentMessageCallback) {
                                                            setAgentMessageCallback('PATCH::https://exemplo.com.br/caminho/{{external_id}}');
                                                            setAgentMessageCallbackData([{
                                                                key: '',
                                                                subkey: '',
                                                                value: ''
                                                            }]);
                                                        }
                                                        else {
                                                            setAgentMessageCallback('');
                                                            setAgentMessageCallbackData([]);
                                                        }
                                                    }}
                                                />
                                            }
                                        />
                                    </Grid>
                                </Grid>
                                {agentMessageCallback &&
                                    <Box className="push-top">
                                        <Grid container spacing={1} className="push-bottom">
                                            <Grid item lg={2} md={2} sm={2} xs={2}>
                                                <FormControl fullWidth size="small">
                                                    <InputLabel id="crm-select-label">
                                                        Método
                                                    </InputLabel>
                                                    <Select
                                                        labelId="type-select-label-message"
                                                        id="type-select-message"
                                                        label="Método"
                                                        value={agentMessageCallback.split('::')[0]}
                                                        onChange={(event) => setAgentMessageCallback(event.target.value + '::' + agentMessageCallback.split('::')[1])}>
                                                        <MenuItem value="POST">POST</MenuItem>
                                                        <MenuItem value="PATCH">PATCH</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item lg={10} md={10} sm={10} xs={10}>
                                                <TextField
                                                    size="small"
                                                    label="URL"
                                                    fullWidth
                                                    type="text"
                                                    variant="outlined"
                                                    value={agentMessageCallback.split('::')[1]}
                                                    onChange={(event) => setAgentMessageCallback(agentMessageCallback.split('::')[0] + '::' + event.target.value)}
                                                />
                                            </Grid>
                                        </Grid>
                                        {agentMessageCallbackData?.map((each, index) => (
                                            <Grid container spacing={1} style={{ marginBottom: "10px" }}>
                                                <Grid item lg={2} md={2} sm={2} xs={2} style={{ textAlign: "right" }}>
                                                    <IconButton
                                                        onClick={() => addRow(agentMessageCallbackData)}
                                                        style={{
                                                            color: "white",
                                                            backgroundColor: "#0288d1",
                                                            padding: "3px",
                                                            marginTop: "3px"
                                                        }}
                                                    ><AddIcon /></IconButton>
                                                    <IconButton
                                                        onClick={() => delRow(agentMessageCallbackData, index)}
                                                        style={{
                                                            color: "white",
                                                            backgroundColor: "#C95F50",
                                                            padding: "3px",
                                                            marginLeft: "10px",
                                                            marginTop: "3px"
                                                        }}
                                                    ><RemoveIcon /></IconButton>
                                                </Grid>
                                                <Grid item lg={2} md={2} sm={2} xs={2}>
                                                    <TextField
                                                        size="small"
                                                        label="Chave"
                                                        fullWidth
                                                        type="text"
                                                        variant="outlined"
                                                        value={each.key}
                                                        onChange={(event) => updateData(each, 'key', event.target.value)}
                                                    />
                                                </Grid>
                                                <Grid item lg={2} md={2} sm={2} xs={2}>
                                                    <TextField
                                                        size="small"
                                                        label="Chave 2"
                                                        fullWidth
                                                        type="text"
                                                        variant="outlined"
                                                        value={each.subkey}
                                                        onChange={(event) => updateData(each, 'subkey', event.target.value)}
                                                    />
                                                </Grid>
                                                <Grid item lg={6} md={6} sm={6} xs={6}>
                                                    <TextField
                                                        multiline
                                                        size="small"
                                                        label="Valor"
                                                        fullWidth
                                                        type="text"
                                                        variant="outlined"
                                                        value={each.value}
                                                        onChange={(event) => updateData(each, 'value', event.target.value)}
                                                    />
                                                </Grid>
                                            </Grid>
                                        ))}
                                    </Box>
                                }
                            </Box>
                        }
                        {(source && source !== 'EVENTMANAGER') &&
                            <Box className="callback-container">
                                <Grid container spacing={3}>
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <FormControlLabel
                                            label="Troca de mensagens (cliente)"
                                            control={
                                                <Switch
                                                    checked={clientMessageCallback}
                                                    onChange={(event) => {
                                                        if (!clientMessageCallback) {
                                                            setClientMessageCallback('PATCH::https://exemplo.com.br/caminho/{{external_id}}');
                                                            setClientMessageCallbackData([{
                                                                key: '',
                                                                subkey: '',
                                                                value: ''
                                                            }]);
                                                        }
                                                        else {
                                                            setClientMessageCallback('');
                                                            setClientMessageCallbackData([]);
                                                        }
                                                    }}
                                                />
                                            }
                                        />
                                    </Grid>
                                </Grid>
                                {clientMessageCallback &&
                                    <Box className="push-top">
                                        <Grid container spacing={1} className="push-bottom">
                                            <Grid item lg={2} md={2} sm={2} xs={2}>
                                                <FormControl fullWidth size="small">
                                                    <InputLabel id="crm-select-label">
                                                        Método
                                                    </InputLabel>
                                                    <Select
                                                        labelId="type-select-label-message"
                                                        id="type-select-message"
                                                        label="Método"
                                                        value={clientMessageCallback.split('::')[0]}
                                                        onChange={(event) => setClientMessageCallback(event.target.value + '::' + clientMessageCallback.split('::')[1])}>
                                                        <MenuItem value="POST">POST</MenuItem>
                                                        <MenuItem value="PATCH">PATCH</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item lg={10} md={10} sm={10} xs={10}>
                                                <TextField
                                                    size="small"
                                                    label="URL"
                                                    fullWidth
                                                    type="text"
                                                    variant="outlined"
                                                    value={clientMessageCallback.split('::')[1]}
                                                    onChange={(event) => setClientMessageCallback(clientMessageCallback.split('::')[0] + '::' + event.target.value)}
                                                />
                                            </Grid>
                                        </Grid>
                                        {clientMessageCallbackData?.map((each, index) => (
                                            <Grid container spacing={1} style={{ marginBottom: "10px" }}>
                                                <Grid item lg={2} md={2} sm={2} xs={2} style={{ textAlign: "right" }}>
                                                    <IconButton
                                                        onClick={() => addRow(clientMessageCallbackData)}
                                                        style={{
                                                            color: "white",
                                                            backgroundColor: "#0288d1",
                                                            padding: "3px",
                                                            marginTop: "3px"
                                                        }}
                                                    ><AddIcon /></IconButton>
                                                    <IconButton
                                                        onClick={() => delRow(clientMessageCallbackData, index)}
                                                        style={{
                                                            color: "white",
                                                            backgroundColor: "#C95F50",
                                                            padding: "3px",
                                                            marginLeft: "10px",
                                                            marginTop: "3px"
                                                        }}
                                                    ><RemoveIcon /></IconButton>
                                                </Grid>
                                                <Grid item lg={2} md={2} sm={2} xs={2}>
                                                    <TextField
                                                        size="small"
                                                        label="Chave"
                                                        fullWidth
                                                        type="text"
                                                        variant="outlined"
                                                        value={each.key}
                                                        onChange={(event) => updateData(each, 'key', event.target.value)}
                                                    />
                                                </Grid>
                                                <Grid item lg={2} md={2} sm={2} xs={2}>
                                                    <TextField
                                                        size="small"
                                                        label="Chave 2"
                                                        fullWidth
                                                        type="text"
                                                        variant="outlined"
                                                        value={each.subkey}
                                                        onChange={(event) => updateData(each, 'subkey', event.target.value)}
                                                    />
                                                </Grid>
                                                <Grid item lg={6} md={6} sm={6} xs={6}>
                                                    <TextField
                                                        multiline
                                                        size="small"
                                                        label="Valor"
                                                        fullWidth
                                                        type="text"
                                                        variant="outlined"
                                                        value={each.value}
                                                        onChange={(event) => updateData(each, 'value', event.target.value)}
                                                    />
                                                </Grid>
                                            </Grid>
                                        ))}
                                    </Box>
                                }
                            </Box>
                        }
                        {(source && source !== 'EVENTMANAGER') &&
                            <Box className="callback-container">
                                <Box>
                                    <Grid container spacing={3}>
                                        <Grid item lg={6} md={6} sm={12} xs={12}>
                                            <FormControlLabel
                                                label=" Desligamento"
                                                control={
                                                    <Switch
                                                        checked={hangupCallback}
                                                        onChange={(event) => {
                                                            if (!hangupCallback) {
                                                                setHangupCallback('PATCH::https://exemplo.com.br/caminho/{{external_id}}');
                                                                setHangupCallbackData([{
                                                                    key: '',
                                                                    subkey: '',
                                                                    value: ''
                                                                }]);
                                                            }
                                                            else {
                                                                setHangupCallback('');
                                                                setHangupCallbackData([]);
                                                            }
                                                        }}
                                                    />
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                                {source && hangupCallback &&
                                    <Box className="push-top">
                                        <Grid container spacing={1} className="push-bottom">
                                            <Grid item lg={2} md={2} sm={2} xs={2}>
                                                <FormControl fullWidth size="small">
                                                    <InputLabel id="crm-select-label">
                                                        Método
                                                    </InputLabel>
                                                    <Select
                                                        labelId="type-select-label-hangup"
                                                        id="type-select-hangup"
                                                        label="Método"
                                                        value={hangupCallback.split('::')[0]}
                                                        onChange={(event) => setHangupCallback(event.target.value + '::' + hangupCallback.split('::')[1])}>
                                                        <MenuItem value="POST">POST</MenuItem>
                                                        <MenuItem value="PATCH">PATCH</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item lg={10} md={10} sm={10} xs={10}>
                                                <TextField
                                                    size="small"
                                                    label="URL"
                                                    fullWidth
                                                    type="text"
                                                    variant="outlined"
                                                    value={hangupCallback.split('::')[1]}
                                                    onChange={(event) => setHangupCallback(hangupCallback.split('::')[0] + '::' + event.target.value)}
                                                />
                                            </Grid>
                                        </Grid>
                                        {hangupCallbackData?.map((each, index) => (
                                            <Box style={{ marginBottom: "10px" }}>
                                                <Grid container spacing={1}>
                                                    <Grid item lg={2} md={2} sm={2} xs={2} style={{ textAlign: "right" }}>
                                                        <IconButton
                                                            onClick={() => addRow(hangupCallbackData)}
                                                            style={{
                                                                color: "white",
                                                                backgroundColor: "#0288d1",
                                                                padding: "3px",
                                                                marginTop: "3px"
                                                            }}
                                                        ><AddIcon /></IconButton>
                                                        <IconButton
                                                            onClick={() => delRow(hangupCallbackData, index)}
                                                            style={{
                                                                color: "white",
                                                                backgroundColor: "#C95F50",
                                                                padding: "3px",
                                                                marginLeft: "10px",
                                                                marginTop: "3px"
                                                            }}
                                                        ><RemoveIcon /></IconButton>
                                                    </Grid>
                                                    <Grid item lg={2} md={2} sm={2} xs={2}>
                                                        <TextField
                                                            size="small"
                                                            label="Chave"
                                                            fullWidth
                                                            type="text"
                                                            variant="outlined"
                                                            value={each.key}
                                                            onChange={(event) => updateData(each, 'key', event.target.value)}
                                                        />
                                                    </Grid>
                                                    <Grid item lg={2} md={2} sm={2} xs={2}>
                                                        <TextField
                                                            size="small"
                                                            label="Chave 2"
                                                            fullWidth
                                                            type="text"
                                                            variant="outlined"
                                                            value={each.subkey}
                                                            onChange={(event) => updateData(each, 'subkey', event.target.value)}
                                                        />
                                                    </Grid>
                                                    <Grid item lg={6} md={6} sm={6} xs={6}>
                                                        <TextField
                                                            multiline
                                                            size="small"
                                                            label="Valor"
                                                            fullWidth
                                                            type="text"
                                                            variant="outlined"
                                                            value={each.value}
                                                            onChange={(event) => updateData(each, 'value', event.target.value)}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        ))}
                                    </Box>
                                }
                            </Box>
                        }

                    </Grid>
                </Grid>
                <>
                    {(source && source === 'EVENTMANAGER') &&
                        <EventManager />
                    }
                </>
                {(source && source !== 'EVENTMANAGER') &&
                    <Box sx={{ marginTop: "1rem" }}>
                        <Button className="button-close"
                            onClick={() => getIntegrations()}>
                            Descartar
                        </Button>
                        <LoadingButton
                            className="button-created"
                            onClick={() => saveIntegrations()}
                            loading={loading}
                            loadingPosition="start"
                            startIcon={<SaveIcon />}
                            variant="contained"
                        >
                            Salvar
                        </LoadingButton>
                    </Box>}
            </Box>
        </>
    )
}

export default Integrations
