import React from 'react';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import {
  DialogActions,
  DialogContent,
  Box,
  TextField,
  Switch,
  DialogTitle,
  Button,
  Slide,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Divider,
  Radio,
  RadioGroup,
} from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';

import MultipleSelections from '../../../Input/MultipleSelections';

import useNotification from "../../../Notification";

import "./styles.css";

import { getSession } from "../../../../auth";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class Modal extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      groups                 : [],
      isActive               : false,
      number                 : '',
      name                   : '',
      email                  : '',
      loading                : false,
      isVoice                : false,
      enterpriseHasVoice     : false,
      enterpriseHasAssistant : false,
      userHasAssistant       : false,
      first_name             : "",
      last_name              : "",
      nickname               : "",
      body                   : {},
      selectedOption         : '',
      showInputs             : false,
      externalServerUri      : '',     // Estado para a URL
      externalUsername       : '',     // Estado para o nome de usuário
      externalPassword       : '',     // Estado para a senha
    }

    this.groups = [
      'administrador',
      'supervisor',
      'operador'
    ];

    this.dialogTitle = null;
  }

  onChangeName = (event) => {
    this.setState({ name: event.target.value });
  }

  onChangeEmail = (event) => {
    this.setState({ email: event.target.value });
  }

  onChangeSelectedOption = (event) => {
    const value = event.target.value;
    this.setState({ selectedOption: value, showInputs: value === 'external' });
    this.state.body['enterprise_has_voice'] = this.state.enterpriseHasVoice;
    this.state.body['is_voice'] = true;        
  };

  onChangeURL = (event) => {
    this.state.body['enterprise_has_voice'] = this.state.enterpriseHasVoice;
    this.state.body['is_voice'] = true;
    this.setState({ externalServerUri: event.target.value });        
  };

  onChangeUsername = (event) => {
    this.state.body['enterprise_has_voice'] = this.state.enterpriseHasVoice;
    this.state.body['is_voice'] = true;
    this.setState({ externalUsername: event.target.value });        
  };

  onChangePassword = (event) => {    
    this.state.body['enterprise_has_voice'] = this.state.enterpriseHasVoice;
    this.state.body['is_voice'] = true;
    this.setState({ externalPassword: event.target.value });
  };

  setChecked = (condition) => {
    this.state.body['is_active'] = condition;
    this.setState({ isActive: condition });
  }

  setCheckedVoice = (condition) => {
    this.state.body['enterprise_has_voice'] = this.state.enterpriseHasVoice;
    this.state.body['is_voice'] = condition;
    this.state.body['extension_type'] = this.state.selectedOption;    
    this.setState({ isVoice: condition });
  }

  setUserHasAssistant = (condition) => {
    if (!this.state.body.hasOwnProperty('profile'))
      this.state.body['profile'] = {};
    this.state.body['profile']['has_assistant'] = condition;
    this.setState({ userHasAssistant: condition });
  }

  setExternalInputs = () => {        
    if (this.state.selectedOption == 'internal') {      
      this.state.body['external_server_uri'] = '';
      this.state.body['external_username'] = '';
      this.state.body['external_password'] = '';
      this.state.body['extension_type'] = 'internal';
    } else if (this.state.selectedOption == 'external') {      
      this.state.body['external_server_uri'] = this.state.externalServerUri;
      this.state.body['external_username'] = this.state.externalUsername;
      this.state.body['external_password'] = this.state.externalPassword;
      this.state.body['extension_type'] = 'external';
    }
  }

  saveUser = async () => {
    this.setState({ loading: true });

    if (this.props.showUpdateUser) {
      this.setExternalInputs();
      await this.props.updateUser(this.props.user.id, this.state.body);
    } else {
      const name       = this.state.first_name;
      const surname    = this.state.last_name
      const email      = this.state.email;
      const nickname   = this.state.nickname

      if (!name || !email) {
        useNotification(
          'Ops!',
          'Os campos não podem ser vazios.',
          'danger'
        );
        return;
      }

      const body = {
        first_name : name,
        last_name  : surname,
        email      : email
      };

      this.props.createUser(body);
    }
  };

  componentWillMount() {
    const user = this.props.user;
    const user_session = getSession();

    let voice = false;

    if (user && user.groups) {
      const groups = [];

      user.groups.map(group => {
        groups.push(group.name);
      });

      if (user_session.profile.enterprise.has_voice) {
        if (user.profile.agent.preferred_interface)
          voice = true;
        else
          voice = false;
      } else
        voice = false;

      this.state.groups = groups;
      this.state.isActive = user.is_active;
      this.state.isVoice = voice;
      this.state.body = {
        first_name : user.first_name,
        last_name  : user.last_name,
        nickname   : user.profile.nickname
      }
      this.dialogTitle = user.username;
      this.state.userHasAssistant = user.profile.has_assistant;
      this.state.enterpriseHasVoice = user_session.profile.enterprise.has_voice;
      this.state.enterpriseHasAssistant = user_session.profile.enterprise.has_assistant;
      this.state.externalServerUri = user.profile.agent.external_server_uri;
      this.state.externalUsername = user.profile.agent.external_username;
      this.state.externalPassword = user.profile.agent.external_password;
      this.state.showInputs = user.profile.agent.external_server_uri != '';
      this.state.selectedOption = user.profile.agent.external_server_uri != '' ? "external" : "internal";
    }
  }


  render() {
    return (
      <>
        <DialogTitle className="group-flexbox">
          <span>{this.props.showUpdateUser ? this.dialogTitle : 'Criar novo usuário'} </span>
          <Button sx={{ 'minWidth': '2rem', 'padding': '0' }} onClick={this.props.handleClose} ><CloseIcon /></Button>
        </DialogTitle>
        <Divider />
        <DialogContent>
          {this.props.showUpdateUser &&
            <>
              <Box className="input-users-modal" sx={{ gap: "1rem", display: "flex" }}>
                <TextField
                  size="small"
                  label="Nome"
                  fullWidth
                  type="text"
                  variant="outlined"
                  value={this.state.body.first_name}
                  onChange={(e) => this.setState({ body: { ...this.state.body, first_name: e.target.value } })}
                />
                <TextField
                  size="small"
                  label="Sobrenome"
                  fullWidth
                  type="text"
                  variant="outlined"
                  value={this.state.body.last_name}
                  onChange={(e) => this.setState({ body: { ...this.state.body, last_name: e.target.value } })}
                />
                <TextField
                  size="small"
                  label="Apelido"
                  fullWidth
                  type="text"
                  variant="outlined"
                  value={this.state.body.nickname}
                  onChange={(e) => this.setState({ body: { ...this.state.body, nickname: e.target.value } })}
                />
              </Box>

              <MultipleSelections
                title="Grupos"
                selectedData={this.state.groups}
                dataProp={this.groups}
                getSelect={(groups) => {this.setState({body: {...this.state.body, groups:groups}})}}
                colors={this.props.groupColors}
              />
              <Box>
                {getSession().id !== this.props.user.id && <FormControl component="fieldset" style={{ marginTop: "5px" }}>
                  <FormLabel component="legend">Ativo</FormLabel>
                  <FormGroup className="push-left" aria-label="position" row>
                    <FormControlLabel
                      control={
                        <Switch
                          color="primary"
                          checked={this.state.isActive}
                          onChange={() => this.setChecked(!this.state.isActive)}
                        />
                      }
                    />
                  </FormGroup>
                </FormControl>}
              </Box>
              {this.state.enterpriseHasVoice &&
                <>
                  <Box>
                    <FormControl component="fieldset" style={{ marginTop: "5px" }}>
                      <FormLabel component="legend">Voz</FormLabel>
                      <FormGroup className="push-left" aria-label="position" row>
                        <FormControlLabel
                          control={
                            <Switch
                              color="primary"
                              checked={this.state.isVoice}
                              onChange={() => this.setCheckedVoice(!this.state.isVoice)}
                            />
                          }
                        />
                      </FormGroup>
                    </FormControl>
                  </Box>
                </>
              }
              {this.state.enterpriseHasAssistant &&
                <>
                  <Box>
                    <FormControl component="fieldset" style={{ marginTop: "5px" }}>
                      <FormLabel component="legend">Cortex</FormLabel>
                      <FormGroup className="push-left" aria-label="position" row>
                        <FormControlLabel
                          control={
                            <Switch
                              color="primary"
                              checked={this.state.userHasAssistant}
                              onChange={() => this.setUserHasAssistant(!this.state.userHasAssistant)}
                            />
                          }
                        />
                      </FormGroup>
                    </FormControl>
                  </Box>
                </>
              }
            </>
          }
          {this.state.isVoice &&
            <>
              <Box>
                <FormControl component="fieldset">
                  <FormLabel component="legend">Selecione uma opção:</FormLabel>
                  <RadioGroup
                    aria-label="opções"
                    name="opcoes"
                    value={this.state.selectedOption}
                    onChange={this.onChangeSelectedOption}
                  >
                    <FormControlLabel
                      value="internal"
                      control={<Radio />}
                      label="Interno"
                    />
                    <FormControlLabel
                      value="external"
                      control={<Radio />}
                      label="Externo"
                      style={{ marginBottom: '20px' }}
                    />
                  </RadioGroup>
                </FormControl>
                {this.state.showInputs && (
                  <Box className="input-users-modal" sx={{ gap: "1rem", display: "flex" }}>
                    <TextField
                      size="small"
                      label="URL"
                      variant="outlined"
                      fullWidth
                      value={this.state.externalServerUri}
                      onChange={this.onChangeURL}                      
                    />
                    <TextField
                      size="small"
                      label="Nome de Usuário"
                      variant="outlined"
                      fullWidth
                      value={this.state.externalUsername}
                      onChange={this.onChangeUsername}
                    />
                    <TextField
                      size="small"
                      label="Senha"
                      variant="outlined"
                      type="password"
                      fullWidth
                      value={this.state.externalPassword}
                      onChange={this.onChangePassword}
                    />
                  </Box>
                )}
              </Box>
            </>
          }                  

          {!this.props.showUpdateUser &&
            <>
              <Box className="input-users-modal" sx={{gap:"1rem", display:"flex"}}>
                <TextField
                  size="small"
                  label="Nome"
                  fullWidth
                  type="text"
                  variant="outlined"
                  value={this.state.first_name}
                  onChange={(e)=>this.setState({first_name:e.target.value})}
                />
                <TextField
                  size="small"
                  label="Sobrenome"
                  fullWidth
                  type="text"
                  variant="outlined"
                  value={this.state.last_name}
                  onChange={(e)=>this.setState({last_name:e.target.value})}
                />
              </Box>
              <Box className="input-users-modal">
                <TextField
                  size="small"
                  label="E-mail"
                  fullWidth
                  type="email"
                  variant="outlined"
                  defaultValue={this.state.email}
                  onChange={this.onChangeEmail}
                />
              </Box>
            </>
          }
          <Box className="user-footer">
            <LoadingButton className="button-created"
              onClick={this.props.handleClose}
            >
              Cancelar
            </LoadingButton>
            <LoadingButton className="button-created"
              onClick={() => this.saveUser()}
              disabled={!this.props.showUpdateUser && (!this.state.first_name || !this.state.email)}
              loading={false}
            >
              Salvar
            </LoadingButton>
          </Box>
        </DialogContent>
      </>
    );
  }
}

export default Modal;