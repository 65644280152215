

import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Drawer from '@mui/material/Drawer';
import PropTypes from "prop-types";
import FilterListIcon from '@mui/icons-material/FilterList';

const PersistentFilter = ({ content, title }) => {
    const [open, setOpen] = useState(false);

    const useStyles = makeStyles({
        list: {
            width: 250,
        },
        fullList: {
            width: 'auto',
        },
        icon: {
            cursor: "pointer",
            color: "gray",
            '&:hover': {
                color: "#21446C",
            },
        },
    });

    const classes = useStyles();

    const toggleDrawer = () => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setOpen(!open);
    };



    return (
        <div>
            <FilterListIcon fontSize="medium" onClick={toggleDrawer()} className={classes.icon} />
            <Drawer anchor={'right'} open={open} onClose={toggleDrawer()}>
                {content}
            </Drawer>
        </div>
    );
}

PersistentFilter.propTypes = {
    content: PropTypes.any,
    title: PropTypes.string,
};

export default PersistentFilter;
