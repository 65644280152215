import React, { useState, useRef } from "react"

import { TextField, Box, List, ListItemButton, IconButton, Tooltip } from '@mui/material'

import { styled } from '@mui/material/styles';
import { tooltipClasses } from '@mui/material/Tooltip';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

import './styles.css'

const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} placement="top" />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: 200,
    },
});

const Dropdown = ({ onVariableSelect, index = '' }) => {

    const dropref = useRef()
    const buttonref = useRef()
 
    const [showOptions, setShowOptions] = useState(false)

    const setTarget = (variable, index = ' ') => target(variable, index)

    function getAbsPosition(element) {
        var rect = element.getBoundingClientRect();
        return { x: rect.left, y: rect.top }
    }

    const moveDiv = () => {
        const selectedDiv = dropref?.current
        const selectedButton = buttonref?.current

        const coords = getAbsPosition(selectedButton);

        const posX = coords.x - selectedDiv.clientWidth
        const posY = coords.y

        selectedDiv.style.left = `${posX}px`
        selectedDiv.style.top = `${posY}px`

        setShowOptions(true)
    }

    return (
        <>
            <CustomWidthTooltip title='Selecione uma variável'>
                <IconButton ref={buttonref} className='absolute-button' onClick={e => { e.preventDefault(); moveDiv();  }}>
                    <KeyboardArrowDownIcon />
                </IconButton>
            </CustomWidthTooltip>
            <Box className={`select-pop-up-backdrop ${showOptions ? 'active': ''}`} onClick={() => setShowOptions(false)}></Box>
            <Box ref={dropref} className={`select-pop-up ${showOptions ? 'active': ''}`}>
                <List className='select-list insiderlist'>
                    <ListItemButton
                        className='select-list-item'
                        onClick={() => onVariableSelect('{{nome_cliente}}')}
                    >
                        Nome do cliente
                    </ListItemButton>
                    <ListItemButton
                        className='select-list-item'
                        onClick={() => onVariableSelect('{{nome_operador}}')}
                    >
                        Nome do operador
                    </ListItemButton>
                    <ListItemButton
                        className='select-list-item'
                        onClick={() => onVariableSelect('{{codigo_cliente}}')}
                    >
                        Código do cliente
                    </ListItemButton>
                </List>
            </Box>
        </>
    )
}

const VariableTextField = ({ sx, size, label, value, variant, multiline, fullWidth, onChange, onVariableSelect, key }) => {

    return (
        <Box className="relative-box">
            <TextField
                sx={sx}
                fullWidth={fullWidth}
                multiline={multiline}
                size={size}
                label={label}
                type="text"
                variant={variant}
                value={value}
                onChange={onChange}
                key={key}
            />
            <Dropdown onVariableSelect={onVariableSelect}/>
        </Box>
    )
}

export default VariableTextField
