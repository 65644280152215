import React, { useState, useEffect } from "react";

import { Box, Grid, Divider, IconButton, Tooltip } from "@mui/material";
import { ListItem, List, ListItemText } from "@mui/material";
import FileIcon from '@mui/icons-material/DescriptionOutlined';
import PlayIcon from '@mui/icons-material/PlayCircle';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import PauseIcon from '@mui/icons-material/Pause';
import useNotification from "../../../Notification";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import PropTypes from "prop-types";

import Typography from "../../../Typography";
import AvatarName from "../../../AvatarName";
import ChangeField from "../../../ChangeField";

import api from '../../../../api';

import userPermissions from '../../../User/Permissions';
import { getSession } from '../../../../auth';

import "../styles.css";

export default function Profile({ agentCanUpdateContact, contactData, isManageCalls, updateField, fetchHistoryMessages, chat, reloadContact }) {

    const [groups, updateGroups] = useState({});
    const [isIntegration, updateIsIntegration] = useState(false);
    const [renderKey, updateRenderKey] = useState(0);
    const [render, updateRender] = useState(0.0);

    const enterprise = getSession().profile.enterprise;

    useEffect(() => {
        const user = getSession();
        if (user)
            getSettings(user.profile.enterprise.id);
        updateGroups(userPermissions());
    }, []);

    // const isNotOperator = () => {
    //     return groups.isNotOnlyOperator;
    // }

    const disableEditInput = (field) => {
        const dataField = contactData ? contactData[field] : null;

        // If the field is empty or
        // the field is 'Usuário Anônimo' or
        // the settings allow the agent to edit or
        // is an operator
        if (!dataField ||
            dataField === 'Usuário Anônimo' ||
            agentCanUpdateContact || groups.isNotOnlyOperator
            // isNotOperator
        )
            return false;

        return true;
    }

    const checkIntegration = (enterprises) => {
        const source = enterprises.settings.integration_source;
        const access_token = enterprises.settings.integration_access_token;
        const base_url = enterprises.settings.integration_base_url;

        if (source && access_token && base_url) {
            updateIsIntegration(true);
            updateRender(Math.random());
        }
    }

    const getSettings = async (enterpriseId) => {
        await api.get(`/api/enterprises/${enterpriseId}/`)
            .then((res) => {
                if (res.status == 200) {
                    checkIntegration(res.data);
                    return;
                }
                throw res;
            }).catch((err) => {
                console.error(err);
            });
    }

    return (
        <Box>
            {contactData &&
                <Box className="contactHistory-box-2">
                    <Box className="avatar-profile">
                        {contactData.name ?
                            <AvatarName
                                name={contactData.name}
                                size="big"
                            />
                            :
                            <AvatarName
                                name="N"
                                size="big"
                            />
                        }
                    </Box>
                    <Box className="contactHistory-box-3">
                        <ChangeField value={contactData.name}
                            router={`api/enterprises/${contactData.enterprise}/contacts/${contactData.id}/`}
                            field="name"
                            label="Nome"
                            updateField={(data) => {
                                contactData.name = data;
                                if (chat){
                                    chat.contact = {...chat.contact, 'name': data}
                                }
                                updateField();
                            }}
                            disabledChange={disableEditInput('name')}
                        />
                    </Box>
                    <Box className="contactHistory-box-4">
                        <ChangeField value={contactData.telephone}
                            router={`api/enterprises/${contactData.enterprise}/contacts/${contactData.id}/`}
                            field="telephone"
                            label="Telefone"
                            updateField={(data) => {
                                contactData.telephone = data;
                                if (chat){
                                    chat.contact = {...chat.contact, 'telephone': data}
                                }
                                updateField();
                            }}
                            disabledChange={isIntegration || disableEditInput('telephone')}
                            key={renderKey}
                            reloadContact={reloadContact}
                        />
                    </Box>
                    <Box className="contactHistory-box-4">
                        <ChangeField value={contactData.email}
                            router={`api/enterprises/${contactData.enterprise}/contacts/${contactData.id}/`}
                            field="email"
                            label="Email"
                            updateField={(data) => {
                                contactData.email = data;
                                if (chat){
                                    chat.contact = {...chat.contact, 'email': data}
                                }
                                updateField();
                            }}
                            disabledChange={isIntegration || disableEditInput('email')}
                            key={renderKey}
                        />
                    </Box>
                    <Box className="contactHistory-box-4">
                        <ChangeField value={contactData.customer_code}
                            router={`api/enterprises/${contactData.enterprise}/contacts/${contactData.id}/`}
                            field="customer_code"
                            label="Código do cliente"
                            updateField={(data) => {
                                contactData.customer_code = data;
                                if (chat){
                                    chat.contact = {...chat.contact, 'customer_code': data}
                                }
                                updateField();
                            }}
                            disabledChange={isIntegration || disableEditInput('customer_code')}
                            key={renderKey}
                        />
                    </Box>
                    <Box className="contactHistory-box-4">
                        <ChangeField value={contactData.enterprise_name}
                            router={`api/enterprises/${contactData.enterprise}/contacts/${contactData.id}/`}
                            field="enterprise_name"
                            label="Empresa"
                            updateField={(data) => {
                                contactData.enterprise_name = data;
                                if (chat){
                                    chat.contact = {...chat.contact, 'enterprise_name': data}
                                }
                                updateField();
                            }}
                            disabledChange={isIntegration || disableEditInput('enterprise_name')}
                            key={renderKey}
                        />
                    </Box>
                    <Box className="contactHistory-box-8">
                        <ChangeField value={contactData.enterprise_cnpj}
                            router={`api/enterprises/${contactData.enterprise}/contacts/${contactData.id}/`}
                            field="enterprise_cnpj"
                            label="CNPJ"
                            updateField={(data) => {
                                contactData.enterprise_cnpj = data;
                                if (chat){
                                    chat.contact = {...chat.contact, 'enterprise_cnpj': data}
                                }
                                updateField();
                            }}
                            disabledChange={isIntegration || disableEditInput('enterprise_cnpj')}
                            key={renderKey}
                        />
                    </Box>
                    <span style={{ fontSize: "90%", fontStyle: "italic", margin: "0 0 1rem 0"}}>
                        Cadastrado em {new Date(contactData.created_at).toLocaleDateString('pt-BR')}
                    </span>
                </Box>
            }
        </Box>
    );
}
