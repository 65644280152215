import React, { useState } from 'react'
import { Box, IconButton, Button, List, ListItem } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const componentsExample = () => {
    return {
        comp1: {
            permissions: true,
            style: 'title', //Will display a title element
            label: 'Example configuration',
            className: '' // class will be forwarded to the li component
        },
        comp2: {
            permissions: true,
            style: 'list', // Will display a list component
            label: 'Componente 1',
            sublabel: 'Esse é o componente 1',
            redirect: (<>OnMenuOn</>),
            className: '' // class will be forwarded to the li component
        },
        comp3: {
            permissions: true,
            style: 'free',  //Will display the entire element
            redirect: (<></>),
            className: '' // class will be forwarded to the li component
        },

    }
}

const SettingsIntermediate = ({ title = '', components = () => ({}) }) => {

    const [onMenu, setOnMenu] = useState(null)

    return (
        <>
            <Box className='settings-flexcolumn'>
                {!onMenu &&
                    <List className='clear-padding settings-list-v2'>
                        {Object.entries(components())?.map(([key, value], index) => (
                            (value.isVisible === undefined || value.isVisible === true) && (
                                value.permissions && (
                                    <ListItem className={`clear-padding ${value.className}`} id={`${key}-${index}-settings-v2`} key={`${key}-${index}-settings-v2`}>
                                        {value.style === 'free' && <Box className='settings-free-v2'>{value.redirect}</Box>}
                                        {value.style === 'title' && <Box className="settings-title-v2">{value.label}</Box>}
                                        {value.style === 'list' && (
                                            <Button className='settings-button-item' onClick={() => { setOnMenu(value) }}>
                                                <Box>{value.label}</Box>
                                                <Box className='sublabel'>{value.sublabel}</Box>
                                            </Button>
                                        )}
                                    </ListItem>
                                )
                            )
                        ))}
                    </List>}
                {onMenu &&
                    <Box className='settings-content-v2' >
                        <Box className='options-menu-title'>
                            <IconButton sx={{ padding: 0 }} onClick={() => { setOnMenu(null) }}>
                                <ArrowBackIcon />
                            </IconButton>
                            <span>{title} / <span style={{ color: '#797bb6' }}>{onMenu.label}</span></span>
                        </Box>
                        <Box className='settings-display-v2'>{onMenu.redirect}</Box>
                    </Box>}
            </Box>
        </>
    )
}

export default SettingsIntermediate