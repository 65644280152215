import React from "react";

import { Box, IconButton, Tooltip, Toolbar } from "@mui/material";

import { getSession } from "../../../../../auth";

import MemoMessage from "./MemoMessage";

import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

import "./styles.css";

class MessageArea extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            messages: [],
            shouldScrollDown: false,
            gluedOnBottom: true,
            messageAmount: 0,
            firstScroll: true,
        }

        this.nameArea = '';
        this.queuesName = {};

    }

    invokeMessageArea = () => {
        if (this.state.firstScroll) {
            this.dragScrollDownEffect()
            this.setState({ firstScroll: false })
        }

        return (
            <>
                {
                    this.props.messages &&
                    <ul>
                        {this.props.messages.map((message, i) =>

                            // <Message key={i} message={message.message} />
                            <MemoMessage
                                message={message}
                                index={i}
                                accountcode={this.props.accountcode}
                                isManageCalls={this.props.isManageCalls}
                                queuesName={this.queuesName}
                                enterpriseId={this.props.enterpriseId}
                                activeChat={this.props.activeChat}
                            />
                        )
                        }
                    </ul>
                }
            </>
        )

    }


    dragScrollDownEffect = () => {
        let messageArea = document.getElementById(this.nameArea);
        if (messageArea) {
            messageArea.scrollTop = messageArea.scrollHeight;
        }


        if (this.state.shouldScrollDown)
            this.setState({ shouldScrollDown: false })
    }


    dragScrollDown = () => {
        if (this.state.gluedOnBottom) {
            this.dragScrollDownEffect()
            return
        }

        if (!this.state.shouldScrollDown)
            this.setState({ shouldScrollDown: true })
    }

    messageAreaKey = () => {
        const messages = this.props.messages;

        if (messages && typeof messages === 'object')
            return `messageArea-${messages.length}`;
        return 'messageArea-0';
    }

    componentWillMount() {
        const user = getSession();
        let queuesName = {};

        // Save code references to capture queue names

        user?.profile.enterprise.queues.forEach(queue => {
            const name_queue = queue.name.split('_')[1];
            queuesName[name_queue] = queue.description;
        });

        this.queuesName = queuesName;
        this.nameArea = this.props.isManageCalls ? 'messageArea-manageCalls' : 'messageArea';
    }

    componentDidMount() {
        this.dragScrollDownEffect();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.dragScrollDown()
    }

    detectPosition = (event) => {
        if ((event.target.scrollHeight - Math.round(event.target.scrollTop)) <= event.target.offsetHeight + 1) {
            this.setState({ gluedOnBottom: true, messageAmount: 0 })
            return
        }
        if (this.state.gluedOnBottom)
            this.setState({ gluedOnBottom: false })
    }

    addToMessageAmount = () => {
        this.setState({ messageAmount: this.state.messageAmount + 1 })
    }

    render() {
        return (
            <>
                <Box style={{ display: this.props.preview ? 'none' : 'block' }} id={this.nameArea} key={this.messageAreaKey()} onScroll={this.detectPosition}>
                    {this.props.messageArea ? this.props.messageArea() : this.invokeMessageArea()}
                </Box >
                {(this.state.shouldScrollDown && !this.state.gluedOnBottom && !this.props.preview) &&
                    <Box className='scrolldown-box' onClick={() => this.dragScrollDownEffect()}>
                        <Box sx={{ display: 'flex', alignItems: 'bottom' }}>
                            <ArrowDownwardIcon />
                        </Box>
                        {this.state.messageAmount > 0 &&
                            <Box
                                className='messageAmount-badge'>
                                {this.state.messageAmount}
                            </Box>
                        }
                    </Box>
                }
            </>
        );
    }
}



export default MessageArea;
