import React, { useState} from "react";

import { IconButton, Dialog, DialogTitle, DialogContent, DialogActions,  Button, TextField, Chip} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

import CloseIcon from '@mui/icons-material/Close';

import { FileDrop } from 'react-file-drop';

import ImportIcon from '@mui/icons-material/Upload';

import useMediaQuery from '@mui/material/useMediaQuery';
import Information from "../../Crm/TabButtons/Information";


export default function ResponsiveImportButton({ className, loading, isDisabled, onClickFn, shouldRenderHiddenInput, onChangeFn, is_financial_market_company }) {
const isSmallScreen = useMediaQuery('(max-width: 60rem)');
  
const [selectedSeparator, setSelectedSeparator] = useState(null);
const [showDialog, setShowDialog ] = useState(false);
const [file, setFile] = useState(null);
const [separatorLabel, setSeparatorLabel] = useState("Personalizado");


  return (
    <>
      <>
        <LoadingButton
          className={className}
          variant="contained"
          component="label"
          color="success"
          loading={loading}
          loadingPosition="start"
          disabled={isDisabled}
          onClick={(...params) => {
            onClickFn(...params);
            setShowDialog(true);
          }}
        >
          Importar em massa
        </LoadingButton>

          {showDialog && <Dialog open={true} onClose={() =>{
              setShowDialog(false);
              console.log('close: ', showDialog);
            }}
            BackdropClick={false}
            fullWidth
            maxWidth='md'
            disableEnforceFocus>
            <DialogTitle size="small">Selecione o caracter separador ou digite o separador desejado</DialogTitle>

            <DialogContent>
            <>
                <Button onClick={() => {setSelectedSeparator(','); setSeparatorLabel(',')}}>,</Button>
                <Button onClick={() => {setSelectedSeparator(';'); setSeparatorLabel(';')}}>;</Button>
                <Button onClick={() => {setSelectedSeparator('|'); setSeparatorLabel('|')}}>|</Button>
                <TextField
                  variant="outlined"
                  size="small"
                  value={selectedSeparator}
                  onChange={e => setSelectedSeparator(e.target.value)}
                />


              <div className="file-drop-container">
              <FileDrop
                onDrop={(files, event) => { setFile(files[0]); event.target.value = null }}
              >
                {file ? 
                  <Chip label={file.name} onDelete={() => { setFile(null) }} /> :
                  <span>Arraste e solte para inserir o anexo.
                  </span>
                }
                <span>ou</span>
                <LoadingButton
                  variant="contained"
                  color="primary"
                  component="label"
                  loading={false}
                  loadingPosition="start"
                  startIcon={<ImportIcon />}
                  disabled={false}
                  onClick={() => { }}
                >
                  Selecione
                  <input
                    hidden
                    accept=".csv,.CSV"
                    type="file"
                    onChange={(e) => { setFile(e.target.files[0]); e.target.value = null }}
                  />
                </LoadingButton>
              </FileDrop>
              </div>
              </>
               </DialogContent>
                      <DialogActions>
                      <Button 
                        disabled={!selectedSeparator || selectedSeparator.length === 0 || selectedSeparator.value == ("Personalizado") || !file}
                        onClick={()=>{
                        onChangeFn(file, selectedSeparator);
                        setShowDialog(false);
                      }}>Confirmar</Button>
                        <Button onClick={()=>{setShowDialog(false)}}>Cancelar</Button>
                      </DialogActions>
                </Dialog> 
        }
        <Information  
          is_financial_market_company={is_financial_market_company}
        />
      </>
    </>
  )
}
