import React from "react";
import PropTypes from "prop-types";
import { Box, Tooltip } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import GetAppIcon from '@mui/icons-material/GetApp';

const ChatDocument = ({ src, user, name, message }) => {

    const useStyles = makeStyles((theme) => {
        return {
            root: {
                maxWidth: '175px',
                backgroundColor: '#f1f0f7',
                borderRadius: '100px',
                margin: '5px 0',
                height: '40px'
            }
        }
    })

    const classes = useStyles();

    return (
        <Box display="flex"
            justifyContent={user !== 0 ? 'flex-end' : 'flex-start'}
        >
            <Tooltip placement='left' title={message?.agent_name ? `Enviado por: ${message?.agent_name}` : ''}>
                <Box className={classes.root}
                    display="flex"
                    alignItems="center"
                    width="100%"
                    justifyContent="space-around"
                >
                    <InsertDriveFileIcon color="primary" />
                    <div>{name}</div>
                    <a target="_blank" href={src}>
                        <GetAppIcon color="primary" />
                    </a>
                </Box>
            </Tooltip>
        </Box>
    )
}

ChatDocument.PropTypes = {
    src: PropTypes.any,
    user: PropTypes.int,
    name: PropTypes.string
};

export default ChatDocument;   