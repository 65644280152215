import React, { useState, useEffect, useContext } from "react";

import { Box } from '@mui/material';

import useMediaQuery from '@mui/material/useMediaQuery';

import LoadingButton from '@mui/lab/LoadingButton';

import GroupIcon from '@mui/icons-material/Group';

import ClientGroupModal from "../ClientGroupModal";

import { ScreenContext } from "../../..";


export default function ClientGroupButton({ addclientgroup, deleteClientGroupModal, editClientGroupModal, selectedClientGroup, filterOnClick, small, selectedClients, addSelectedClientsToGroup }) {
    const isSmallScreen = small
    const [display, updateDisplay] = useState(false)

    const closeModal = (event) => {
        if (!display) {
            updateDisplay(false)
            window.removeEventListener('click', closeModal)
        }
    }

    const openModal = () => {
        if (!display) {
            window.addEventListener('click', closeModal)
        }
    }


    return (
        <>
            <LoadingButton
                component="label"
                startIcon={<GroupIcon />}
                onClick={(e) => { openModal(); updateDisplay(true); e.stopPropagation() }}
                size="small"
            >
                {isSmallScreen ? '' : 'Segmentos'}
            </LoadingButton>
            <>

                <Box sx={{ display: display ? 'block' : 'none' }} onClick={e => { e.stopPropagation() }} className={"clientGroupDialog"}>
                    <ClientGroupModal
                        addclientgroup={addclientgroup}
                        deleteClientGroupModal={deleteClientGroupModal}
                        editClientGroupModal={editClientGroupModal}
                        selectedClientGroup={selectedClientGroup}
                        filterOnClick={filterOnClick}
                        selectedClients={selectedClients}
                        addSelectedClientsToGroup={addSelectedClientsToGroup}
                    />
                </Box>

            </>

        </>
    )
}
