import { Box, IconButton, Tooltip } from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import ArrowUpward from "@mui/icons-material/ArrowUpward";
import ArrowDownward from "@mui/icons-material/ArrowDownward";

const SortComponent = ({ orderBy, updateData, color, old = false }) => {

  if (old) return (<Box className="sort-box" style={{ display: "flex", justifyContent: "flex-end" }} >
    <LoadingButton size="small"
      component="label"
      startIcon={orderBy === "-id" ? <ArrowDownward style={{ color }} /> : <ArrowUpward style={{ color }} />}
      onClick={() => {
        if (orderBy === "-id") {
          updateData("id");
        } else {
          updateData("-id");
        }

      }}
    >
    </LoadingButton>
  </Box>)

  return (<Tooltip title="Ordenação" placement="bottom" disableInteractive>
    <IconButton
      sx={{ p: '10px' }} aria-label="search"
      onClick={() => {
        if (orderBy === "-id") {
          updateData("id");
        } else {
          updateData("-id");
        }

      }}
    >
      {orderBy === "-id" ? <ArrowDownward style={{ color }} /> : <ArrowUpward style={{ color }} />}
    </IconButton>

  </Tooltip>)


};
export default SortComponent;
