import React, { useState, useEffect, useReducer } from 'react'

import {
    DataGridPro,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    gridClasses,
    ptBR
} from '@mui/x-data-grid-pro';

import {
    createTheme,
    ThemeProvider,
    StyledEngineProvider
} from '@mui/material/styles';

import { ptBR as corePtBR } from '@mui/material/locale';

import { Box, Grid, Tooltip, Button } from '@mui/material';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';

import useNotification from '../../Notification';

import api from '../../../api';
import ConfirmationDialogCaptcha from '../../Dialogs/ConfimationDialogCaptcha';
import defaultReducer from '../../../utils/defaultReducer';

import LoadingButton from '@mui/lab/LoadingButton';

import Loading from '../../Loading';

import { getSession } from '../../../auth';
import { alphabeticalSort } from '../../../utils/alphabeticalSort';
import { 
    stringOperators,
    choiceOperators,
    numericOperators
} from '../../../utils/filterOperators';

import EditEscalationModal from './EditEscalationModal';

const theme = createTheme({
    palette: {
        primary: { main: '#21446C' },
    },
}, ptBR, corePtBR);

const Escalation = () => {

    const enterpriseId = getSession()?.profile?.enterprise?.id

    const [state, setState] = useReducer(defaultReducer, {
        rows               : [],
        loading            : false,
        editEscalation     : null,
        allQueues          : {},
        showDeleteDialog   : false,
        selectedEscalation : null
    })

    const CustomToolbar = () => {
        return (
            <GridToolbarContainer className={gridClasses.toolbarContainer}>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <LoadingButton
                    size="small"
                    component="label"
                    startIcon={<AddCircleIcon />}
                    onClick={() => setState({ editEscalation: {} }) }
                >
                    Adicionar
                </LoadingButton>
            </GridToolbarContainer>
        );
    }

    const dataColumns = [
        { field: 'description', headerName: 'Descrição', flex: 1, filterOperators: stringOperators },
        { field: 'type', headerName: 'Tipo', flex: 1, filterOperators: stringOperators,
            renderCell: (params) => {
                if (params.row.chatbots.length !== 0) 
                    return 'Chatbots'
                return 'Filas'
            }
        },
        { field: 'chatbots_or_queues', headerName: 'Chatbots/Filas', flex: 1, filterOperators: stringOperators,
            renderCell: (params) => {
                if (params.row.chatbots.length !== 0)
                    return params.row.chatbots.map((each) => state.allChatBots[each.chatbot_id].name).join(', ')
                return params.row.queues.map((each) => state.allQueues[each.queue].name).join(', ')
            }
        },
        { field: 'messages', headerName: 'Mensagens automáticas', flex: 1, filterable: false,
            renderCell: (params) => {
                if (params.value.length === 0)
                    return ''
                if (params.value.length === 1)
                    return '1 mensagem'
                return params.value.length + ' mensagens'
            }
        },
        { field: 'destination_queue', headerName: 'Transbordo', flex: 1, filterOperators: stringOperators,
            renderCell: (params) => {
                if (! params.value)
                    return
                return state.allQueues[params.value].name
            }
        },
        { field: 'timeout', headerName: 'Tempo de espera', flex: 1, filterOperators: numericOperators,
            renderCell: (params) => {
                return Math.trunc(params.value / 60) + ' minutos'
            }
        },
        { field: 'message', headerName: 'Mensagem', flex: 1, filterOperators: stringOperators },
        {
            field: 'action', headerName: 'Ações',
            filterable: false, sortable: false,
            renderCell: (params) => (
                <>
                    <Button className="action-button"
                        onClick={() => setState({ editEscalation: params.row })}
                    >
                        <EditIcon />
                    </Button>
                    <Button className="action-button delete"
                        onClick={() => {
                            setState({ 
                                showDeleteDialog   : true,
                                selectedEscalation : params.row
                            })
                        }}
                    >
                        <DeleteIcon />
                    </Button>
                </>
            ),
        }
    ]

    useEffect(async () => {
        const queues   = {}
        const chatBots = {}

        const enterpriseId = getSession().profile.enterprise.id

        const queueResponse = await api.get(`api/enterprises/${enterpriseId}/queues/chat/`)
        if (queueResponse.status === 200) {
            queueResponse.data.sort((x,y) => alphabeticalSort(x,y,'description')).filter(each=>!each.disabled).forEach((queue) => {
                queues[queue.name] = {
                    name    : queue.description,
                    checked : false
                }
            })
        }

        const builderResponse = await api.get(`/api/getBuilder/`)
        if (builderResponse.status === 200) {
            builderResponse.data.botlist.sort((x,y) => alphabeticalSort(x,y,'name')).forEach((builder) => {
                chatBots[builder.id] = {
                    name    : builder.name,
                    checked : false
                }
            })
        }

        const vivreResponse = await api.get(`/api/enterprises/${enterpriseId}/ura/chat/`)
        if (vivreResponse.status === 200) {
            vivreResponse.data.sort((x,y) => alphabeticalSort(x,y,'name')).forEach((vivre) => {
                chatBots[vivre.id] = {
                    name    : vivre.name,
                    checked : false
                }
            })
        }
        
        const escalationResponse = await api.get(`api/enterprises/${getSession().profile.enterprise.id}/escalation/`)
        if (escalationResponse.status === 200)
            setState({ 
                allQueues   : queues,
                allChatBots : chatBots,
                rows        : escalationResponse.data 
            })
        else
            setState({ 
                allQueues   : queues,
                allChatBots : chatBots
            })
    }, [])

    const deleteEscalation = async (escalation) => {
        const response = await api.delete(`api/enterprises/${getSession().profile.enterprise.id}/escalation/${escalation.id}/`)
        
        if (response.status !== 204) {
            useNotification(
                'Ops!',
                'Ocorreu um erro na requisição.',
                'danger'
            )
            return
        }

        useNotification(
            'Sucesso!',
            'Sua jornada de atendimento foi removida com sucesso.',
            'success'
        )

        setState({ 
            rows: state.rows.filter((each) => each.id !== escalation.id),
            showDeleteDialog: false,
            selectedEscalation: null
        })
    }

    return (
        <>
            {state.editEscalation ?
                <EditEscalationModal
                    data={state.editEscalation}
                    allQueues={state.allQueues}
                    allChatBots={state.allChatBots}
                    rows={state.rows}
                    setParentState={setState}
                    method={Object.keys(state.editEscalation).length === 0 ? 'POST' : 'PATCH'}
                    handleClose={() => setState({ editEscalation: null })}
                />
                :
                null
            }
            {state.showDeleteDialog ?
                <ConfirmationDialogCaptcha open={state.showDeleteDialog}
                    title="Confirmação"
                    description={
                        <span>
                            Para confirmar a remoção da jornada, digite: <span style={{ 'fontWeight': 'bold' }}>remover</span> no campo abaixo.
                        </span>
                    }
                    handleClose={() => setState({ showDeleteDialog: false })}
                    handleSubmit={() => deleteEscalation(state.selectedEscalation)}
                    captcha="remover"
                />
                :
                null
            }
            <Loading loading={state.loading} />
            <Box className="escalation-container">
                <Grid container sx={{ height: '100%' }}>
                    <Box sx={{ width: '100%', height: '100%' }}>
                        <ThemeProvider theme={theme}>
                            <DataGridPro
                                disableSelectionOnClick
                                disableDensitySelector
                                density="compact"
                                columns={dataColumns}
                                rows={state.rows}
                                components={{
                                    Toolbar: CustomToolbar,
                                }}
                            />
                        </ThemeProvider>
                    </Box>
                </Grid>
            </Box>
        </>
    )
}

export default Escalation
