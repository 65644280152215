import React, { useEffect, useReducer } from 'react'
import defaultReducer from '../../../../utils/defaultReducer'
import Loading from '../../../Loading'
import api from '../../../../api'
import { getSession } from '../../../../auth'
import useNotification from '../../../Notification'
import {
    DataGridPro,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    ptBR,
} from '@mui/x-data-grid-pro';
import { ptBR as corePtBR } from '@mui/material/locale';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { createColumns } from './columns'
import ConfirmationDialogCaptcha from '../../../Dialogs/ConfimationDialogCaptcha'
import EditEvent from './editEvent'
import { Box } from '@mui/material'
import { BuilderModalDialog } from '../../../Settings/Vivre/Vivremenu'
import LoadingButton from '@mui/lab/LoadingButton';
import AddIcon from '@mui/icons-material/Add';

const theme = createTheme({
    palette: {
        primary: { main: '#21446C' },
    },
}, ptBR, corePtBR);

const getEvents = async (setState) => {
    setState({ loading: true })
    const enterpriseId = getSession().profile.enterprise.id
    const integration_events_promise = api.get(`/api/integration_events/`)
    const enterprise_events_promise = api.get(`/api/enterprises/${enterpriseId}/events/`)
    const builder_promise = api.get('/api/getBuilder/')
    const [integration_events, enterprise_events, builder] = await Promise.all([integration_events_promise, enterprise_events_promise, builder_promise])

    const stateUpdate = { loading: false }
    if (integration_events.status === 200)
        stateUpdate.integration_events = integration_events.data
    if (enterprise_events.status === 200)
        stateUpdate.enterprise_events = enterprise_events.data
    if (builder.status === 200)
        stateUpdate.builder = builder.data
    if (stateUpdate.integration_events && stateUpdate.enterprise_events){
        stateUpdate.integration_merged = []
        stateUpdate.integration_events.map((each) => {
            const newData = {...each}
            const findRow = stateUpdate.enterprise_events.find(event => event.event === each.action)
            if (findRow){
                newData.builderbot = findRow.builderbot
                newData.status = findRow.status
                newData.id = findRow.id
            }
            stateUpdate.integration_merged.push(newData)
        })
    }
    setState(stateUpdate)

    if (integration_events.status !== 200) {
        useNotification(
            'Ops!',
            'Não foi possível carregar os eventos de integração',
            'warning'
        )
    }
    if (enterprise_events.status !== 200) {
        useNotification(
            'Ops!',
            'Não foi possível carregar os eventos da empresa',
            'warning'
        )
    }
    if (builder.status !== 200) {
        useNotification(
            'Ops!',
            'Não foi possível carregar os dados do builder',
            'warning'
        )
    }
}

const CustomToolbar = (setState) => {
    return (
        <GridToolbarContainer >
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
            {/* <>
                <LoadingButton
                    startIcon={<AddIcon />}
                    size="small"
                    onClick={() => setState({ edit: {} })}
                >
                    Adicionar
                </LoadingButton>
            </> */}
        </GridToolbarContainer>
    )
}

const deleteEvent = async (data, setState, loadData) => {
    setState({ loading: true })
    const enterpriseId = getSession().profile.enterprise.id
    const res = await api.delete(`api/enterprises/${enterpriseId}/events/${data.id}/`, { timeout: 10000 })
    setState({ loading: false })
    if (res.status === 204) {
        useNotification(
            'Sucesso!',
            'Automação excluída com sucesso',
            'success'
        )
        setState({ delete: null })
        loadData(setState)
        return
    }
    useNotification(
        'warning',
        'Automação não pôde ser excluída',
        'danger'
    )
}



const EventManager = ({ clearEvent, saveEvent }) => {

    const [state, setState] = useReducer(defaultReducer, {
        integration_events: [],
        enterprise_events: [],
        integration_merged: [],
        builder: {},
        loading: false,
        edit: null,
        delete: null,
        showbot: null
    })

    useEffect(() => {
        getEvents(setState)
    }, [])

    return (
        <>
            {state.builder &&
                <Box sx={{ overflow: "hidden", position: 'fixed', left: '-999rem' }}>
                    <iframe id="builder" src={state.builder.embed_url} type="text/html"  ></iframe>
                </Box>
            }
            {state.loading ? <Loading loading={true} /> : null}
            {state.showbot && <BuilderModalDialog
                data={state.showbot.builderbot}
                builderData={state.builder}
                turnOffFrame={() => setState({ loading: false })}
                onClose={() => { setState({ showbot: false }); getEvents(setState) }}

            />}
            {state.edit && <EditEvent data={state.edit} parent={state} setParent={setState} reloadData={() => getEvents(setState)} />}
            {false && state.delete && <ConfirmationDialogCaptcha
                open={true}
                title="Confirmação"
                description={
                    <>
                        <Box>
                            Concorda em excluir o evento {state.integration_events.find(each => each.id === state.delete.event)?.name}?
                        </Box>
                        <Box>
                            Para confirmar a ação, digite: <span style={{ 'fontWeight': 'bold' }}>remover</span> no campo abaixo.
                        </Box>
                    </>
                }
                handleClose={() => { setState({ delete: null }) }}
                handleSubmit={() => { deleteEvent(state.delete, setState, getEvents) }}
                captcha={'remover'}

            />}
            <ThemeProvider theme={theme}>
                <DataGridPro
                    columns={createColumns(state, setState)}
                    rows={state.integration_merged}
                    disableSelectionOnClick
                    components={{ Toolbar: () =>CustomToolbar(setState) }}
                    density={'compact'}
                    getRowId={(e)=> e.idx}
                />
            </ThemeProvider>
        </>
    )
}

export default EventManager